import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {select, Store} from "@ngrx/store";
import {environment} from "../../../environments/environment";
import {appSettings} from "../../app-settings";
import { StorageUtil } from "src/app/helpers/storage-util";
import { AppActionTypes, GetMUsMUSets } from "src/app/store/actions";

@Injectable()
export class CreatePlanService {

  private token;
  public userInfo$: Observable<string>;

  constructor(private http: HttpClient, private store: Store<any>) {
    this.userInfo$ = this.store.select("state");
    this.userInfo$.subscribe((value: any) => {
      if (value && value.user && value.user.token) {
        this.token = value.user.token;
      }
    });
  }

  getEntityList(entityType: string, workloadEnabled: boolean): Observable<any> {
    let params = "permission=MODIFY";
    if (entityType === "ct") {
      console.debug("WorkloadCTs: " + workloadEnabled);
      params = params + "&optionalSlr=ERLANG&optionalSlr=OUTBOUND" + (workloadEnabled ? "&optionalSlr=WORKLOAD" : "");
    }
    const url = environment.espEndpointBaseUrl
      + appSettings.resourceLocations.entityList
      + `/${entityType}`
      + "?" + params;
    return this.http.get(url);
  }

  getMUsAndMUsets(entityType: string, entityOid: string){

    this.store.dispatch(new GetMUsMUSets({'entity-type':entityType, 'entity-oid':entityOid}));
  }

  getLTForecastList(entityType: string, entityOid: string,includeMSWF = false): Observable<any> {
    let userData = StorageUtil.getUserData();
    let url = environment.espEndpointBaseUrl
      + appSettings.resourceLocations.forecastList
      + `/${entityType}`
      + `/${entityOid}`;

    if(includeMSWF){
      url+="?ms-workflow=true"
    }

    return this.http.get(url);
  }

  getImportedForecastList(entityType: string, entityOid: string): Observable<any> {
    const url = environment.espEndpointBaseUrl
    + appSettings.resourceLocations.importedForecastsList
    + `/${entityType}`
    + `/${entityOid}`;
    return this.http.get(url);
  }

  createPlan(createPlanRequest: any) {
    const url = environment.espEndpointBaseUrl + appSettings.resourceLocations.planList;

    return this.http.post(url, createPlanRequest);
  }

  planExists(planName: string): Observable<any> {
    const respObs: any = "response";
    const httpOptions = {
      observe: respObs
    };
    const url = environment.espEndpointBaseUrl + appSettings.resourceLocations.planExists(planName);
    return this.http.head(url, httpOptions);
  }

}
