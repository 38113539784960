export enum PlanParam {

    CONTACTS = "plan.detail.contacts.recv",
    ACTUAL_CONTACTS = "plan.detail.contacts.recv.actual",
    VARIANCE_CONTACTS = "plan.detail.contacts.recv.variance",
    CONTACTS_HANDLED = "plan.detail.contacts.handled",
    ACTUAL_FTE_CAPACITY = "plan.detail.actual.fte.capacity",
    BACKLOG = "plan.detail.backlog",
    AHT = "plan.detail.aht",
    ACTUAL_AHT = "plan.detail.aht.actual",
    VARIANCE_AHT = "plan.detail.aht.variance",
    MEAN_CONCURRENCY = "plan.detail.mean.concurrency",
    SERVICE_LEVEL_PERCENT = "plan.detail.sl.per",
    SERVICE_LEVEL_TIME = "plan.detail.sl.time",
    ASA = "plan.detail.asa",
    OCCUPANCY = "plan.detail.max.occ",
    BASE_FTE = "plan.detail.fte.base",
    MS_EFFICIENCY = "plan.detail.mse",
    SHRINKAGE = "plan.detail.shr.per",
    SHRINKAGE_SUB = "plan.detail.shr.sub",
    FTE_REQUIRED = "plan.detail.fte.req",
    ACTUAL_FTE = "plan.detail.fte.act",
    ATTRITION = "plan.detail.att.per",
    ABS_ATTRITION = "plan.detail.att.abs",
    NEW_HIRE_FTE = "plan.detail.fte.nh",
    PROJECTED_SERVICE_LEVEL_PERCENT = "plan.detail.prj.sl.per",
    PROJECTED_ASA  = "plan.detail.prj.asa",
    PROJECTED_OCCUPANCY = "plan.detail.prj.occ.per",
    FTE_REQ_DIFF = "plan.detail.fte.rq.pm",
    HOURLY_RATE = "plan.detail.hr.rate",
    FTE_REQ_COST = "plan.detail.fte.req.cost",
    FTE_ACT_COST = "plan.detail.fte.act.cost",
    FTE_COST_DIFF = "plan.detail.cost.pm"

  }

  export enum PlanCellType {
    EDITED = "EDITED",
    CALCULATED = "CALCULATED",
    NONE = "NONE",
    CLOSED = "CLOSED"
  }

export enum PlanGen {
  V1 = 1, V2 = 2
}
