import {PlanDetailComponent} from "./plan/plan-detail/plan-detail.component";
import {PlanSettingsComponent} from "./plan/plan-settings/plan-settings.component";
import {EspNoRowsOverlayComponent} from "../components/no-rows-overlay/no-rows-overlay.component";
import {PlanListPageComponent} from "./plan/plan-list-page/plan-list-page.component";
import {ImportedForecastsDialogComponent} from "./imported-forecasts/imported-forecasts-dialog/imported-forecasts-dialog.component";
import {ImportedForecastsViewLogComponent} from "./imported-forecasts/imported-forecasts-view-log/imported-forecasts-view-log.component";
import {NgModule} from "@angular/core";
import {TooltipModule} from "primeng/tooltip"
import {NgbPopoverModule} from "@ng-bootstrap/ng-bootstrap";
import {ComponentModule} from "../components/component.module";
import { LoginPageComponent } from "./login-page/login-page.component";
import { GridFrozenScrollDirective } from '../common/grid-frozen-scroll/grid-frozen-scroll.directive';
// ag-grid
import {AgGridModule} from "ag-grid-angular";
import {TooltipFieldComponent} from "./plan/tooltip-field/tooltip-field.component";
import {CommonModule} from "@angular/common";
import {PlanDetailHeaderRowComponent} from "./plan/plan-detail-header-row/plan-detail-header-row.component";
import {PlanDetailClickableHeaderComponent} from "./plan/plan-detail-clickable-header/plan-detail-clickable-header.component";
import {RouterModule} from "@angular/router";
import { ToastModule } from "primeng/toast";
import { BlockUIModule } from "primeng/blockui";
import { DateRangeFieldComponent } from "./plan/date-range-field/date-range-field.component";
import { ModifiedDateFieldComponent } from "./plan/modified-date-field/modified-date-field.component";
import { PlanLoadingOverlayComponent } from "./plan/plan-loading-overlay/plan-loading-overlay.component";
import { EntityFieldComponent } from "./plan/entity-field/entity-field.component";
import { TranslateModule } from "@ngx-translate/core";
import { EntityTypeFieldComponent } from "./plan/entity-type-field/entity-type-field.component";
import { NumericEditorComponent } from "./plan/common/cell-editors/numeric/numeric-editor.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ErrorComponent } from "./error/error.component";
import { PlanDetailChartComponent } from "./plan/plan-detail-chart/plan-detail-chart.component";
import { HighchartsChartModule } from "highcharts-angular";
import { ConvertPlanDialogComponent } from "./plan/convert-plan-dialog/convert-plan-dialog.component";
import { PlanSaveAsComponent } from "./plan/plan-save-as/plan-save-as.component";
import { CalendarModule } from "primeng/calendar";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

// Imported Forecasts Related Components
import { ImportedForecastsListPageComponent } from "./imported-forecasts/imported-forecasts-list-page/imported-forecasts-list-page.component";
import { ImportedForecastsLoadingOverlayComponent } from "./imported-forecasts/imported-forecasts-loading-overlay/imported-forecasts-loading-overlay.component";
import { PlanDetailComponentV2 } from "./plan/plan-detail-v2/plan-detail-v2.component";
import { WeekHeaderComponentV2 } from "./plan/plan-distribution/week-header-v2/week-header-v2.component";
import { BaseHeaderComponentV2 } from "./plan/plan-distribution/base-header-v2/base-header-v2.component";
import { EspTableHeaderComponent } from "../components/esp-table-header/esp-table-header.component";
import {PlanMultiChartComponent} from "./plan/plan-multi-chart/plan-multi-chart.component";
import { GridItemActionComponent } from "../components/grid-item-action/grid-item-action.component";
import { NewHireDialogComponent } from "./plan/new-hire-dialog/new-hire-dialog.component";
import { PlanSettingsDialogComponent } from "./plan/plan-settings-dialog/plan-settings-dialog.component";
import { PlanSettingsCtDialogComponent } from "./plan/plan-settings-ct-dialog/plan-settings-ct-dialog.component";
import { EspColumnHeaderComponent } from "./plan/plan-distribution/esp-column-header/esp-column-header.component";
import { PlanDistributionComponentV2 } from "./plan/plan-distribution/plan-distribution-v2.component";
import {ValidationIndicatorCellComponentV2} from "./plan/plan-distribution/validation-indicator-cell-v2/validation-indicator-cell-v2.component";
import {HistoryValidationCellComponent} from "./historical-data/history-validation-cell/history-validation-cell.component";
import { InputNumberModule } from "primeng/inputnumber";

import { HistoricalDataComponent } from "./historical-data/historical-data.component";
import {MultiSelectModule} from "primeng/multiselect";
import {DropdownModule} from 'primeng/dropdown';
import { LogoutPageComponent } from './logout-page/logout-page.component';
import { UnrecoverableErrorComponent } from './unrecoverable-error/unrecoverable-error.component';
import { SsoLoginErrorComponent } from './sso-login-error/sso-login-error.component';
import { MswfRulesDialogComponent } from './plan/mswf-rules-dialog/mswf-rules-dialog.component';
import {PlanDetailComponentV3} from './plan/plan-detail-v3/plan-detail-v3.component';
import { PlanDetailValidationCellComponent } from './plan/plan-detail-validation-cell/plan-detail-validation-cell.component';

@NgModule({
  declarations: [
    ErrorComponent,
    PlanListPageComponent,
    TooltipFieldComponent,
    DateRangeFieldComponent,
    ModifiedDateFieldComponent,
    EntityFieldComponent,
    EntityTypeFieldComponent,
    PlanLoadingOverlayComponent,
    PlanDetailHeaderRowComponent,
    PlanDetailClickableHeaderComponent,
    PlanDetailComponent,
    PlanDetailComponentV2,
    PlanDetailComponentV3,
    PlanSettingsComponent,
    LoginPageComponent,
    PlanDetailChartComponent,
    ConvertPlanDialogComponent,
    PlanSaveAsComponent,
    ImportedForecastsListPageComponent,
    ImportedForecastsLoadingOverlayComponent,
    ImportedForecastsDialogComponent,
    PlanDistributionComponentV2,
    ValidationIndicatorCellComponentV2,
    WeekHeaderComponentV2,
    BaseHeaderComponentV2,
    EspColumnHeaderComponent,

    NumericEditorComponent,
    ImportedForecastsViewLogComponent,
    PlanMultiChartComponent,
    NewHireDialogComponent,
    PlanSettingsDialogComponent,
    PlanSettingsCtDialogComponent,
    HistoricalDataComponent,
    HistoryValidationCellComponent,
    LogoutPageComponent,
    UnrecoverableErrorComponent,
    SsoLoginErrorComponent,
    MswfRulesDialogComponent,
    PlanDetailValidationCellComponent,
    GridFrozenScrollDirective
  ],
    imports: [
        RouterModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        ComponentModule,
        TooltipModule,
        NgbPopoverModule,
        ToastModule,
        ReactiveFormsModule,
        BlockUIModule,
        TranslateModule.forRoot(),
        AgGridModule,
        CalendarModule,
        NgbModule,
        HighchartsChartModule,
        InputNumberModule,
        MultiSelectModule,
        DropdownModule
    ],
  exports: [
    ErrorComponent,
    PlanListPageComponent,
    TooltipFieldComponent,
    DateRangeFieldComponent,
    ModifiedDateFieldComponent,
    EntityFieldComponent,
    EntityTypeFieldComponent,
    PlanLoadingOverlayComponent,
    PlanDetailComponent,
    PlanDetailComponentV2,
    PlanSettingsComponent,
    PlanDetailHeaderRowComponent,
    PlanDetailClickableHeaderComponent,
    PlanMultiChartComponent,
    LoginPageComponent,
    NumericEditorComponent,
    ImportedForecastsListPageComponent,
    ImportedForecastsLoadingOverlayComponent,
    ImportedForecastsDialogComponent,
    ImportedForecastsViewLogComponent
  ]
})
export class PageModule {

}
