import { planRoutes } from "./plan-routing.module";
import { NgModule } from "@angular/core";
import { Routes, RouterModule, Route } from "@angular/router";
import { LoginPageComponent } from "./pages/login-page/login-page.component";
import { ErrorComponent } from './pages/error/error.component';
import {LogoutPageComponent} from "./pages/logout-page/logout-page.component";
import { UnrecoverableErrorComponent } from "./pages/unrecoverable-error/unrecoverable-error.component";
import { SsoLoginErrorComponent } from "./pages/sso-login-error/sso-login-error.component";

// import { PlanListPageComponent } from './pages';
// import { PlanDetailComponent } from './pages/plan/plan-detail/plan-detail.component';

const fallbackRoute: Route = {
  path: "**",
  redirectTo: "/"
};

const routes: Routes = [
  {
    path: "",
    redirectTo: "/",
    pathMatch: "full"
  },
  { path: "login", component: LoginPageComponent },
  { path: "logout", component: LogoutPageComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'unrecoverable-error', component: UnrecoverableErrorComponent},
  { path: 'sso-login-error', component: SsoLoginErrorComponent},
  fallbackRoute
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
