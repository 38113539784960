import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root"
})
export class TenantService {
  private _tenantData: any = null;

  constructor(private http: HttpClient) { }

  validate() {
    let self = this;
    this._tenantData = null;
    var subdomain = window.location.host.split(".")[0];
    return new Promise((resolve, reject) => {
      this.callValidateTenant(subdomain)
        .toPromise()
        .then(
          data => {
            self._tenantData = {
              ...data,
              subdomain
            };
            resolve(self._tenantData);
          }
        )
        .catch(function(err){
            self._tenantData = null;
            reject(err);
          }
        );
    });
  }

  callValidateTenant(subdomain) {
    let apiURL = environment.saasEndpointBaseUrl + "/tenant-manager/v1/tenants/validate?application=ESP&subdomain=" + subdomain;
    return this.http.get(apiURL);
  }

  get tenantData(): any {
    return this._tenantData;
  }

  get tenantIdentifier(): any {
    return this._tenantData.tenantIdentifier;
  }

}
