import {Injectable} from "@angular/core";
import {Action} from "@ngrx/store";
import {Actions, Effect, ofType} from "@ngrx/effects";
import {Observable, of} from "rxjs";
import {map, mergeMap} from "rxjs/operators";
import {AppActionTypes, LocaleUpdated, RefreshTokenRequest} from "../actions";
import {TranslateService} from "@ngx-translate/core";
import {AuthenticationService} from "src/app/authentication.service";
import {HttpClient} from "@angular/common/http";


@Injectable()
export class AppEffects {

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService
  ) {
  }

  @Effect()
  requestSuccessful$: Observable<Action> = this.actions$.pipe(
    ofType(AppActionTypes.RequestSuccessful),
    map((action: RefreshTokenRequest) => action.payload),
    mergeMap((payload: any) => {
      return this.authenticationService.refreshTokenIfNecessary();
    })
  );

  @Effect()
  UpdateLocale$: Observable<Action> = this.actions$.pipe(
    ofType(AppActionTypes.UpdateLocale),
    map((action: LocaleUpdated) => action.payload),
    mergeMap((payload: any) => {
      if (payload) {
        this.translateService.use(payload);
        return of({type: AppActionTypes.LocaleUpdated, payload: true});
      }
      return of({type: AppActionTypes.LocaleUpdated, payload: false});
    })
  );
}

