import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: 'api-credential',
  templateUrl: './api-credential.component.html',
  styleUrls: ['./api-credential.component.scss']
})
export class ApiCredentialComponent implements OnInit {

  created:Date = new Date();
  status = "Activated";


  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  dismiss() {
    console.log("dismiss");
    this.activeModal.dismiss();
  }

  copyToClipboard(event: any) {
    //ESP-8462 to be implemented
    console.log("copyToClipboard");
  }

}
