import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpEventType, } from "@angular/common/http";

import { Action } from "@ngrx/store";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { map, mergeMap, catchError } from "rxjs/operators";
import {
  AppActionTypes,
  ImportForecastTemplateSuccess,
  ImportForecastsListRequest,
  ImportForecastsListReceived,
  ImportForecastUploadFileSuccess,
  ImportForecastUploadFileProgress,
  ImportForecastViewLogSuccess,
  CheckImportForecastFileExistsSuccess,
  CheckImportForecastFileExistsFailed, CheckImportForecastFileExists, DeleteForecastsSuccess
} from "../actions";
import { environment } from "src/environments/environment";
import { appSettings } from "src/app/app-settings";
import {ImportForecastDetailsResponse, ImportForecastsListResponse} from "../../models/imported-forecasts";
import { AuthenticationService } from "src/app/authentication.service";
import { Features } from "src/app/models/plan";

@Injectable()
export class ForecastEffects {

  constructor(private http: HttpClient, private actions$: Actions, private authService: AuthenticationService) { }

  @Effect()
  importForecastTemplate$: Observable<Action> = this.actions$.pipe(
    ofType(AppActionTypes.ImportForecastTemplate),
    map((action: any) => action.payload),
    mergeMap((payload: any) => {
      let forecastTemplate = environment.espEndpointBaseUrl + appSettings.resourceLocations.forecastTemplate;
      if (this.authService.hasFeature(Features.UPDATE_FORECAST_TEMPLATE)) {
        forecastTemplate = forecastTemplate + "?version=3";
      } else if (this.authService.hasFeature(Features.WORKLOAD_CTS)) {
        forecastTemplate = forecastTemplate + "?version=2";
      }
      return this.http.get(forecastTemplate, { responseType: 'blob' })
        .pipe(
          map((data) => new ImportForecastTemplateSuccess(data)),
          catchError((err) => of({ type: AppActionTypes.ImportForecastTemplateFailed, payload: err }))
        );
    })
  );

  @Effect()
  importForecastUploadFile$: Observable<Action> = this.actions$.pipe(
    ofType(AppActionTypes.ImportForecastUploadFile),
    map((action: any) => action.payload),
    mergeMap((payload: File) => {
      let forecastUploadFile = environment.espEndpointBaseUrl + appSettings.resourceLocations.forecastUploadFile;
      if (this.authService.hasFeature(Features.UPDATE_FORECAST_TEMPLATE)) {
        forecastUploadFile = forecastUploadFile + "?version=3";
      } else if (this.authService.hasFeature(Features.WORKLOAD_CTS)) {
        forecastUploadFile = forecastUploadFile + "?version=2";
      }
      return this.http.post(forecastUploadFile, payload, { reportProgress: true, observe: "events" })
        .pipe(
          map((event: any) => {
            if (event.type == HttpEventType.Sent) {
              return new ImportForecastUploadFileProgress({ percentageLoaded: 2 });
            } else if (event.type == HttpEventType.UploadProgress) {
              let loaded = Math.round((100 / event.total) * event.loaded);
              return new ImportForecastUploadFileProgress({ percentageLoaded: loaded });
            } else if (event.type == HttpEventType.Response) {
              return new ImportForecastUploadFileSuccess({});
            } else if (event.type == HttpEventType.ResponseHeader || event.type == HttpEventType.DownloadProgress) {
              return new ImportForecastUploadFileProgress({});
            }
          }),
          catchError((err) => of({ type: AppActionTypes.ImportForecastUploadFileFailed, payload: err }))
        );
    })
  );

  @Effect()
  importForecastViewLog$: Observable<Action> = this.actions$.pipe(
    ofType(AppActionTypes.ImportForecastViewLog),
    map((action: any) => action.payload),
    mergeMap((payload: any) => {
      const importForecastOid = encodeURI(payload.importedForecastOid);
      const forecastViewLogUrl = environment.espEndpointBaseUrl + appSettings.resourceLocations.forecastViewLog(importForecastOid);
      return this.http.get(forecastViewLogUrl)
        .pipe(
          map((data) => new ImportForecastViewLogSuccess(data)),
          catchError((err) => of({ type: AppActionTypes.ImportForecastViewLogFailed, payload: err }))
        );
    })
  );

  @Effect()
  importForecastsList$: Observable<Action> = this.actions$.pipe(
    ofType(AppActionTypes.GetImportForecastsList),
    map((action: ImportForecastsListRequest) => action.payload),
    mergeMap((payload: any) => {
      const importForecastsListUrl = environment.espEndpointBaseUrl + appSettings.resourceLocations.importedForecastsList;
      let urlParameters = encodeURI(Object.entries(payload).map(e => e.join("=")).join("&"));
      return this.http.get(importForecastsListUrl + `?${urlParameters}`)
        .pipe(
          // If successful, dispatch success action with result
          map((data: ImportForecastsListResponse) => {
              return new ImportForecastsListReceived(data,payload);
            }
          ),
          // If request fails, dispatch failed action
          catchError((err) => of({ type: AppActionTypes.ImportForecastsListRetrieveFailed, payload: err })),
        )
    })
  );

  @Effect()
  importForecastCheckFileExists$: Observable<Action> = this.actions$.pipe(
    ofType(AppActionTypes.CheckImportForecastFileExists),
    map((action: any) => action.payload),
    mergeMap((payload: any) => {
      const importForecastName = payload.importForecastName.slice(0, -4);
      const forecastCheckFileExists = environment.espEndpointBaseUrl + appSettings.resourceLocations.forecastCheckFileExists(importForecastName);
      let result = new ImportForecastDetailsResponse();
      result.exists = true;
      result.errorStatus = false;
      return this.http.get(forecastCheckFileExists)
        .pipe(
          map((data: any) => {
            result.finalStatus = data.finalStatus;
            // If file name is not in final status, it cannot be overridden.
            if (!data.finalStatus) {
              return new CheckImportForecastFileExistsSuccess(result);
            }
            // If file name exists, upload will be allowed along with errormessage
            return new CheckImportForecastFileExistsFailed(result);
          }),
          catchError((error: any) => {
            // If the file name does NOT exist(404), upload will be allowed.
            if (error.status === 404) {
              result.exists = false;
              return of({type: AppActionTypes.CheckImportForecastFileExistsSuccess, payload: result});
            }
            result.errorStatus = true;
            return of({type: AppActionTypes.CheckImportForecastFileExistsFailed, payload: result});
          })
        );
    })
  );


  @Effect()
  deleteForecastList$: Observable<Action> = this.actions$.pipe(ofType(AppActionTypes.DeleteImportedForecasts),
      map((action: any) => action.payload),
      mergeMap((payload: any) => {
          let forecastIdArr = payload;
          const forecastListUrl = environment.espEndpointBaseUrl + appSettings.resourceLocations.forecastSoftDelete;

          return this.http.request('post', forecastListUrl, { body: { forecastIds: forecastIdArr } })
              .pipe(map((resp: any) => { return new DeleteForecastsSuccess(resp); }),
                  catchError(() => of({ type: AppActionTypes.DeleteForecastsFailed, payload: null }))
              );

      }

      ));

}
