// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  saasEndpointBaseUrl: "https://api.dev1.saas.nicewfmdev.com",
  espEndpointBaseUrl: "https://api.dev1.esp.nicewfmdev.com",
  espHelpUrl: "https://help.api.dev1.saas.nicewfmdev.com",
  locales: [
    {locale: "en-US", display: "English (US)", dateFormat: "M/d/yyyy", shortDateFormat: "M/d"},
    {locale: "en-US-Dummy", display: "English Dummy (US)", dateFormat: "M/d/yyyy", shortDateFormat: "M/d"},
    {locale: "es-ES", display: "Español (España)", dateFormat: "dd/MM/yyyy", shortDateFormat: "dd/MM"},
    {locale: "fr-FR", display: "Français (France)", dateFormat: "dd/MM/yyyy", shortDateFormat: "dd/MM"},
    {locale: "pt-BR", display: "Português (Brasil)", dateFormat: "dd/MM/yyyy", shortDateFormat: "dd/MM"}
  ],
  defaultLocale: "en-US"
};

// resource locations have moved to ../app/app-settings.ts
