import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { PlanListPageComponent } from "./pages";
import { PlanDetailComponent } from "./pages/plan/plan-detail/plan-detail.component";
import { AuthenticationGuard } from "src/app/authentication.guard";
import { CanDeactivateGuard } from "./helpers/can-deactivate-guard.service";
import { PlanDetailComponentV2 } from "./pages/plan/plan-detail-v2/plan-detail-v2.component";
import { PlanDistributionComponentV2 } from "./pages/plan/plan-distribution/plan-distribution-v2.component";
import {PlanDetailComponentV3} from './pages/plan/plan-detail-v3/plan-detail-v3.component';

export const planRoutes: Routes = [{
  path: "plans",
  children: [
    {
      path: "",
      component: PlanListPageComponent
    },
    {
      path: ":id",
      component: PlanDetailComponent,
      canDeactivate: [CanDeactivateGuard]
    }
  ],
  canActivate: [AuthenticationGuard]
},
{
  path: "site-plans",
  children: [
    {
      path: ":id",
      component: PlanDetailComponentV2,
      canDeactivate: [CanDeactivateGuard],
      children: [
        {
          path:"distribution",
          component: PlanDistributionComponentV2,
          canDeactivate: [CanDeactivateGuard],
        }
      ]
    }
  ],
  canActivate: [AuthenticationGuard]
},
  {
    path: "site-plans-v3",
    children: [
      {
        path: ":id",
        component: PlanDetailComponentV3,
        canDeactivate: [CanDeactivateGuard],
        children: [
          {
            path:"distribution",
            component: PlanDistributionComponentV2,
            canDeactivate: [CanDeactivateGuard],
          }
        ]
      }
    ],
    canActivate: [AuthenticationGuard]
  }];

@NgModule({
  imports: [
    RouterModule.forChild(planRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class PlanRoutingModule { }
