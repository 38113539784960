import {Action} from "@ngrx/store";
import {EditEntitySettingsDto, EditPlanDetailRequestDTO, MultiEditPlanDetailRequestDTO} from "src/app/models/plan";
import {BackgroundTask, TaskState} from "../application-state";

export enum AppActionTypes {
  GetUserInfo = "[App] GET_USER_INFO",
  GetAppInfo = "[App] GET_APP_INFO",
  HasUserInfo = "[App] CHECK_USER_INFO",
  ToggleLoadingState = "[App] TOGGLE_LOADING_STATE",
  SetLoadingState = "[App] SET_LOADING_STATE",
  PerformLogin = "[App] PERFORM_LOGIN",
  LoginFailed = "[App] LOGIN_FAILED",
  ResetLoginState = "[App] RESET_LOGIN_STATE",
  LoginSuccess = "[App] LOGIN_SUCCESS",
  LogInWithAccessCode = "[App] LOG IN WITH ACCESS CODE",

  UpdateLoginState = "[App] UPDATE_LOGIN_STATE",
  PerformLogout = "[App] PERFORM_LOGOUT",
  UpdateUserInfo = "[App] UPDATE_USER_INFO",
  GetEntityList = "[App] GET_ENTITY_LIST",
  EntityListReceived = "[App] RECEIVED_ENTITY_LIST",
  EntityListRequest = "[App] ENTITY LIST REQUEST",
  GetPlanList = "[App] GET_PLAN_LIST",
  PlanListRequest = "[App] PLAN_LIST_REQUEST",
  PlanListReceived = "[App] PLAN_LIST_RECEIVED",
  PlanListRetrieveFailed = "[App] PLAN_LIST_RETRIEVE_FAILED",
  GetPlanDetail = "[App] GET_PLAN_DETAIL",
  LoadPlanDetail = "[App] LOAD_PLAN_DETAIL",
  PlanDetailRequest = "[App] PLAN_DETAIL_REQUEST",
  PlanDetailReceived = "[App] PLAN_DETAIL_RECEIVED",

  SavePlanSettings = "[App] SAVE_PLAN_SETTINGS",
  SavePlanSettingsSuccess = "[App] SAVE_PLAN_SETTINGS_SUCCESS",
  SavePlanSettingsError = "[App] SAVE_PLAN_SETTINGS_ERROR",

  EditPlanDetail = "[App] EDIT_PLAN_DETAIL",
  EditPlanDetailRequest = "[App] EDIT_PLAN_DETAIL_REQUEST",
  EditPlanDetailReceived = "[App] EDIT_PLAN_DETAIL_RECEIVED",

  SavePlanDetail = "[App] SAVE_PLAN_DETAIL",
  SavePlanDetailComplete = "[App] SAVE_PLAN_DETAIL_COMPLETE",

  GetMuStaffing = "[App] GET_MU_STAFFING",
  GetMuStaffingSuccess = "[App] MU_STAFFING_SUCCESS",
  GetMuStaffingFailed = "[App] MU_STAFFING_FAILED",

  RequestSuccessful = "[App] REQUEST_SUCCESSFUL",
  RefreshTokenRequest = "[App] REFRESH_TOKEN_REQUEST",
  RefreshedTokenReceived = "[App] REFRESHED_TOKEN_RECEIVED",
  RefreshedTokenFailure = "[App] REFRESHED_TOKEN_FAILURE",

  RefreshPlanCache = "[App] REFRESH_PLAN_CACHE",
  RefreshPlanCacheRequest = "[App] REFRESH_PLAN_CACHE_REQUEST",
  RefreshPlanCacheReceived = "[App] REFRESH_PLAN_CACHE_RECEIVED",
  CacheSyncSuccess = "[App] CACHE_SYNC_SUCCESS",
  CacheSyncFailure = "[App]  CACHE_SYNC_FAILURE",

  UpdateMinutesUntilTokenExpiration  = "[App] UPDATE_MINUTES_UNTIL_TOKEN_EXPIRATION",
  UpdateLocale = "[App] UPDATE_LOCALE",
  LocaleUpdated = "[App] LOCALE_UPDATED",

  DeletePlans = "[App] DELETE_PLANS",
  DeletePlansSuccess = "[App] DELETE_PLANS_SUCCESS",
  DeletePlansFailed = "[App] DELETE_PLANS_FAILED",
  PlanNotExist = "[App] PLAN_NOT_EXIST",

  ConvertPlan = "[APP] CONVERT_PLAN",
  ConvertPlanSuccess = "[APP] CONVERT_PLAN_SUCCESS",
  ConvertPlanFailed = "[APP] CONVERT_PLAN_FAILED",

  SaveAsPlan = "[APP] SAVE_AS_PLAN",
  SaveAsPlanReceived = "[APP] SAVE_AS_PLAN_RECEIVED",
  SaveAsPlanSuccess = "[APP] SAVE_AS_PLAN_SUCCESS",
  SaveAsPlanSuccessReceived = "[APP] SAVE_AS_PLAN_SUCCESS_RECEIVED",
  SaveAsPlanFailed = "[APP] SAVE_AS_PLAN_FAILED",

  CheckPlanNameExists = "[APP] CHECK_PLAN_NAME_EXISTS",
  CheckPlanNameExistsReceived = "[APP] CHECK_PLAN_NAME_EXISTS_RECEIVED",
  CheckPlanNameExistsFailed = "[APP] CHECK_PLAN_NAME_EXISTS_FAILED",

  ImportForecastTemplate = "[App] IMPORT_FORECASTS_TEMPLATE",
  ImportForecastTemplateSuccess = "[App] IMPORT_FORECASTS_TEMPLATE_SUCCESS",
  ImportForecastTemplateFailed = "[App] IMPORT_FORECASTS_TEMPLATE_FAILED",

  ImportForecastUploadFile = "[App] IMPORT_FORECASTS_UPLOAD_FILE",
  ImportForecastUploadFileSuccess = "[App] IMPORT_FORECASTS_UPLOAD_FILE_SUCCESS",
  ImportForecastUploadFileProgress = "[App] IMPORT_FORECASTS_UPLOAD_FILE_PROGRESS",
  ImportForecastUploadFileFailed = "[App] IMPORT_FORECASTS_UPLOAD_FILE_FAILED",
  CheckImportForecastFileExists = "[App] CHECK_IMPORT_FORECASTS_FILE_EXISTS",
  CheckImportForecastFileExistsSuccess = "[App] CHECK_IMPORT_FORECASTS_FILE_EXISTS_SUCCESS",
  CheckImportForecastFileExistsFailed = "[App] CHECK_IMPORT_FORECASTS_FILE_EXISTS_FAILED",

  ImportForecastViewLog = "[App] IMPORT_FORECASTS_VIEW_LOG",
  ImportForecastViewLogSuccess = "[App] IMPORT_FORECASTS_VIEW_LOG_SUCCESS",
  ImportForecastViewLogFailed = "[App] IMPORT_FORECASTS_VIEW_LOG_FAILED",

  GetImportForecastsList = "[App] IMPORT_FORECASTS_LIST",
  ImportForecastsListRequest = "[App] IMPORT_FORECASTS_LIST_REQUEST",
  ImportForecastsListReceived = "[App] IMPORT_FORECASTS_LIST_RECEIVED",
  ImportForecastsListRetrieveFailed = "[App] IMPORT_FORECASTS_LIST_RETRIEVE_FAILED",

  NoAction = "[App] NO_ACTION",

  LTFRetrieveFail = "[App]  RETRIEVE_LTF_FAILED",
  UnrecoverableError = "[App] UNRECOVERABLE_ERROR",

  EditNewHireTrainingError = "[App] EDIT_NEW_HIRE_TRAINING_ERROR",
  EditPlanDetailError = "[App] EDIT_PLAN_DETAIL_ERROR",

  SortingCleared = "[App] SORTING_CLEARED",

  GetMUsMUSets = "[App] GET_MUS_MUSETS",
  MuAndMusetReceived = "[App] MU_MUSET_RECEIVED",
  MuAndMusetRetrieveError = "[APP] MU_MUSET_RETRIEVE_ERROR",

  CreatePlan = "[App] CREATE_PLAN",
  CreatePlanSubmitted = "[App]  CREATE_PLAN_SUCCESS",
  CreatePlanError = "[App] CREATE_PLAN_ERROR",

  EditMUDistV2 = "[App] EDIT_MU_DISTRIBUTION_V2",
  EditMUDistSuccess = "[App] EDIT_MU_DISTRIBUTION_SUCCESS",
  EditMUDistError = "[App] EDIT_MU_DISTRIBUTION_ERROR",

  TaskCreateAction = "[APP] TASK_CREATE",
  TaskStartAction = "[APP] TASK_START",
  TaskStatusAction = "[APP] TASK_STATUS",
  TaskErrorAction = "[APP] TASK_ERROR",
  TaskCompleteAction = "[APP] TASK_COMPLETE",
  TaskCancelAction = "[APP] TASK_CANCEL",

  DiscardMUDist = "[App] DISCARD_MU_DISTRIBUTION",
  DiscardMUDistSuccess = "[App] DISCARD_MU_DISTRIBUTION_SUCCESS",
  DiscardMUDistError = "[App] DISCARD_MU_DISTRIBUTION_ERROR",

  ApplyStaffingData = "[App] APPLY_STAFFING_DATA",
  ApplyStaffingDataSuccess = "[App] APPLY_STAFFING_DATA_SUCCESS",
  ApplyStaffingDataError = "[App] APPLY_STAFFING_DATA_ERROR",

  ApplyMuParams = "[App] APPLY_MU_PARAMS",
  ApplyMuParamsSuccess = "[App] APPLY_MU_PARAMS_SUCCESS",
  ApplyMuParamsError = "[App] APPLY_MU_PARAMS_ERROR",

  DeleteImportedForecasts = "[App] DELETE_IMPORTED_FORECASTS",
  DeleteForecastsSuccess = "[App] DELETE_FORECASTS_SUCCESS",
  DeleteForecastsFailed = "[App] DELETE_FORECASTS_FAILED",


  ApplyFTERequired = "[App] APPLY_FTE_REQUIRED",
  ApplyFTERequiredSuccess = "[App] APPLY_FTE_REQUIRED_SUCCESS",
  ApplyFTERequiredError = "[App] APPLY_FTE_REQUIRED_ERROR",

  EditEntitySettings = "[App] EDIT_ENTITY_SETTINGS",
  EditEntitySettingsSuccess = "[App] EDIT_ENTITY_SETTINGS_SUCCESS",
  EditEntitySettingsError = "[App] EDIT_ENTITY_SETTINGS_ERROR",

  GetHistoricalDataCTs = "[App] GET_HISTORICAL_DATA_CTS",
  GetHistoricalDataCTsSuccess = "[App] GET_HISTORICAL_DATA_CTS_SUCCESS",
  GetHistoricalDataCTsFailed = "[App] GET_HISTORICAL_DATA_CTS_FAILED",

  GetHistoricalData = "[App] GET_HISTORICAL_DATA",
  GetHistoricalDataSuccess = "[App] GET_HISTORICAL_DATA_SUCCESS",
  GetHistoricalDataFailed = "[App] GET_HISTORICAL_DATA_FAILED",

  EditHistoricalData = "[App] EDIT_HISTORICAL_DATA",
  EditHistoricalDataSuccess = "[App] EDIT_HISTORICAL_DATA_SUCCESS",
  EditHistoricalDataFailed = "[App] EDIT_HISTORICAL_DATA_FAILED",

  SaveHistoricalData = "[App] SAVE_HISTORICAL_DATA",
  SaveHistoricalDataSuccess = "[App] SAVE_HISTORICAL_DATA_SUCCESS",
  SaveHistoricalDataFailed = "[App] SAVE_HISTORICAL_DATA_FAILED",

  ExportEveryCt = "[App] EXPORT_EVERY_CT",
  ExportEveryCtSuccess = "[App] EXPORT_EVERY_CT_SUCCESS",
  ExportEveryCtFailed = "[App] EXPORT_EVERY_CT_FAILED",

  MultiEditPlanDetail = "[App] MULTI_EDIT_PLAN_DETAIL",
  MultiEditPlanDetailSuccess = "[App] MULTI_EDIT_PLAN_DETAIL_SUCCESS",
  MultiEditPlanDetailFailed = "[App] MULTI_EDIT_PLAN_DETAIL_FAILED",

}

export class GetEntityList implements Action {
  readonly type = AppActionTypes.GetEntityList;

  constructor(public payload: any) {
  }
}

export class EntityListRequest implements Action {
  readonly type = AppActionTypes.EntityListRequest;
  constructor(public payload: any) {
  }
}

export class EntityListReceived implements Action {
  readonly type = AppActionTypes.EntityListReceived;

  constructor(public payload: Array<any>) {
  }
}

export class UpdateLoginState implements Action {
  type = AppActionTypes.UpdateLoginState;
  constructor(public payload: Boolean) {

  }
}

export class UpdateUserInfo implements Action {
  type = AppActionTypes.UpdateUserInfo;
  constructor(public payload: any) {
  }
}

export class PerformLogin implements Action {
  readonly type = AppActionTypes.PerformLogin;

  constructor(public payload: any) {
  }
}

export class SetLoadingState implements Action {
  readonly type = AppActionTypes.SetLoadingState;

  constructor(public payload: boolean = null) {
  }
}

export class ResetLoginState implements Action {
  readonly type = AppActionTypes.ResetLoginState;

  constructor() {
  }
}

export class GetPlanList implements Action {
  readonly type = AppActionTypes.GetPlanList;

  constructor(public payload: any) {
  }
}
export class PlanListRequest implements Action {
  readonly type = AppActionTypes.PlanListRequest;
  constructor(public payload: any) {
  }
}

export class PlanListReceived implements Action {
  readonly type = AppActionTypes.PlanListReceived;

  constructor(public payload: any,public requestPayload:any=null) {
  }
}

export class PlanListRetrieveFailed implements Action {
  readonly type = AppActionTypes.PlanListRetrieveFailed;

  constructor(public payload: any) {
  }
}

export class GetPlanDetail implements Action {
  readonly type = AppActionTypes.GetPlanDetail;

  constructor(public payload: any) {
  }
}

export class LoadPlanDetail implements Action {
  readonly type = AppActionTypes.LoadPlanDetail;

  constructor(public payload: any) {
  }
}

export class PlanDetailRequest implements Action {
  readonly type = AppActionTypes.PlanDetailRequest;
  constructor(public payload: any) {
  }
}

export class PlanDetailReceived implements Action {
  readonly type = AppActionTypes.PlanDetailReceived;

  constructor(public payload: any) {
  }
}


export class EditPlanDetail implements Action {
  readonly type = AppActionTypes.EditPlanDetail;

  constructor(public payload: any) {
  }
}

export class EditPlanDetailRequest implements Action {
  readonly type = AppActionTypes.EditPlanDetailRequest;
  constructor(public payload: EditPlanDetailRequestDTO) {
  }
}

export class EditPlanDetailReceived implements Action {
  readonly type = AppActionTypes.EditPlanDetailReceived;

  constructor(public payload: any) {
  }
}

export class EditPlanDetailError implements Action {
  readonly type = AppActionTypes.EditPlanDetailError;

  constructor(public payload: any) {
  }
}

export class EditNewHireTrainingError implements Action {
  readonly type = AppActionTypes.EditNewHireTrainingError;

  constructor(public payload: any) {
  }
}


export class SavePlanDetail implements Action {
  readonly type = AppActionTypes.SavePlanDetail;

  constructor(public payload: any) {
  }
}

export class SavePlanDetailComplete implements Action {
  readonly type = AppActionTypes.SavePlanDetailComplete;
  constructor(public payload: any) {
  }
}

export class RequestSuccessful implements Action {
  readonly type = AppActionTypes.RequestSuccessful;

  constructor(public payload: any) {}
}

export class RefreshTokenRequest implements Action {
  readonly type = AppActionTypes.RefreshTokenRequest;

  constructor(public payload: any) {
  }
}

export class RefreshedTokenReceived implements Action {
  readonly type = AppActionTypes.RefreshedTokenReceived;

  constructor(public payload: any) {
  }
}

export class RefreshedTokenFailure implements Action {
  readonly type = AppActionTypes.RefreshedTokenFailure;

  constructor(public payload: any) {
  }
}

export class RefreshPlanCache implements Action {
  readonly type = AppActionTypes.RefreshPlanCache;

  constructor(public payload: any) {
  }
}

export class RefreshPlanCacheRequest implements Action {
  readonly type = AppActionTypes.RefreshPlanCacheRequest;

  constructor(public payload: any) {
  }
}
export class RefreshPlanCacheReceived implements Action {
  readonly type = AppActionTypes.RefreshPlanCacheReceived;

  constructor(public payload: any) {
  }
}

export class CacheSyncSuccess implements Action {
  readonly type = AppActionTypes.CacheSyncSuccess;

  constructor(public payload: any) {
  }
}

export class CacheSyncFailure implements Action {
  readonly type = AppActionTypes.CacheSyncFailure;

  constructor(public payload: any) {
  }
}

export class UpdateMinutesUntilTokenExpiration implements Action {
  readonly type = AppActionTypes.UpdateMinutesUntilTokenExpiration;

  constructor(public payload: any) {
  }
}

export class UpdateLocale implements Action {
  readonly type = AppActionTypes.UpdateLocale;

  constructor(public payload: any) {
  }
}

export class LocaleUpdated implements Action {
  readonly type = AppActionTypes.LocaleUpdated;

  constructor(public payload: any) {
  }
}

export class DeletePlans implements Action {
  readonly type = AppActionTypes.DeletePlans;

  constructor(public payload: any) {
  }
}

export class DeletePlansSuccess implements Action {
  readonly type = AppActionTypes.DeletePlansSuccess;

  constructor(public payload: any) {
  }
}

export class DeletePlansFailed implements Action {
  readonly type = AppActionTypes.DeletePlansFailed;

  constructor(public payload: any) {
  }
}

export class DeleteImportedForecasts implements Action {
  readonly type = AppActionTypes.DeleteImportedForecasts;

  constructor(public payload: any) {
  }
}

export class DeleteForecastsSuccess implements Action {
  readonly type = AppActionTypes.DeleteForecastsSuccess;

  constructor(public payload: any) {
  }
}

export class DeleteForecastsFailed implements Action {
  readonly type = AppActionTypes.DeleteForecastsFailed;

  constructor(public payload: any) {
  }
}

export class PlanNotExist implements Action {
  readonly type = AppActionTypes.PlanNotExist;

  constructor(public payload: any) {
  }
}

export class NoAction implements Action{
  readonly type = AppActionTypes.NoAction;
  constructor(public payload: any){

  }
}

export class ConvertPlan implements Action{
  readonly type = AppActionTypes.ConvertPlan;
  constructor(public payload: any){

  }
}

export class ConvertPlanSuccess implements Action{
  readonly type = AppActionTypes.ConvertPlanSuccess;
  constructor(public payload: any){

  }
}

export class ConvertPlanFailed implements Action{
  readonly type = AppActionTypes.ConvertPlanFailed;
  constructor(public payload: any){

  }
}

export class SaveAsPlan implements Action{
  readonly type = AppActionTypes.SaveAsPlan;
  constructor(public payload: any){

  }
}

export class SaveAsPlanReceived implements Action{
  readonly type = AppActionTypes.SaveAsPlanReceived;
  constructor(public payload: any){

  }
}

export class SaveAsPlanSuccess implements Action{
  readonly type = AppActionTypes.SaveAsPlanSuccess;
  constructor(public payload: any){

  }
}

export class SaveAsPlanSuccessReceived implements Action{
  readonly type = AppActionTypes.SaveAsPlanSuccessReceived;
  constructor(public payload: any){

  }
}

export class SaveAsPlanFailed implements Action{
  readonly type = AppActionTypes.SaveAsPlanFailed;
  constructor(public payload: any){

  }
}

export class CheckPlanNameExists implements Action{
  readonly type = AppActionTypes.CheckPlanNameExists;
  constructor(public payload: any){

  }
}

export class CheckPlanNameExistsReceived implements Action{
  readonly type = AppActionTypes.CheckPlanNameExistsReceived;
  constructor(public payload: any){

  }
}

export class CheckPlanNameExistsFailed implements Action{
  readonly type = AppActionTypes.CheckPlanNameExistsFailed;
  constructor(public payload: any){

  }
}

export class LTFRetrieveFail implements Action{
  readonly type = AppActionTypes.LTFRetrieveFail;
  constructor(public payload: any){

  }
}

export class UnrecoverableError implements Action{
  readonly type = AppActionTypes.UnrecoverableError;
  constructor(public payload: any){
  }
}

export class SortingCleared implements Action{
  readonly type = AppActionTypes.SortingCleared;
  constructor(public payload: any){
  }
}

export class ImportForecastTemplate implements Action{
  readonly type = AppActionTypes.ImportForecastTemplate;
  constructor(public payload: any){

  }
}

export class ImportForecastTemplateSuccess implements Action{
  readonly type = AppActionTypes.ImportForecastTemplateSuccess;
  constructor(public payload: any){

  }
}

export class ImportForecastTemplateFailed implements Action{
  readonly type = AppActionTypes.ImportForecastTemplateFailed;
  constructor(public payload: any){

  }
}

export class CheckImportForecastFileExists implements Action {
  readonly type = AppActionTypes.CheckImportForecastFileExists;
  constructor(public payload: any){
  }
}

export class CheckImportForecastFileExistsSuccess implements Action {
  readonly type = AppActionTypes.CheckImportForecastFileExistsSuccess;
  constructor(public payload: any){
  }
}

export class CheckImportForecastFileExistsFailed implements Action {
  readonly type = AppActionTypes.CheckImportForecastFileExistsFailed;
  constructor(public payload: any){
  }
}

export class ImportForecastUploadFile implements Action {
  readonly type = AppActionTypes.ImportForecastUploadFile;
  constructor(public payload: any){

  }
}

export class ImportForecastUploadFileSuccess implements Action {
  readonly type = AppActionTypes.ImportForecastUploadFileSuccess;
  constructor(public payload: any){

  }
}

export class ImportForecastUploadFileProgress implements Action {
  readonly type = AppActionTypes.ImportForecastUploadFileProgress;
  constructor(public payload: any){

  }
}

export class ImportForecastUploadFileFailed implements Action {
  readonly type = AppActionTypes.ImportForecastUploadFileFailed;
  constructor(public payload: any){

  }
}

export class ImportForecastViewLog implements Action {
  readonly type = AppActionTypes.ImportForecastViewLog;
  constructor(public payload: any){

  }
}

export class ImportForecastViewLogSuccess implements Action {
  readonly type = AppActionTypes.ImportForecastViewLogSuccess;
  constructor(public payload: any){

  }
}

export class ImportForecastViewLogFailed implements Action {
  readonly type = AppActionTypes.ImportForecastViewLogFailed;
  constructor(public payload: any){

  }
}

export class GetMuStaffingFailed implements Action{
  readonly type = AppActionTypes.GetMuStaffingFailed;
  constructor(public payload: any){

  }
}

export class GetMuStaffingSuccess implements Action{
  readonly type = AppActionTypes.GetMuStaffingSuccess;
  constructor(public payload: any){

  }
}


export class GetMuStaffing implements Action{
  readonly type = AppActionTypes.GetMuStaffing;
  constructor(public payload: any){

  }
}

export class GetImportForecastsList implements Action {
  readonly type = AppActionTypes.GetImportForecastsList;
  constructor(public payload: any) {
  }
}
export class ImportForecastsListRequest implements Action {
  readonly type = AppActionTypes.ImportForecastsListRequest;
  constructor(public payload: any) {
  }
}
export class ImportForecastsListReceived implements Action {
  readonly type = AppActionTypes.ImportForecastsListReceived;
  constructor(public payload: any,public requestPayload=null) {
  }
}
export class ImportForecastsListRetrieveFailed implements Action {
  readonly type = AppActionTypes.ImportForecastsListRetrieveFailed;
  constructor(public payload: any) {
  }
}

export class GetMUsMUSets implements Action {
  readonly type = AppActionTypes.GetMUsMUSets;

  constructor(public payload: any) {
  }
}

export class MuAndMusetReceived implements Action {
  readonly type = AppActionTypes.MuAndMusetReceived;

  constructor(public payload: any) {
  }
}

export class MuAndMusetRetrieveError implements Action {
  readonly type = AppActionTypes.MuAndMusetRetrieveError;

  constructor(public payload: any) {
  }
}

export class CreatePlan implements Action {
  readonly type = AppActionTypes.CreatePlan;
  constructor(public payload: any, public version:number) {

  }
}

export class CreatePlanSubmitted implements Action {
  readonly type = AppActionTypes.CreatePlanSubmitted;

  constructor(public payload: any) {
  }
}

export class CreatePlanError implements Action {
  readonly type = AppActionTypes.CreatePlanError;

  constructor(public payload: any) {
  }
}

export class EditMUDistV2 implements Action {
  readonly type = AppActionTypes.EditMUDistV2;

  constructor(public payload: any) {

  }

}

export class EditMUDistSuccess implements Action {
  readonly type = AppActionTypes.EditMUDistSuccess;

  constructor(public payload: any) {
  }
}

export class EditMUDistError implements Action {
  readonly type = AppActionTypes.EditMUDistError;

  constructor(public payload: any) {
  }
}

export class DiscardMUDist implements Action {
  readonly type = AppActionTypes.DiscardMUDist;

  constructor(public payload: any) {
  }
}

export class DiscardMUDistSuccess implements Action {
  readonly type = AppActionTypes.DiscardMUDistSuccess;

  constructor(public payload: any) {
  }
}

export class DiscardMUDistError implements Action {
  readonly type = AppActionTypes.DiscardMUDistError;

  constructor(public payload: any) {
  }
}


export class TaskCreate implements Action {
  readonly type = AppActionTypes.TaskCreateAction;

  constructor(public payload: BackgroundTask) {

  }
}

export class TaskStart implements Action {
  readonly type = AppActionTypes.TaskStartAction;

  constructor(public payload: BackgroundTask) {

  }
}

export class TaskCancel implements Action {
  readonly type = AppActionTypes.TaskCancelAction;

  constructor(public payload: BackgroundTask) {

  }
}

export class TaskStatus implements Action {
  readonly type = AppActionTypes.TaskStatusAction;

  constructor(public payload: BackgroundTask) {

  }
}

export class TaskError implements Action {
  readonly type = AppActionTypes.TaskErrorAction;

  constructor(public payload: BackgroundTask) {

  }
}

export class TaskComplete implements Action {
  readonly type = AppActionTypes.TaskCompleteAction;

  constructor(public payload: BackgroundTask) {

  }
}


export class ApplyStaffingData implements Action {
  readonly type = AppActionTypes.ApplyStaffingData;

  constructor(public payload: any) {

  }
}
export class ApplyStaffingDataSuccess implements Action {
  readonly type = AppActionTypes.ApplyStaffingDataSuccess;

  constructor(public payload: any) {

  }
}
export class ApplyStaffingDataError implements Action {
  readonly type = AppActionTypes.ApplyStaffingDataError;

  constructor(public payload: any,public taskStatus: TaskState) {

  }
}

export class ApplyMuParams implements Action {
  readonly type = AppActionTypes.ApplyMuParams;

  constructor(public payload: any) {

  }
}

export class ApplyMuParamsSuccess implements Action {
  readonly type = AppActionTypes.ApplyMuParamsSuccess;

  constructor(public payload: any) {

  }
}

export class ApplyMuParamsError implements Action {
  readonly type = AppActionTypes.ApplyMuParamsError;

  constructor(public payload: any, public taskStatus: TaskState) {

  }
}

export class SavePlanSettings implements Action {
  readonly type = AppActionTypes.SavePlanSettings;

  constructor(public payload: any) {
  }
}

export class SavePlanSettingsSuccess implements Action {
  readonly type = AppActionTypes.SavePlanSettingsSuccess;

  constructor(public payload: any) {
  }
}


export class SavePlanSettingsError implements Action {
  readonly type = AppActionTypes.SavePlanSettingsError;

  constructor(public payload: any, public taskStatus: TaskState) {
  }
}


export class ApplyFTERequired implements Action {
  readonly type = AppActionTypes.ApplyFTERequired;

  constructor(public payload: any) {

  }
}

export class ApplyFTERequiredSuccess implements Action {
  readonly type = AppActionTypes.ApplyFTERequiredSuccess;

  constructor(public payload: any) {

  }
}

export class ApplyFTERequiredError implements Action {
  readonly type = AppActionTypes.ApplyFTERequiredError;

  constructor(public payload: any, public taskStatus: TaskState) {

  }
}

export class EditEntitySettings implements Action {
  readonly type = AppActionTypes.EditEntitySettings;
  constructor(public payload: EditEntitySettingsDto) {

  }
}

export class EditEntitySettingsSuccess implements Action {
  readonly type = AppActionTypes.EditEntitySettingsSuccess;
  constructor(public payload: EditEntitySettingsDto) {

  }
}

export class EditEntitySettingsError implements Action {
  readonly type = AppActionTypes.EditEntitySettingsError;
  constructor(public payload: any) {

  }
}
export class GetHistoricalDataCTs implements Action{
  readonly type = AppActionTypes.GetHistoricalDataCTs;
  constructor(){

  }
}

export class GetHistoricalDataCTsSuccess implements Action{
  readonly type = AppActionTypes.GetHistoricalDataCTsSuccess;
  constructor(public payload: any){

  }
}
export class GetHistoricalDataCTsFailed implements Action{
  readonly type = AppActionTypes.GetHistoricalDataCTsFailed;
  constructor(public payload: any){

  }
}

export class GetHistoricalData implements Action{
  readonly type = AppActionTypes.GetHistoricalData;
  constructor(public payload:any,public sessionId:string,public requestId:number){

  }
}

export class GetHistoricalDataSuccess implements Action{
  readonly type = AppActionTypes.GetHistoricalDataSuccess;
  constructor(public payload: any,public requestId:number){

  }
}
export class GetHistoricalDataFailed implements Action{
  readonly type = AppActionTypes.GetHistoricalDataFailed;
  constructor(public payload: any,public requestId:number){

  }
}

export class EditHistoricalData implements Action{
  readonly type = AppActionTypes.EditHistoricalData;
  constructor(public payload:any,public sessionId:string){

  }
}

export class EditHistoricalDataSuccess implements Action{
  readonly type = AppActionTypes.EditHistoricalDataSuccess;
  constructor(public payload: any){

  }
}
export class EditHistoricalDataFailed implements Action{
  readonly type = AppActionTypes.EditHistoricalDataFailed;
  constructor(public payload: any){

  }
}


export class SaveHistoricalData implements Action{
  readonly type = AppActionTypes.SaveHistoricalData;
  constructor(public sessionId:string){

  }
}

export class SaveHistoricalDataSuccess implements Action{
  readonly type = AppActionTypes.SaveHistoricalDataSuccess;
  constructor(public payload: any){

  }
}
export class SaveHistoricalDataFailed implements Action{
  readonly type = AppActionTypes.SaveHistoricalDataFailed;
  constructor(public payload: any){

  }
}

export class ExportEveryCt implements Action {
  readonly type = AppActionTypes.ExportEveryCt;
  constructor(public payload: any) {

  }
}

export class ExportEveryCtSuccess implements Action {
  readonly type = AppActionTypes.ExportEveryCtSuccess;
  constructor(public payload: any) {

  }
}

export class ExportEveryCtFailed implements Action {
  readonly type = AppActionTypes.ExportEveryCtFailed;
  constructor(public payload: any) {

  }
}

export class MultiEditPlanDetail implements Action {
  readonly type = AppActionTypes.MultiEditPlanDetail;
  constructor(public payload: MultiEditPlanDetailRequestDTO) {

  }
}

export class MultiEditPlanDetailSuccess implements Action {
  readonly type = AppActionTypes.MultiEditPlanDetailSuccess;
  constructor(public payload: any) {

  }
}

export class MultiEditPlanDetailFailed implements Action {
  readonly type = AppActionTypes.MultiEditPlanDetailFailed;
  constructor(public payload: any) {

  }
}



export type AppAction = PlanNotExist |SavePlanDetail | SavePlanDetailComplete | EditPlanDetail | EditPlanDetailReceived
  | GetPlanDetail | PlanDetailReceived | GetPlanList | PlanListReceived | UpdateLoginState | UpdateUserInfo
  | GetEntityList | RequestSuccessful | RefreshTokenRequest | RefreshedTokenReceived | UpdateMinutesUntilTokenExpiration
  | UpdateLocale | LocaleUpdated | DeletePlans | DeletePlansSuccess | DeletePlansFailed | RefreshPlanCache
  | RefreshPlanCacheRequest | ConvertPlan | ConvertPlanSuccess | ConvertPlanFailed | SaveAsPlan | SaveAsPlanReceived
  | SaveAsPlanSuccess | SaveAsPlanSuccessReceived | SaveAsPlanFailed | CheckPlanNameExists | CheckPlanNameExistsReceived
  | CheckPlanNameExistsFailed
  | LTFRetrieveFail | UnrecoverableError | EditNewHireTrainingError | PlanListRetrieveFailed | SortingCleared
  | ImportForecastUploadFile | ImportForecastUploadFileSuccess | ImportForecastUploadFileProgress | ImportForecastUploadFileFailed
  | CheckImportForecastFileExists | CheckImportForecastFileExistsSuccess | CheckImportForecastFileExistsFailed
  | GetImportForecastsList | ImportForecastsListRequest | ImportForecastsListReceived | ImportForecastsListRetrieveFailed
  | GetMuStaffing | GetMuStaffingSuccess | GetMuStaffingFailed
  | ImportForecastTemplate | ImportForecastTemplateSuccess | ImportForecastTemplateFailed | GetMUsMUSets | MuAndMusetReceived
  | MuAndMusetRetrieveError
  | ImportForecastViewLog | ImportForecastViewLogSuccess | ImportForecastViewLogFailed
  | CreatePlan | CreatePlanSubmitted | CreatePlanError
  | EditMUDistSuccess | EditMUDistError
  | DiscardMUDist | DiscardMUDistSuccess | DiscardMUDistError
  | ApplyMuParams | ApplyMuParamsSuccess | ApplyMuParamsError
  | DeleteImportedForecasts | DeleteForecastsSuccess | DeleteForecastsFailed
  | SavePlanSettings | SavePlanSettingsSuccess | SavePlanSettingsError
  | ApplyFTERequired | ApplyFTERequiredSuccess | ApplyFTERequiredError
  | EditEntitySettings | EditEntitySettingsSuccess | EditEntitySettingsError | EditMUDistV2
  | GetHistoricalDataCTs | GetHistoricalDataCTsSuccess | GetHistoricalDataCTsFailed
  | GetHistoricalData | GetHistoricalDataSuccess | GetHistoricalDataFailed
  | SaveHistoricalData | SaveHistoricalDataSuccess | SaveHistoricalDataFailed
  | ExportEveryCt | ExportEveryCtSuccess | ExportEveryCtFailed
