import {Component, Input, OnDestroy, OnInit} from "@angular/core";

@Component({
  selector: "esp-multi-select-filter",
  templateUrl: "./multi-select-filter.component.html",
  styleUrls: ["./multi-select-filter.component.scss"]
})
export class MultiSelectFilterComponent implements OnInit,OnDestroy{
  @Input() multiSelect;

  subscription = null;

  constructor() {
  }

  ngOnInit(): void {
    if(this.multiSelect.filterValue===undefined){
      this.multiSelect.filterValue = "";
    }


    this.subscription = this.multiSelect.onPanelHide.subscribe(this.onFilterReset.bind(this))
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  filterNow() {
    console.log(this.multiSelect);
  }



  onFilterChanged(text) {
      this.multiSelect.filterValue = text;
  }

  onFilterReset() {
      this.multiSelect.filterValue = ""
  }
  onSelectAll(){
    this.multiSelect.checkAll();
    this.multiSelect.onModelChange(this.multiSelect.value);
    this.multiSelect.onChange.emit({ originalEvent: null, value: this.multiSelect.value });
    this.multiSelect.updateFilledState();
    this.multiSelect.updateLabel();
  }
  onClearAll(){
    this.multiSelect.uncheckAll();
    this.multiSelect.onModelChange(this.multiSelect.value);
    this.multiSelect.onChange.emit({ originalEvent: null, value: this.multiSelect.value });
    this.multiSelect.updateFilledState();
    this.multiSelect.updateLabel();
  }
}
