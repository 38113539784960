import { format } from "date-fns";
import { enUS, es, fr, pt } from "date-fns/locale";
import { environment } from "src/environments/environment";

export class DateUtils{
  private static dateShortFormat = "M/d";
  public static language = "en";
  public static locale = "en-US"

  private static datefnsLocaleMap = new Map([
    ["en", enUS],
    ["es", es],
    ["fr", fr],
    ["pt", pt]
  ]);

  public static getDatefnsLocale(lang) {
    let locale = this.datefnsLocaleMap.get(lang);
    // defaults to English US locale
    return !locale ? enUS : locale;
  }

  public static getMonthYear(week, dateLocale){
    return format(new Date(week + "T12:00:00Z"), "MMM yyyy", {locale: dateLocale})
  }
  public static getShortMonthYear(datePipe,week, locale){
    let shortDateFormat = environment.locales.find(shortDate => shortDate.locale === locale).shortDateFormat;
    return datePipe.transform(new Date(week + "T12:00:00Z"), shortDateFormat)
  }
  public static getShortDate(datePipe,week, locale){
    let shortDateFormat = environment.locales.find(shortDate => shortDate.locale === locale).shortDateFormat;
    return datePipe.transform(new Date(week + "T12:00:00Z"), shortDateFormat + "/yy")
  }

  public static getShortDate2(datePipe,date){
    return datePipe.transform(date, this.dateShortFormat + "/yy")

  }

  public static getShortDate3(datePipe,date,locale){
    let shortDateFormat = environment.locales.find(shortDate => shortDate.locale === locale).dateFormat;
    return datePipe.transform(new Date(date + "T12:00:00Z"), shortDateFormat)
  }
  public static getLocalISODate(date){
    let isoString = DateUtils.getLocalISOString(date);
    return isoString.split("T")[0];
  }
  public static getLocalISOString(date) {
    let offset = date.getTimezoneOffset() * 60000;
    let localISOTime = (new Date(date - offset)).toISOString().slice(0, -1);
    return localISOTime;
  }
  public static addDays(date ,days) {
    const newDate = new Date(date.valueOf());
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }
}

