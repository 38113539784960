import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {GridApi, IDatasource, IGetRowsParams, SortModelItem} from "ag-grid-community";
import {TooltipFieldComponent} from "../../plan/tooltip-field/tooltip-field.component";
import {DateRangeFieldComponent} from "../../plan/date-range-field/date-range-field.component";
import {ModifiedDateFieldComponent} from "../../plan/modified-date-field/modified-date-field.component";

import {MessageService} from "primeng/api";
import {Actions, ofType} from "@ngrx/effects";
import {TranslateService} from "@ngx-translate/core";
import {AuthenticationService} from "../../../authentication.service";
import {ImportedForecastsLoadingOverlayComponent} from "../imported-forecasts-loading-overlay/imported-forecasts-loading-overlay.component";
import {InputTextComponent} from "../../../components/input-text/input-text.component";
import { Store, select } from "@ngrx/store";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import { ImportedForecastsDialogComponent } from '../imported-forecasts-dialog/imported-forecasts-dialog.component';
import { EspPermissions } from '../../../common/enums';
import {
  AppActionTypes,
  GetImportForecastsList,
  ImportForecastsListReceived,
  ImportForecastsListRetrieveFailed,
  DeleteForecastsFailed,
  DeleteForecastsSuccess,
  DeleteImportedForecasts
} from "../../../store/actions";
import {Subscription} from "rxjs";
import { ImportedForecastsViewLogComponent } from '../imported-forecasts-view-log/imported-forecasts-view-log.component';
import { EspTableHeaderComponent } from 'src/app/components/esp-table-header/esp-table-header.component';
import { EspNoRowsOverlayComponent } from 'src/app/components/no-rows-overlay/no-rows-overlay.component';
import { Features } from 'src/app/models/plan';
import { ImportedForecastStatusEnum } from '../common/imported-forecast-status-enum';
import { GridItemActionComponent } from 'src/app/components/grid-item-action/grid-item-action.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'wfm-esp-imported-forecasts-list',
  templateUrl: './imported-forecasts-list-page.component.html',
  styleUrls: ['./imported-forecasts-list-page.component.scss'],
  providers: [MessageService]
})
export class ImportedForecastsListPageComponent implements OnInit, OnDestroy {

  protected subscriptionList: Array<Subscription> = [];
  public gridApi: GridApi = null;
  public gridColumnApi;
  public rowClassRules;
  public rowBuffer = 10;
  public rowModelType = "infinite";
  public infiniteInitialRowCount = 0;
  public cacheBlockSize = 50;
  public frameworkComponents: any;
  public loadingOverlayComponent = "ImportedForecastsLoadingOverlayComponent";
  public noRowsOverlayComponent = "noImportedForecastsOverlay";
  public currentGetRowParam: IGetRowsParams;
  public datasource: IDatasource;
  public context;
  public rowData = [];
  public espPermission:EspPermissions = EspPermissions.VIEW;
  public locale : string = environment.defaultLocale;
  private isRowSelectable:any;
  private sortBy: any;
  private sortOrder: any;
  public filterText: string;
  public  _isLoading = false;

  public noRowsOverlayComponentParams:any;

  private readonly DEFAULT_SORT_MODEL:SortModelItem[] = [{ colId: "forecastName", sort: 'asc' }];

  @ViewChild("searchTextBox") searchBoxEl: InputTextComponent;

  constructor(private store: Store<any>, private modalService: NgbModal,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private messageService: MessageService,
              private action$: Actions,
              private translate: TranslateService,
              private authService: AuthenticationService) {
    this.context = {
      componentParent: this,
      isLoading: this.isLoading.bind(this),
    };
    this.rowClassRules = {
      "row-disabled": (params) => this.disableRows(params)
    };

    this.isRowSelectable = function(rowNode) {
      return (rowNode.data && rowNode.data.importStatus && (rowNode.data.importStatus.toUpperCase() === 'PROCESSED'
      || rowNode.data.importStatus.toUpperCase() === "FAILED") ) ? true : false;
    };
     this.noRowsOverlayComponentParams = {
      getOverlayMsg: () => {
        let msgKey = this.filterText ? "search.no.results.msg" : "imported.forecasts.no.rows";
        return this.translate.instant(msgKey)
      }
    }
  }

  defaultColDef={
    sortable:true
  }
  ngOnInit() {
    this.frameworkComponents = {
      GridItemActionComponent: GridItemActionComponent,
      TooltipField: TooltipFieldComponent,
      DateRangeField: DateRangeFieldComponent,
      ModifiedDateField: ModifiedDateFieldComponent,
      noImportedForecastsOverlay: EspNoRowsOverlayComponent,
      ImportedForecastsLoadingOverlayComponent: ImportedForecastsLoadingOverlayComponent,
      agColumnHeader: EspTableHeaderComponent
    };
    let state = this.store.select("state");
    state.subscribe((data) => {
      if (data.user){
        this.espPermission = data.user.espPermission;
      }
      if (data.locale) {
        this.locale = data.locale;
      }
    });

    this.addToSubscriptionList(this.activatedRoute.queryParams
      .subscribe(params => {
        this.sortBy = params['sortBy'] || null;
        this.sortOrder = params['sortOrder'] || null;
        this.setColumnDefsSort(this.sortBy, this.sortOrder);
      }));
    this.columnDefs.forEach(a => a.headerComponentParams =  {headerPrefix:  "imported.forecasts.header."});
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.datasource = { getRows: (params: IGetRowsParams) => this.getImportForecastsList.call(this, params) };
    this.gridApi.setDatasource(this.datasource);
    this.initHandlers();
    this.setColumnVisibility();
    this.gridApi.sizeColumnsToFit();
  }

  public isLoading():boolean{
    return this._isLoading;
  }

  setColumnVisibility(){
    if (!this.hasEspModifyPermission || !this.hasDeleteImportedForecastFeature) {
      this.gridColumnApi.setColumnVisible("multiSelect", false);
      this.gridColumnApi.setColumnVisible("actions", false);
    }
  }

  disableRows(params: any) {
    if (params && params.data) {
      return params.data.importStatus.toLowerCase() !== "processed";
    }
    else return false;
  }

  get selectedForecasts() {
    if (this.gridApi !== null) {
      return this.gridApi.getSelectedNodes().map(item => item.data);
    }
    return null;
  }

  get selectedForecastsCount() {
    if (this.gridApi !== null) {
      const selectedNodes = this.gridApi.getSelectedNodes();
      return selectedNodes && selectedNodes !== null && selectedNodes.length > 0 ? selectedNodes.length : 0;
    }
    return 0;
  }

  get hasSelectedImportedForecasts() {
    return this.selectedForecastsCount > 0;
  }

  get hasEspModifyPermission() {
    return this.espPermission === EspPermissions.MODIFY;
  }

  get hasDeleteImportedForecastFeature(){
    return this.authService.hasFeature(Features.DELETE_IMPORTED_FORECAST);
  }

  isFinalStatus(importStatus: String) : boolean {
    return (importStatus === ImportedForecastStatusEnum.FAILED
      || importStatus === ImportedForecastStatusEnum.PROCESSED );
  }

  async showImportForecastsDlg() {
    try {
      const modalRef = this.modalService.open(ImportedForecastsDialogComponent, {
        centered: true,
        windowClass: "b-info wfm-modal",
        container: "div.manage-forecasts-content",
        backdrop: "static",
        keyboard: false
      }).result.then( ( result ) => {
        this.importForecastSuccessHandler();
      }, ( reason ) => { } );

    }catch(e){
      console.log(e)
    }

  }

  getImportForecastsList(params: IGetRowsParams) {
    this.currentGetRowParam = params;
    this.startLoadingGrid();
    console.log("Getting datasource rows, start: " + params.startRow + ", end: " + params.endRow);
    let blockSize = params.endRow - params.startRow;
    let columnId = "FORECAST_NAME";
    let sortOrder = "ASC";
    if (params.sortModel.length > 0) {
      sortOrder = params.sortModel[0].sort.toUpperCase();
      columnId = ColumnId[params.sortModel[0].colId];
    }
    let payload = {
      offset: params.startRow,
      limit: blockSize,
      "sort-by": columnId,
      "sort-order": sortOrder,
      "import-forecast-name-filter": this.filterText
    };
    this.store.dispatch(new GetImportForecastsList(payload));
  }


  filterRows(text) {
    this.filterText = text;
    this.currentGetRowParam.startRow = 0;
    let sortModel = this.currentGetRowParam.sortModel;
    if(sortModel.length == 0)
       sortModel = this.DEFAULT_SORT_MODEL;
    this.gridColumnApi.applyColumnState({state:sortModel});
    this.gridApi.ensureIndexVisible(0, "top");
    this.gridApi.setDatasource(this.datasource);
  }

  performCellClick(event) {
    if (ColumnId[event.colDef.colId] && event.data && event.data.oid) {

      const importedForecastOid = event.data.oid;
      const importedForecastName = event.data.forecastName;

      try {
        const modalRef = this.modalService.open(ImportedForecastsViewLogComponent, {
          centered: true,
          windowClass: "b-info wfm-modal",
          container: "div.manage-forecasts-content",
          backdrop: "static",
          keyboard: false
        });
        modalRef.componentInstance.importedForecastOid = importedForecastOid;
        modalRef.componentInstance.importedForecastName = importedForecastName;

        modalRef.result.then(
          (result) => {}, (reason) => {}
        );

      }catch(e){
        console.log(e)
      }

    }

  }

  importForecastSuccessHandler() {
    this.searchBoxEl.clear();
    this.filterText = '';
    let sortModel = [{ colId: "modifiedTime", sort: "desc" }];
    this.gridColumnApi.applyColumnState({state:sortModel});
    this.gridApi.ensureIndexVisible(0, "top");
    this.gridApi.setDatasource(this.datasource);
  }

  setColumnDefsSort(colId: ColumnId, sortOrder: String) {
    if (!colId || !sortOrder) {
      // default is forecast name ascending
      this.columnDefs[1]["sort"] = "asc";
    } else {
      switch (colId) {
        case ColumnId.modifiedTime:
          this.columnDefs[3]["sort"] = sortOrder;
          break;
        default:
          // plan forecast ascending is default
          this.columnDefs[1]["sort"] = "asc";
      }
    }
  }

  startLoadingGrid(){
    this.gridApi.showLoadingOverlay();
    this._isLoading = true;
  }

  endLoadingGrid(){
    this.gridApi.hideOverlay();
    this._isLoading = false;
  }



  setDefaultSorting(param:any){
    EspTableHeaderComponent.setSortOrder("forecastName", "asc");
  }

  initHandlers() {
    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ImportForecastsListReceived))
        .subscribe(this.importForecastsListReceiveHandler.bind(this))
    );
    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ImportForecastsListRetrieveFailed))
        .subscribe(this.importForecastsListRetrieveFailHandler.bind(this))
    );
    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.SortingCleared))
        .subscribe(this.setDefaultSorting.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.DeleteForecastsSuccess))
        .subscribe(this.deleteForecastsSuccessHandler.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.DeleteForecastsFailed))
        .subscribe(this.deleteForecastsFailureHandler.bind(this))
    );
  }

  protected addToSubscriptionList(newSubscription: Subscription) {
    this.subscriptionList.push(newSubscription);
  }

  private clearSubscriptionList() {
    if (this.subscriptionList.length > 0) {
      this.subscriptionList.forEach(subscriptionItem => subscriptionItem.unsubscribe());
      this.subscriptionList = null;
    }
  }

  private prevData: any = { metadata: [], lastRow: 0 };

  importForecastsListReceiveHandler(action: ImportForecastsListReceived) {
    let data = action.payload;
    if(action.requestPayload && action.requestPayload["import-forecast-name-filter"]!=this.filterText){
      return;
    }
    var lastRow = -1;
    if (data.totalCount <= this.currentGetRowParam.endRow) {
      lastRow = data.totalCount;
    }
    // Checks the data and make sure that the return is an array for the grid to process properly
    let metadata = !data.metadata || data.metadata === null ? [] : data.metadata;
    if (data.offset == 0) {
      //it's the first page
      this.prevData = { metadata, lastRow };
    } else {
      //append to previous import forecasts
      this.prevData = { metadata: [...this.prevData.metadata, ...metadata], lastRow };
    }
    this.currentGetRowParam.successCallback(metadata, lastRow);
    this.endLoadingGrid();
    // In case we have no import forecasts, we want to show no row overlay
    if (Array.isArray(metadata) && metadata.length === 0) {
      this.gridApi.showNoRowsOverlay();
    }
  }

  importForecastsListRetrieveFailHandler(action: ImportForecastsListRetrieveFailed) {
    console.log("ERROR retrieving import forecasts list");
    if (this.prevData.metadata.length > 0) {
      let metadata = this.prevData.metadata.slice(0, 100); //100 is the block size
      this.currentGetRowParam.successCallback(metadata, metadata.length);
    } else {
      this.currentGetRowParam.successCallback([], 0);
    }
    this.endLoadingGrid();
    let failMsg = this.translate.instant("imported.forecasts.retrieve.failed");
    this.messageService.add({ severity: "error", detail: failMsg });
  }


  deleteSelectedForecasts() {
    this.deleteForecasts(this.selectedForecasts);
  }

  deleteSingleForecast(forecast: any){
    let forecasts = [forecast];
    this.deleteForecasts(forecasts);
  }

  deleteForecasts(forecasts: any) {
    const forecastIds = forecasts.map((forecast: any) => forecast.oid);
    this.startLoadingGrid();
    this.store.dispatch(new DeleteImportedForecasts(forecastIds));
  }


  deleteForecastsSuccessHandler(action: DeleteForecastsSuccess) {
    if (action.payload) {
      let deletedForecastsResp = action.payload.deletedForecasts;
      let succeeded: Array<string> = deletedForecastsResp.SUCCESS;
      let failed = deletedForecastsResp.FAILED;
      let sortModel = this.currentGetRowParam.sortModel;
      if(sortModel.length == 0)
        sortModel = this.DEFAULT_SORT_MODEL;
      this.gridColumnApi.applyColumnState({state:sortModel});
      // this.gridApi.deselectAll();
      // this.gridApi.refreshInfiniteCache();
      this.gridApi.ensureIndexVisible(0, "top");
      this.gridApi.setDatasource(this.datasource);
      if ((failed == null || failed.length == 0) && succeeded.length > 0) {
        let deleted = (succeeded.length == 1) ? "single" : "multiple";
        let successMsg = this.translate.instant("imported.forecasts.delete.success." + deleted);
        this.messageService.add({ severity: "success", detail: successMsg });
      }
      else if (failed.length > 0) {
        let failMsg = this.translate.instant("imported.forecasts.delete.failed");
        this.messageService.add({ severity: "error", detail: failMsg });
      }
    }

    this.endLoadingGrid();

  }

  deleteForecastsFailureHandler(action: DeleteForecastsFailed){
    const failMsg = this.translate.instant("imported.forecasts.retrieve.failed");
    this.messageService.add({ severity: "error", detail: failMsg });
    this.endLoadingGrid();
  }

  public ngOnDestroy() {
    this.clearSubscriptionList();
  }

  checkBoxCellClass = (p:any) => {
    if (p.data && p.data.importStatus && this.hasDeleteImportedForecastFeature && this.hasEspModifyPermission) {
      return (this.isFinalStatus(p.data.importStatus)) ? '' :  'checkbox-disabled';
    } else {
      return 'checkbox-hidden';
    }
  }

  icons = {
    checkboxDisabled: `<svg class="icon"><use xlink:href="#icon-rect-disabled" /></svg>`,
    checkboxCheckedReadOnly: `<svg class="icon"><use xlink:href="#icon-rect-readonly" /></svg>`,
    sortAscending: `<svg class="icon"><use xlink:href="#icon-sort-ascending" /></svg>`,
    sortDescending: '<svg class="icon"><use xlink:href="#icon-sort-descending" /></svg>'
  };
  columnDefs:any = [
    {
      headerName: null,
      field: null,
      checkboxSelection: () => (this.hasEspModifyPermission && this.hasDeleteImportedForecastFeature),
      cellClass: this.checkBoxCellClass.bind(this),
      headerCheckboxSelection: false,
      suppressSizeToFit: true,
      width: 50,
      headerTooltip: " "
    },
    {
      headerName: this.translate.instant("imported.forecasts.header.forecastName"),
      field: "forecastName",
      colId: "forecastName",
      cellRenderer: "TooltipField",
      minWidth:150
    },
    {
      headerName: this.translate.instant("imported.forecasts.header.dateRange"),
      colId: "dateRange",
      cellRenderer: "DateRangeField",
      cellRendererParams: () => (environment.locales.find(l => l.locale === this.locale)),
      minWidth:150
    },
    {
      headerName: this.translate.instant("imported.forecasts.header.modifiedTime"),
      field: "modifiedTime",
      colId: "modifiedTime",
      cellRenderer: "ModifiedDateField",
      cellRendererParams: () => (environment.locales.find(l => l.locale === this.locale)),
      minWidth:150
    },
    {
      headerName: this.translate.instant("imported.forecasts.header.createdBy"),
      field: "createdBy",
      colId: "createdBy",
      cellRenderer: "TooltipField",
      minWidth:150
    },
    {
      headerName: this.translate.instant("imported.forecasts.header.importStatus"),
      field: "importStatus",
      colId: "importStatus",
      cellRenderer: "TooltipField",
      minWidth:150
    },
    {
      headerName: this.translate.instant("imported.forecasts.header.actions"),
      colId: "actions",
      sortable: false,
      headerClass: "text-center only-action",
      suppressSizeToFit: true,
      width: 100,
      cellRendererSelector: (p:any)=>(!this.hasEspModifyPermission || !this.hasDeleteImportedForecastFeature
        || p.data === undefined || p.data.importStatus === undefined || !this.isFinalStatus(p.data.importStatus) )
        ? null :
        {
          component: 'GridItemActionComponent',
          params: {
            callback: this.deleteSingleForecast.bind(this),
            messageDelete: this.translate.instant("imported.forecasts.delete.single")
          }
        }
    }
  ];

}

export enum ColumnId {
  forecastName = "FORECAST_NAME",
  dateRange = "DATE_RANGE",
  modifiedTime = "IMPORT_DATE",
  createdBy = "IMPORTED_BY",
  importStatus = "STATUS",
}
