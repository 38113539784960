import { Component, OnInit } from "@angular/core";
import { INoRowsOverlayAngularComp } from "ag-grid-angular";

@Component({
  selector: "wfm-esp-no-rows-overlay",
  templateUrl: "./no-rows-overlay.component.html",
  styleUrls: ["./no-rows-overlay.component.scss"]
})
export class EspNoRowsOverlayComponent implements OnInit, INoRowsOverlayAngularComp {

  public params: any;

  constructor() { }

  ngOnInit() {
  }

  agInit(params: any): void {
    this.params = params;
  }

}
