import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from "@angular/router";
import {Component, HostListener, OnInit, ViewChild,OnDestroy} from "@angular/core";
import {ColumnApi, GridApi, IDatasource} from "ag-grid-community";
import {format} from "date-fns";
import {PlanDetailHeaderRowComponent} from "../plan-detail-header-row/plan-detail-header-row.component";
import {PlanDetailClickableHeaderComponent} from "../plan-detail-clickable-header/plan-detail-clickable-header.component";
import {BasePage} from "../../base-page";
import {TranslateService} from "@ngx-translate/core";
import {select, Store} from "@ngrx/store";
import {Actions, ofType} from "@ngrx/effects";
import {Observable, Subscription} from "rxjs";
import {
  AppActionTypes,
  EditPlanDetail,
  EditPlanDetailReceived,
  GetPlanDetail,
  PlanDetailReceived,
  PlanNotExist,
  SavePlanDetail
} from "src/app/store/actions";
import {PlanLoadingOverlayComponent} from "../plan-loading-overlay/plan-loading-overlay.component";
import {DatePipe, FormatWidth, getLocaleDateFormat} from "@angular/common";
import {NumericEditorComponent} from "../common/cell-editors/numeric/numeric-editor.component";
import {EditPlanDetailRequestDTO, PlanDetailResponse, EntityType, Features} from "src/app/models/plan";
import {ValidatorFactory} from "../common/validator-factory";
import {PlanCellType, PlanParam} from "../common/plan-param";

import { MessageService } from "primeng/api";
import {NgbDropdownConfig, NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {WfmModalComponent as modalComponent} from "src/app/components/wfm-modal/wfm-modal.component";
import {ConfirmationMsgFactory} from "../common/confirm-msg-factory";
import {DialogOptions} from "../../../common/dialog-options";
import {PlanSettingsComponent} from "../plan-settings/plan-settings.component";
import {ConvertPlanDialogComponent} from "../convert-plan-dialog/convert-plan-dialog.component";
import {PlanSaveAsComponent} from "../plan-save-as/plan-save-as.component";
import {AuthenticationService} from "../../../authentication.service";
import {PlanHelper} from "../../../helpers/plan-helper";
import {ColumnId} from "../plan-list-page/plan-list-page.component";
import {filter, take} from "rxjs/operators";
import { NumberUtils } from "src/app/common/utils/number.utils";
import {PlanDetailCommonHelper} from "../common/plan-detail-common-helper";
import {DateUtils} from "../../../common/utils/date";

var dateLocale = DateUtils.getDatefnsLocale("en");

export enum DetailViewMode {
  Daily = "DAY",
  Weekly = "WEEK",
  Monthly = "MONTH"
}

@Component({
  selector: "plan-detail",
  templateUrl: "./plan-detail.component.html",
  styleUrls: ["./plan-detail.component.scss"],
  providers: [MessageService, NgbDropdownConfig]
})
export class PlanDetailComponent extends BasePage implements OnInit, OnDestroy {

  private pinnedRightColumnWidth = 105;
  private pinnedRightTotalColumnWidth = 115;
  private pinnedLeftColumnWidth = 225;
  private dateColumnWidth = 110;
  private dateShortFormat = "M/d";
  public  _isLoading = true;
  public _dataIsSaved = true;
  private _userHasModify = false;

  public planId: String = null;
  public gridApi: GridApi = null;
  public gridParams: any;
  protected subscriptionList: Array<Subscription> = [];
  public datasource: IDatasource;
  public frameworkComponents: any;
  public isChartView=false;
  public planName: String;
  public planStartDate: String;
  public planEndDate: String;
  public contactTypeList: any;
  public selectedContactType:any = null;
  public static readonly ALL_CONTACT_TYPES:string = "ALL_CONTACT_TYPES";
  public entityType:any = null;
  public entityId: any;
  public entityName: String;
  public ltForecastName: String;
  public _historicalDataInPlanFeature:boolean = false;

  protected translationKeys = ["plan.label.param", "plan.label.total", "plan.label.average", "date.format.short"];
  @ViewChild("grid")
  private grid;

  colDefs = [];
  colDefsOld = [];

  data = null;
  rowData = null;
  rowDataDaily = [];

  rowDataMonthly = [];

  rowDataWeekly = [];

  private weekStartDate = 0;
  public language = "en";
  public locale = "en-US";
  public userInfo$: Observable<string>;
  public loadingOverlayComponent = "planLoadingOverlay";
  private _isDistributionRoute:boolean;
  private columnApi: ColumnApi = null;

  constructor(
    private translateSrv: TranslateService,
    private store: Store<any>,
    private messageService: MessageService,
    private action$: Actions,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private ngbDropdownConfig: NgbDropdownConfig,
    private authService: AuthenticationService) {

    super(translateSrv);
    ngbDropdownConfig.placement = "bottom-right";
    this._historicalDataInPlanFeature = authService.hasFeature(Features.HISTORICAL_DATA_IN_PLAN);

    this.userInfo$ = this.store.select("state");
    this.userInfo$.subscribe((value: any) => {
      if (value && value.user && value.user.language) {
        if (this.language != value.user.language) {
          dateLocale = DateUtils.getDatefnsLocale(value.user.language);
          this.language = value.user.language;
        }
      }
      if (value && value.locale) {
        this.locale = value.locale;
      }
    });

  }

  async showConvertToActiveDialog(){

    if(this._dataIsSaved) {
      try {
        const modalRef = this.modalService.open(ConvertPlanDialogComponent, {
          centered: true,
          windowClass: "b-info wfm-modal",
          container: "div.plan-detail-container",
          backdrop: "static",
          keyboard: false
        });

        modalRef.componentInstance.planStartDate = this.planStartDate;
        modalRef.componentInstance.planEndDate = this.planEndDate;
        modalRef.componentInstance.planName = this.planName;
        modalRef.componentInstance.planId = this.planId;
        await modalRef.result;
      }catch(e){

      }
    }else{
      let dgOption: DialogOptions = new DialogOptions();
      dgOption.titleKey = "plan.convert.unsaved.dialog.title";
      dgOption.messageKey = "plan.convert.unsaved.dialog.msg";
      dgOption.msgType = "warn";
      dgOption.showCancel = true;
      dgOption.confirmLabel = "btn.confirm.label";
      try{
        let result = await modalComponent.showModalMessage(dgOption, this.modalService);
        this.action$.pipe(
          ofType(AppActionTypes.SavePlanDetailComplete),
          take(1))
          .subscribe((action:any)=>{
            if(action.payload !== null){
              this.showConvertToActiveDialog();
            }

          });
        this.savePlan();
      }catch(e){
        //user canceled
      }


    }
  }

  showPlanSettingsDlg() {
    const modalRef = this.modalService.open(PlanSettingsComponent, {
      centered: true,
      windowClass: "b-info wfm-modal",
      container: "div.plan-detail-container",
      backdrop: "static",
      keyboard: false
    });

    modalRef.componentInstance.currentContact = this.selectedContactType;
    modalRef.componentInstance.planVersion = 1;

    modalRef.result.then((data) => {
      this._dataIsSaved = false;
    }, (reason) => {

    });
  }

  async showSaveAsDlg() {
    try {
      const modalRef = this.modalService.open(PlanSaveAsComponent, {
        centered: true,
        windowClass: "b-info wfm-modal",
        container: "div.plan-detail-container",
        backdrop: "static",
        keyboard: false
      });
      let component = modalRef.componentInstance;
      component.planId = this.planId;
      component.entityType = this.entityType;
      component.entityId = this.entityId;
      component.entityName = this.entityName;
      component.ltfcstName = this.ltForecastName;
      component.planStartDate = this.planStartDate;
      component.planEndDate = this.planEndDate;

      await modalRef.result;
    } catch(error) {
      if (error) {
        console.log("error during showSaveAsDlg: " + error.message);
      }
    }
  }

  public gridContext: any = {viewMode: DetailViewMode.Monthly};

  //commented due to popover flickering
  // get gridContext() {
  //   return {updateViewMode: this.updateViewMode.bind(this)}
  // }

  public updateViewMode(isCollapsed) {

    if(this.isLoading()){
      return;
    }

    let newMode: DetailViewMode = null;
    switch (this.gridContext.viewMode) {
      case DetailViewMode.Daily:
        if (isCollapsed) {
          newMode = DetailViewMode.Weekly;
        }
        break;
      case DetailViewMode.Weekly:
        if (isCollapsed) {
          newMode = DetailViewMode.Monthly;
        } else {
          newMode = DetailViewMode.Daily;
        }
        break;
      case DetailViewMode.Monthly:
        if (!isCollapsed) {
          newMode = DetailViewMode.Weekly;
        }
        break;
    }
    this.getPlanDetail(newMode);

  }

  ngOnInit() {
    super.ngOnInit();
    this.frameworkComponents = {
      // agColumnHeaderGroup: PlanDetailHeaderRowComponent,
      // agColumnHeader: PlanDetailClickableHeaderComponent,
      planLoadingOverlay: PlanLoadingOverlayComponent,
      numericEditor: NumericEditorComponent
    };

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(()=>{
      this.updateCurrentRoute();
    });

    this.route.params.subscribe(params => {
      this.planId = params["id"];
    });
    this.translationResults.subscribe((result) => {
      this.updateTranslationObj(result);
      if (this.grid && this.grid.api) {
        this.grid.api.refreshHeader();
      }
    });

    this.gridContext = {
      updateViewMode: this.updateViewMode.bind(this),
      showMessage: this.showMessage.bind(this),
      viewMode: this.gridContext.viewMode,
      isLoading: this.isLoading.bind(this),
      cellEditable: this.cellEditable.bind(this),
      setCustomValue:this.setCustomValue.bind(this),
      canDrillDown:this.canDrillDown.bind(this)
    };

    this.initHandlers();
    this.getPlanDetail(this.gridContext.viewMode);
    this.updateCurrentRoute();
  }

  ngOnDestroy(): void {
    this.clearSubscriptionList();
    PlanHelper.removePlanDetailSessionId(this.planId);
  }

  public isLoading():boolean{
    return this._isLoading;
  }

  showMessage(titleKey, msgKey, type) {

    const dgOption: DialogOptions = new DialogOptions();
    dgOption.titleKey = titleKey;
    dgOption.messageKey = msgKey;
    dgOption.msgType = type;
    dgOption.showCancel = false;
    dgOption.confirmLabel = "btn.ok.label";
    const dialog = modalComponent.showModalMessage(dgOption, this.modalService);
    return dialog;
  }

  onGridReady(params: any) {
    this.gridParams = params;
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

  }

  getPlanDetail(mode: DetailViewMode) {
    this._isLoading = true;
    if(this.gridApi){
      this.gridApi.showLoadingOverlay();
    }
    let payload = {planId: this.planId, interval: mode ,oid:this.getSelectedCtOid()};
    this.store.dispatch(new GetPlanDetail(payload));
  }

  initHandlers() {
    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.PlanDetailReceived)).subscribe(this.planDetailReceiveHandler)
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.PlanNotExist))
        .subscribe(this.planNotExistHandler)
        );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.EditPlanDetailReceived))
        .subscribe(this.editPlanDetailReceiveHandler)
    );
    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.EditPlanDetailError))
        .subscribe(this.editPlanDetailErrorHandler.bind(this))
    );
    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.SavePlanDetailComplete))
        .subscribe(this.savePlanDetailCompleteHandler)
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ConvertPlanFailed))
        .subscribe(this.convertPlanFailedHandler)
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.SaveAsPlanSuccessReceived))
        .subscribe(this.saveAsSuccessHandler)
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.SaveAsPlanFailed))
        .subscribe(this.saveAsFailedHandler)
    );
  }

  onBtnExport(): void {
    const properCase:any = (p:string) => {return p.charAt(0).toUpperCase() + p.substr(1).toLowerCase()};
    const viewLevel:any = this.translate.instant("plan.viewMode." + properCase(this.gridContext.viewMode.toString()));
    const params: any = {
      columnGroups: true,
      allColumns: true,
      fileName: `${this.planName} - ${this.selectedContactType.label} - ${viewLevel}`,
      columnSeparator: ",",
      customHeader: `"${this.planName}","${this.selectedContactType.label}"`,
      processCellCallback: (p: any) => {
        const params: any = { value: p.value, data: p.node.data, colDef: p.column.colDef };

        // code to skip new parameters of esp-histdatainplan-esp2740 because not data.
        if (!(params.data.metadata && params.data.metadata.cellmetadata)) {
          return "";
        }

        const cellMetadata: any = params.data.metadata.cellmetadata[params.colDef.field];
        if (cellMetadata && cellMetadata.paramStatus === PlanCellType.CLOSED)
          return "";
        else
          return this.cellValueFormatter(params);
      }
    };
    this.gridApi.exportDataAsCsv(params);
  }

  planDetailReceiveHandler = (action: PlanDetailReceived) => {
    if (action.payload) {

      let response = action.payload;
      this.gridContext.viewMode=response.interval;
      this.planName = response.planName;
      this.planStartDate = response.settings.startDate;
      this.planEndDate = response.settings.endDate;
      this._userHasModify = false;
      if (response.settings && response.settings.userPlanPermission && response.settings.userPlanPermission === "MODIFY") {
        this._userHasModify = true;
      }

      let colDefsTemp = this.getColumnsFromResponse(response);
      let rowDataTemp = this.getRowsFromResponse(response);
      this.colDefs = colDefsTemp;
      this.rowData = rowDataTemp;
      this.contactTypeList = this.getCTFromResponse(response);
      this.entityType = response.entityType;
      this.entityId = response.settings.entityId;
      this.entityName = response.settings.entityName;
      this.ltForecastName = response.settings.ltForecastName;

      if(!this.selectedContactType){
        this.selectedContactType = this.contactTypeList[0];
      }
    } else { //server error
      if(this.planName){//we already have some grid data so stay on the grid
        this.messageService.add({ severity: "error", detail: this.translate.instant("plan.details.interval.failure") });
      }else{
        this.planNotReceived();
      }
    }
    if(this.gridApi){
      this.gridApi.hideOverlay();
    }
    this._isLoading = false;
  }

  planNotReceived = ()=>{
    const dgOption: DialogOptions = new DialogOptions();
    dgOption.titleKey = this.translate.instant("plan.detail.open.failure.title");
    dgOption.messageKey = this.translate.instant("plan.detail.open.failure.msg");
    dgOption.msgType = "error";
    dgOption.showCancel = false;
    dgOption.confirmLabel = "btn.ok.label";
    var dialog = modalComponent.showModalMessage(dgOption, this.modalService);
    const navigateBack = ()=>{
      this.router.navigateByUrl("/plans");
    };
    dialog.then(navigateBack, navigateBack);
  }

  planNotExistHandler = (action: PlanNotExist) => {
    const dgOption: DialogOptions = new DialogOptions();
    dgOption.titleKey = this.translate.instant("plan.notexist.title");
    dgOption.messageKey = this.translate.instant("plan.notexist.msg");
    dgOption.msgType = "error";
    dgOption.showCancel = false;
    dgOption.confirmLabel = "btn.ok.label";
    var dialog = modalComponent.showModalMessage(dgOption, this.modalService);
    const navigateBack = ()=>{
      this.router.navigateByUrl("/plans");
    };
    dialog.then(navigateBack, navigateBack);
  }

  editPlanDetailReceiveHandler = (action: EditPlanDetailReceived) => {
    if (action.payload) {
      var updatedRows = action.payload.editValues;
      updatedRows.forEach((updatedRow: any) => {
        var existingRow = this.rowData.find(obj => {
          return obj.metadata && obj.metadata.paramName == updatedRow.paramName;
        });
        if (existingRow) {
          Object.keys(updatedRow.data).forEach((k) => {
            const precision = Math.pow(10, updatedRow.decimal);
            let val = updatedRow.data[k];
            existingRow.metadata.cellmetadata[k] = Object.assign({}, updatedRow.metadata[k]);
            if (!existingRow.metadata.cellmetadata[k]) {
              existingRow.metadata.cellmetadata[k] = {origValue: val};
            } else {
              existingRow.metadata.cellmetadata[k].origValue = val;
            }// raw value to preserve precision
            existingRow[k] = Number.parseFloat(val) >= 0 ? Math.round(Number(val) * precision) / precision : val;
            // existingRow[k] = Number(val)?Number(val):val;

          });

          // update total and average
          existingRow["plan_label_total"] = updatedRow.total;
          existingRow["plan_label_average"] = updatedRow.average;
        }
      });

      // we must redraw rows since the cell-class might have changed. (Edit/Calculated)
      this.rowData=[...this.rowData];
      this.gridApi.redrawRows();
      this._isLoading = false;
      this.gridApi.hideOverlay();
    }
    else{
      this.router.navigateByUrl("/error");
    }
  }

  editPlanDetailErrorHandler(action:any){
    // handle this later
    //this.router.navigateByUrl("/error");
    this._isLoading = false;
    this.messageService.add({severity:"error",detail:this.translate.instant("plan.detail.edit.failure")})
    this.gridApi.hideOverlay();
  }

  savePlanDetailCompleteHandler = (action: any) => {
    this._isLoading = false;
    this.gridApi.hideOverlay();

    let errorStatus = action.payload.errorStatus;
    if (errorStatus === null) {
      this._dataIsSaved = true;
      let successMsg = this.translate.instant("plan.save.success");
      this.messageService.add({severity: "success", detail: successMsg});
    } else if (errorStatus === 409) { // plan modified
      let title = this.translate.instant("plan.save.error.title.dlg");
      let msg = this.translate.instant("plan.save.error.modified.msg");
      this.showMessage(title, msg, "error");
    } else if (errorStatus === 410) { // plan deleted
      let title = this.translate.instant("plan.save.error.title.dlg");
      let msg = this.translate.instant("plan.save.error.deleted.msg");
      this.showMessage(title, msg, "error");
    } else {
      let failureMsg = this.translate.instant("plan.save.failure");
      this.messageService.add({severity: "error", detail: failureMsg});
    }
  };

  convertPlanFailedHandler = (e: any) => {
    if (e.payload.status === 500) {
      const done = () => { };
      const dialogOpt: DialogOptions = new DialogOptions();
      dialogOpt.titleKey = "generic.err.title";
      dialogOpt.messageKey = "unrecoverable.err.msg";
      dialogOpt.msgType = "error";
      dialogOpt.confirmLabel = "btn.close";
      dialogOpt.showCancel = false;
      modalComponent.showModalMessage(dialogOpt, this.modalService).then(done, done);
      // this.messageService.add({severity: "error", detail: this.translate.instant("plan.convert.failure.general")});
    }
    else if (e.payload.status === 401) {
      // logout
      const logout = () => {
        if (this.router.navigate(["/login"]))
          this.authService.logout();
      };
      const dgOption: DialogOptions = new DialogOptions();
      dgOption.titleKey = "generic.err.title";
      dgOption.messageKey = "generic.err.msg";
      dgOption.msgType = "error";
      dgOption.confirmLabel = "btn.signin";
      dgOption.showCancel = false;
      modalComponent.showModalMessage(dgOption, this.modalService).then(logout, logout);
    }
  };

  saveAsSuccessHandler = (action: any) => {
    // set data is saved (as) so user can navigate back to managed plans page
    this._dataIsSaved = true;
    this.router.navigate(['/plans'], {queryParams: {sortBy: ColumnId.modifiedDate, sortOrder: "desc"}});
  };

  saveAsFailedHandler = async (action: any) => {
    // Toast not displayed when Save As results in a duplicate name failure and unrecoverable error
    if (![409, 500, 401].includes(action.payload.status)) {
      this.messageService.add({ severity: "error", detail: this.translate.instant("plan.save.as.failure") });
    }
  };

  protected getColumnsFromResponse(response) {

    let colDefs = [];
    if (this.translateSrv.currentLang) {
      this.dateShortFormat = getLocaleDateFormat(this.translateSrv.currentLang, FormatWidth.Short).replace("/yy", "");
    }
    if (response.interval === DetailViewMode.Daily) {

      // Parameters column
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.param"],
        headerGroupComponentFramework: null,
        children: [{
          pinned: "left",
          headerComponentFramework: null,
          headerName: "",
          suppressSizeToFit: true,
          width: this.pinnedLeftColumnWidth,
          field: "plan_label_param",
          //colId: "plan_label_param",
          tooltipField: "plan_label_param"
        }],
        headerClass: "",
        width: null
      });
      // Week/Day columns
      let weeks = response.headers;
      for (var weekKey in weeks) {
        let itemClass = "first-item";
        if (weeks.hasOwnProperty(weekKey)) {
          let children = [];
          let days = weeks[weekKey].children;
          for (var dayKey in days) {
            if (days.hasOwnProperty(dayKey)) {
              children.push({
                field: dayKey,
                //colId:dayKey,
                startDate: days[dayKey].date,
                endDate: days[dayKey].date,
                headerComponentParams: {updateViewMode: this.updateViewMode.bind(this)},
                headerComponentFramework: null,
                headerName: this.datePipe.transform(new Date(days[dayKey].date + "T12:00:00Z"), this.dateShortFormat + "/yy"),
                headerNameFull: this.datePipe.transform(new Date(days[dayKey].date + "T12:00:00Z"), this.dateShortFormat + "/yy"),
                suppressSizeToFit: true,
                width: 110,
                headerClass: "no-bold no-icon no-black " + itemClass,
                cellEditorSelector: this.cellEditor,
                editable: this.cellEditable,
                valueFormatter: this.cellValueFormatter,
                cellEditorParams: this.cellEditorParam,
                onCellValueChanged: this.cellValueChanged,
                validator: ValidatorFactory.getValidator,
                valueSetter: this.cellValueSetter.bind(this),
                cellClass: this.cellClass,
                lockPosition:true
              });
            }
            itemClass = "";
          }
          colDefs.push({
            headerName: this.datePipe.transform(new Date(weeks[weekKey].startDate + "T12:00:00Z"), this.dateShortFormat) +
                  "-" + this.datePipe.transform(new Date(weeks[weekKey].endDate + "T12:00:00Z"), this.dateShortFormat),
            headerNameFull: this.datePipe.transform(new Date(weeks[weekKey].startDate + "T12:00:00Z"), this.dateShortFormat+ "/yy") +
                  "-" + this.datePipe.transform(new Date(weeks[weekKey].endDate + "T12:00:00Z"), this.dateShortFormat+ "/yy"),
            headerGroupComponentFramework: PlanDetailHeaderRowComponent,
            children: children,
            headerClass: "",
            width: null,
            lockPosition:true
          });
        }
        itemClass = "";
      }

      // Total column
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.total"],
        headerGroupComponentFramework: null,
        children: [{
          pinned: "right", headerComponentFramework: null,
          headerName: "",
          suppressSizeToFit: true,
          width: this.pinnedRightTotalColumnWidth,
          field: "plan_label_total",
          //colId: "plan_label_total",
          tooltip: this.cellValueFormatter,
          valueFormatter: this.cellValueFormatter,
          cellClass: this.cellClass
        }],
        headerClass: "black text-right",
        width: null
      });

      // Average column
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.average"],
        headerGroupComponentFramework: null,
        children: [{
          pinned: "right", headerComponentFramework: null, suppressSizeToFit: true,
          width: this.pinnedRightColumnWidth,
          field: "plan_label_average",
          //colId: "plan_label_average",
          tooltip: this.cellValueFormatter,
          valueFormatter: this.cellValueFormatter,
          headerName: "",
          cellClass: this.cellClass
        }],
        headerClass: "black text-right",
        width: null
      });
    }

    if (response.interval === DetailViewMode.Weekly) {

      // Parameters column
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.param"],
        headerGroupComponentFramework: null,
        children: [{
          pinned: "left",
          headerComponentFramework: null,
          headerName: "",
          suppressSizeToFit: true,
          width: this.pinnedLeftColumnWidth,
          field: "plan_label_param",
          //colId: "plan_label_param",
          tooltipField: "plan_label_param"
        }],
        headerClass: "",
        width: null
      });

      // Month/Week columns
      let months = response.headers;
      for (var monthKey in months) {
        let itemClass = "first-item";
        if (months.hasOwnProperty(monthKey)) {
          let children = [];
          let weeks = months[monthKey].children;
          for (var weekKey in weeks) {
            if (weeks.hasOwnProperty(weekKey)) {
              if (weeks[weekKey].partialFlag) {
                itemClass = itemClass + " partial-field";
              }
              children.push({
                field: weekKey,
                //colId: weekKey,
                startDate: weeks[weekKey].startDate,
                endDate: weeks[weekKey].endDate,
                headerComponentParams: {updateViewMode: this.updateViewMode.bind(this)},
                headerComponentFramework: PlanDetailClickableHeaderComponent,
                headerName: this.datePipe.transform(new Date(weeks[weekKey].startDate + "T12:00:00Z"), this.dateShortFormat) +
                  "-" + this.datePipe.transform(new Date(weeks[weekKey].endDate + "T12:00:00Z"), this.dateShortFormat),
                headerNameFull: this.datePipe.transform(new Date(weeks[weekKey].startDate + "T12:00:00Z"), this.dateShortFormat+ "/yy") +
                  "-" + this.datePipe.transform(new Date(weeks[weekKey].endDate + "T12:00:00Z"), this.dateShortFormat+ "/yy"),
                suppressSizeToFit: true,
                width: 110,
                headerClass: "no-bold no-icon no-black " + itemClass,
                cellEditorSelector: this.cellEditor,
                editable: this.cellEditable,
                valueFormatter: this.cellValueFormatter,
                cellEditorParams: this.cellEditorParam,
                onCellValueChanged: this.cellValueChanged,
                validator: ValidatorFactory.getValidator,
                valueSetter: this.cellValueSetter.bind(this),
                cellClass: this.cellClass,
                lockPosition:true
              });
            }
            itemClass = "";
          }
          colDefs.push({
            headerName: format(new Date(months[monthKey].startDate + "T12:00:00Z"), "MMM yyyy", {locale: dateLocale}),
            headerNameFull: format(new Date(months[monthKey].startDate + "T12:00:00Z"), "MMM yyyy", {locale: dateLocale}),
            headerGroupComponentFramework: PlanDetailHeaderRowComponent,
            children: children,
            headerClass: "",
            width: null,
            lockPosition:true
          });
        }
        itemClass = "";
      }

      // Total column
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.total"],
        headerGroupComponentFramework: null,
        children: [{
          pinned: "right", headerComponentFramework: null,
          headerName: "",
          suppressSizeToFit: true,
          width: this.pinnedRightTotalColumnWidth,
          field: "plan_label_total",
          //colId: "plan_label_total",
          tooltip: this.cellValueFormatter,
          valueFormatter: this.cellValueFormatter,
          cellClass: this.cellClass
        }],
        headerClass: "black text-right",
        width: this.pinnedRightTotalColumnWidth
      });

      // Average column
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.average"],
        headerGroupComponentFramework: null,
        children: [{
          pinned: "right", headerComponentFramework: null, suppressSizeToFit: true,
          width: this.pinnedRightColumnWidth,
          field: "plan_label_average",
          //colId: "plan_label_average",
          tooltip: this.cellValueFormatter,
          valueFormatter: this.cellValueFormatter,
          headerName: "",
          cellClass: this.cellClass
        }],
        headerClass: "black text-right",
        width: this.pinnedRightColumnWidth
      });
    }

    if (response.interval === DetailViewMode.Monthly) {
      // Parameters column
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.param"],
        pinned: "left",
        field: "plan_label_param",
        //colId: "plan_label_param",
        tooltipField: "plan_label_param",
        width: this.pinnedLeftColumnWidth
      });
      let columns = response.headers;
      for (var columnKey in columns) {
        if (columns.hasOwnProperty(columnKey)) {

          let startDateStr = columns[columnKey].startDate;
          var startDate = new Date(startDateStr + "T12:00:00Z");
          let lastDayOfMon = this.lastday(startDate.getFullYear(), startDate.getMonth());
          let endDateStr = format(lastDayOfMon, "yyyy-MM-dd", {locale: dateLocale});
          let colDef: any = {
            field: columnKey,
            //colId: columnKey,
            startDate: startDateStr,
            endDate: endDateStr,
            headerComponentParams: {updateViewMode: this.updateViewMode.bind(this)},
            headerComponentFramework: PlanDetailClickableHeaderComponent,
            headerName: format(startDate, "MMM yyyy", {locale: dateLocale}),
            headerNameFull: format(startDate, "MMM yyyy", {locale: dateLocale}),
            suppressSizeToFit: true,
            width: 110
          };
          colDef.lockPosition=true;
          colDef.cellEditorSelector = this.cellEditor;
          colDef.editable = this.cellEditable.bind(this);
          colDef.valueFormatter = this.cellValueFormatter;
          colDef.cellEditorParams = this.cellEditorParam;
          colDef.onCellValueChanged = this.cellValueChanged;
          colDef.validator = ValidatorFactory.getValidator;
          colDef.valueSetter = this.cellValueSetter.bind(this);
          colDef.cellClass = this.cellClass;
          if (columns[columnKey].partialFlag) {
            colDef.headerClass = "partial-field"
          }
          colDefs.push(colDef);
        }
      }
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.total"],
        pinned: "right",
        field: "plan_label_total",
        //colId: "plan_label_total",
        tooltip: this.cellValueFormatter,
        headerClass: "black text-right",
        width: this.pinnedRightTotalColumnWidth,
        valueFormatter: this.cellValueFormatter,
        cellClass: this.cellClass,

      });
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.average"],
        pinned: "right",
        field: "plan_label_average",
        //colId: "plan_label_average",
        tooltip: this.cellValueFormatter,
        valueFormatter: this.cellValueFormatter,
        headerClass: "black text-right",
        width: this.pinnedRightColumnWidth,
        cellClass: this.cellClass
      });
    }

    return colDefs;

  }

  public lastday = (y, m) => {
    return new Date(y, m + 1, 0);
  };

  public floor(num: any, dec: any) {
    if (num && num.toString().includes(".")) {
      var array = num.toString().split(".");
      var decPart = array.pop();
      if (!decPart) {
        decPart = "0";
      }
      array.push(decPart.substring(0, dec));
      var result = array.join(".");
      return result;
    } else {
      return num;
    }
  }

  public cellClass = (params: any) => {
    const baseClass = this.cellEditable(params) ? "cell-editable " : "";
    let paramName: PlanParam = null;
    let cellMetadata = null;
    let cssClass = "";
    if (params.data && params.data.metadata) {
      paramName = <PlanParam>PlanParam[params.data.metadata.paramName];

      if(!paramName && params.data.metadata.parent){
        paramName = <PlanParam>PlanParam[params.data.metadata.parent + "_SUB"];
      }
      cellMetadata = params.data.metadata.cellmetadata[params.colDef.field];
      if (cellMetadata && cellMetadata.paramStatus === PlanCellType.CLOSED) {
		cssClass = baseClass + "closed-field";
        return cssClass;
      }
      else if (cellMetadata && cellMetadata.paramStatus === PlanCellType.CALCULATED) {
        cssClass = baseClass + "calculated-field";
      }
    }

    if(params.colDef.field == "plan_label_average" || params.colDef.field == "plan_label_total" ){
      return params.value < 0 ? "negative-value" : "calculated-field";
    }
    if (this.entityType != EntityType.CT
            && this.selectedContactType.value === PlanDetailComponent.ALL_CONTACT_TYPES
            && paramName !== PlanParam.CONTACTS
            && paramName !== PlanParam.AHT) {
      let retCssClass = params.value < 0 ? "negative-value" : "calculated-field";
      return this.isPartial(params) ? retCssClass + " partial-field" : retCssClass;
    }
    switch (paramName) {
      case PlanParam.BASE_FTE:
      case PlanParam.FTE_REQUIRED:
        cssClass = "calculated-field";
        break;
      case PlanParam.FTE_REQ_DIFF:
      case PlanParam.FTE_COST_DIFF:
        cssClass = params.value < 0 ? "negative-value" : "calculated-field";
        break;
      case PlanParam.ABS_ATTRITION:
      case PlanParam.NEW_HIRE_FTE:
        if (this.gridContext.viewMode === DetailViewMode.Monthly) {
          cssClass = baseClass + "calculated-field";
        }
        break;
      // default:
      //   cssClass = baseClass;
    }
    if (this.isPartial(params)) {
      cssClass = cssClass + " partial-field";
    }
    return cssClass;
  };

  public isPartial(params) {
    var field = params.colDef.field;
    let cellMeta = params.data.metadata !== undefined ? params.data.metadata.cellmetadata[field] : null;
    return cellMeta && cellMeta.partialFlag;
  }

  public hasVariance(params) {
    var field = params.colDef.field;
    let cellMeta = params.data.metadata.cellmetadata[field];
    return cellMeta && cellMeta.varianceFlag;
  }

  public setVarianceOff(params) {
    if (this.hasVariance(params)) {
      var field = params.colDef.field;
      let cellMeta = params.data.metadata.cellmetadata[field];
      cellMeta.varianceFlag = false;
    }
  }

  cellValueSetter(params) {
    var decimal = params.data.metadata.decimal;
    var newVal = params.newValue ? this.floor(params.newValue, decimal) : params.newValue;
    var oldVal = params.oldValue ? this.floor(params.oldValue, decimal) : params.oldValue;

    var data = params.data;
    var result = false;

    if (newVal !== oldVal) {
      if (this.hasVariance(params)) {
        data[params.colDef.field] = newVal;
        this.confirmAndUpdate(params, oldVal);
      } else {
        data[params.colDef.field] = newVal;
        result = true;
      }

    } else {
      result = false;
    }
    return result;

  }

  confirmAndUpdate(params, oldVal) {
    let planParam: PlanParam = <PlanParam>PlanParam[params.data.metadata.paramName];
    //Parent parameter will set the warning message
    if(params.data.metadata.parent){
      planParam = <PlanParam>PlanParam[params.data.metadata.parent];
    }
    let confMsg = ConfirmationMsgFactory.getMsg({
      paramName: planParam,
      viewMode: this.gridContext.viewMode
    });

    const dgOption: DialogOptions = new DialogOptions();
    dgOption.titleKey = confMsg.title;
    dgOption.messageKey = confMsg.msg;
    dgOption.msgType = "warn";
    const dialog = modalComponent.showModalMessage(dgOption, this.modalService);
    dialog.then((yes) => {
      this.setVarianceOff(params);
      var refreshParam = {
        rowNodes: [params.node],
        columns: [params.column]
      };
      this.gridApi.refreshCells(refreshParam);
      this.cellValueChanged(params);

    }, (no) => {
      params.data[params.colDef.field] = oldVal;
      var refreshParam = {
        rowNodes: [params.node],
        columns: [params.column]
      };
      this.gridApi.refreshCells(refreshParam);
      this.rowData=[...this.rowData];//We must refresh the grid because the chart needs to revert as well

    });
  }

  public cellValueFormatter = (params: any) => {
    if (params && params.data && params.data.metadata) {
      let options = {decimal: params.data.metadata.decimal};
      return NumberUtils.formatNumberIfNecessary(params.value, this.locale, options.decimal);
    }
  };

  public cellValueChanged = (params: any) => {
    var field = params.colDef.field;
    var decimal = params.data.metadata.decimal;
    const cellMetadata = params.data.metadata.cellmetadata[field];
    const origValue = cellMetadata?cellMetadata.origValue: null; // raw value to preserve precision
    var newVal = params.newValue !== "" ? Number(params.newValue).toFixed(decimal) : params.newValue;
    var oldVal = params.oldValue ? Number(params.oldValue).toFixed(decimal) : params.oldValue;
    var validator = params.data.metadata.validator;
    if (oldVal !== newVal && validator.isValid(params.newValue,cellMetadata)) {
      this._isLoading = true;
      this._dataIsSaved = false;
      this.gridApi.showLoadingOverlay();
      let payload: EditPlanDetailRequestDTO = {
        planId: this.planId,
        startDate: params.colDef.startDate,
        endDate: params.colDef.endDate,
        interval: this.gridContext.viewMode,
        planParameterLabel: params.data.metadata.paramName,
        editedValue: newVal,
        previousValue: origValue,
        ctOid: this.getSelectedCtOid()
      };
      this.store.dispatch(new EditPlanDetail(payload));
    }

  };

  public saveButtonClicked = () => {
    this.savePlan();
  };

  private savePlan(){
    this._isLoading = true;
    this.gridApi.showLoadingOverlay();
    this.store.dispatch(new SavePlanDetail(this.planId));
  }


  public cellEditable = (params: any) => {
    let editable = false;
    let paramName: PlanParam = null;

    if(params.colDef) { //charts doesn't have col definition
      var field = params.colDef.field;
      let cellMetadata = null;
      if (params.data && params.data.metadata && params.data.metadata.cellmetadata) {
        cellMetadata = params.data.metadata.cellmetadata[field];
      }
      if (!params.data || !params.data.metadata || !params.data.metadata.paramName
        || (cellMetadata && cellMetadata.paramStatus === PlanCellType.CLOSED)) {
        return false;
      }
    }
    paramName = <PlanParam>PlanParam[params.data.metadata.paramName];

    //Parent parameter will decide if it's editable or not
    if(params.data.metadata.parent){
      paramName = <PlanParam>PlanParam[params.data.metadata.parent];
    }

    if (this.entityType !== EntityType.CT
          && this.selectedContactType.value === PlanDetailComponent.ALL_CONTACT_TYPES
          && paramName !== PlanParam.CONTACTS
          && paramName !== PlanParam.AHT) {
      return false;
    }
    switch (paramName) {
      case PlanParam.ACTUAL_FTE:
        if (this.gridContext.viewMode !== DetailViewMode.Monthly) {
          editable = true;
        }
        break;
      case PlanParam.ASA:
      case PlanParam.MS_EFFICIENCY:
      case PlanParam.CONTACTS:
      case PlanParam.HOURLY_RATE:
      case PlanParam.OCCUPANCY:
      case PlanParam.SHRINKAGE:
      case PlanParam.SERVICE_LEVEL_PERCENT:
      case PlanParam.SERVICE_LEVEL_TIME:
        editable = true;
        break;

      case PlanParam.NEW_HIRE_FTE:
      case PlanParam.ABS_ATTRITION:
        if (this.gridContext.viewMode === DetailViewMode.Weekly) {
          editable = true;
        }
        break;
      case PlanParam.AHT:
        editable = true;
        break;
      default:
        break;
    }
    return editable;
  };

  public cellEditor = (params: any) => {
    let paramName: PlanParam = <PlanParam>PlanParam[params.data.metadata.paramName];

    if(!paramName && params.data.metadata.parent){
      paramName = <PlanParam>PlanParam[params.data.metadata.parent + "_SUB"];
    }

    var editor = {component: null, param: params};
    switch (paramName) {
      case PlanParam.ACTUAL_FTE:
      case PlanParam.SHRINKAGE:
      case PlanParam.SHRINKAGE_SUB:
      case PlanParam.ASA:
      case PlanParam.MS_EFFICIENCY:
      case PlanParam.CONTACTS:
      case PlanParam.HOURLY_RATE:
      case PlanParam.OCCUPANCY:
      case PlanParam.NEW_HIRE_FTE:
      case PlanParam.AHT:
      case PlanParam.SERVICE_LEVEL_PERCENT:
      case PlanParam.SERVICE_LEVEL_TIME:
      case PlanParam.ABS_ATTRITION:
        editor.component = "numericEditor";
        break;

      default:
        break;
    }
    return editor;
  };

  public cellEditorParam = (params: any) => {
    let paramName: PlanParam = <PlanParam>PlanParam[params.data.metadata.paramName];
    const param = {
      origValue: params.data[params.colDef.field],
      metadata: params.data.metadata,
      locale: this.locale
    };
    //gets added to the editor parameter
    return param;
  };

  private getCTFromResponse(response:PlanDetailResponse){
      let result = response.ctInfo.map((ct)=>{
        return {
          label:ct.id + " " + ct.name,
          value:ct.oid,
          object:ct
        }
      });
      if(response.entityType!="CT") {
        result.unshift({label: this.translate.instant("plan.details.cts.all"), value: PlanDetailComponent.ALL_CONTACT_TYPES, object: null});
      }
      return result;
  }

  protected getRowsFromResponse(response) {

    let rowGroup = 1;
    let rows = [];
    let localParams : any = PlanParam;
    delete localParams["MEAN_CONCURRENCY"];
    if (this.gridContext.viewMode === DetailViewMode.Daily) {
      localParams = this.removeNonDailyParams(PlanParam);
    } else if(this.gridContext.viewMode === DetailViewMode.Weekly) {
      localParams = this.removeNonWeeklyParams(PlanParam)
    } else if(this.gridContext.viewMode === DetailViewMode.Monthly) {
      localParams = this.removeNonMonthlyParams(PlanParam)
    }

    // Workload parameters not supported in v1 plans
    localParams = this.removeWorkloadParams(localParams)

    // historical data parameters only available if feature toggle esp-histdatainplan-esp2740 is enable.
    if (!this._historicalDataInPlanFeature) {
      localParams = PlanDetailCommonHelper.removeHistoricalDataParams(localParams);
    }


    Object.keys(localParams).map(paramKey => {
      let remoteParam = response.params.find(obj => obj.paramName == paramKey);
      let row = this.getRow(remoteParam,paramKey,rowGroup);
      rows.push(row);

      rowGroup = Math.abs(rowGroup - 1);

      let remoteParamChildren = response.params.filter(obj => obj.parent == paramKey);
      if (remoteParamChildren.length > 0) {
        remoteParamChildren.forEach((remoteChild)=>{
          let childRow = this.getRow(remoteChild,remoteChild.paramName,rowGroup);
          rows.push(childRow);
        });
        rowGroup = Math.abs(rowGroup - 1);
      }

    });

    return rows;
  }
  protected getRow(remoteParam,paramKey,rowGroup){
    let rowTemp = {};

    //if it's a static parameter, we have a translation for it
    if(PlanParam[paramKey]){
      // historical data parameters if feature toggle esp-histdatainplan-esp2740 is enable.
      // plan.detail.aht.forecast it will be plan.detail.aht.forecast
      // and plan.detail.contacts.recv will be plan.detail.contacts.recv.forecast
      if (this._historicalDataInPlanFeature &&
        (PlanParam[paramKey] === PlanParam.CONTACTS || PlanParam[paramKey] === PlanParam.AHT)) {
        rowTemp["plan_label_param"]=this.translate.instant(`${PlanParam[paramKey]}.forecast`);
      } else {
        rowTemp["plan_label_param"]=this.translate.instant(PlanParam[paramKey]);
      }
    }

    if (remoteParam && remoteParam.data) {

      let paramName: PlanParam = <PlanParam>PlanParam[paramKey];

      if(!paramName && remoteParam.parent){
        paramName = <PlanParam>PlanParam[remoteParam.parent + "_SUB"];
      }

      let validator = ValidatorFactory.getValidator(paramName, remoteParam, this.translate, this.locale);
      const rowMetaData: any = {decimal: remoteParam.decimal, paramName: paramKey, validator: validator, cellmetadata: new Map<String, any>(),};

      const precision = Math.pow(10, remoteParam.decimal);


      if (remoteParam.metadata) {
        for (let [key, value] of Object.entries(remoteParam.metadata)) {
          rowMetaData.cellmetadata[key]=Object.assign({},value);
        }
      }
      const options = this.getOptionsFromRow(remoteParam);
      for (const cellKey in remoteParam.data) {
        if (remoteParam.data.hasOwnProperty(cellKey)) {
          const val = remoteParam.data[cellKey];
          if (rowMetaData.cellmetadata[cellKey]) {
            rowMetaData.cellmetadata[cellKey].origValue = val;
          } else {
            rowMetaData.cellmetadata[cellKey]= {origValue:val};
          }// raw value to preserve precision
          //rowTemp[cellKey] = Number(val)!==NaN ? Math.round(Number(val) * precision) / precision : val;//this.formatNumberIfNecessary(remoteParam.data[cellKey],paramKey,options);
          rowTemp[cellKey] = Math.abs(Number.parseFloat(val)) >= 0 ? Math.round(Number(val) * precision) / precision : val;

        }
      }
      rowTemp["metadata"] = rowMetaData;
    }

    rowTemp["plan_label_total"] = remoteParam && remoteParam.total ? remoteParam.total : "";
    rowTemp["plan_label_average"] = remoteParam && remoteParam.average ? remoteParam.average : "";
    rowTemp["rowGroup"] = rowGroup;

    //If we're a child row
    if(remoteParam && remoteParam.parent){
      rowTemp["plan_label_param"] = remoteParam.label;
      rowTemp["rowChild"] = true;
      rowTemp["metadata"].parent = remoteParam.parent;
    }

    return rowTemp;

  }
  protected getOptionsFromRow(row) {
    var options = {
      decimal: null
    };
    if ("decimal" in row) {
      options.decimal = row.decimal;
    }
    return options;
  }

  protected removeNonDailyParams(PlanParam) {
    var params = {};
    Object.keys(PlanParam).map(rowLabel => {
      params[rowLabel] = PlanParam[rowLabel];
    });
    delete params["ACTUAL_FTE"];
    delete params["ATTRITION"];
    delete params["ABS_ATTRITION"];
    delete params["NEW_HIRE_FTE"];
    delete params["FTE_REQ_DIFF"];
    delete params["FTE_ACT_COST"];
    delete params["FTE_COST_DIFF"];
    delete params["PROJECTED_SERVICE_LEVEL_PERCENT"];
    delete params["PROJECTED_ASA"];
    delete params["PROJECTED_OCCUPANCY"];
    delete params["SHRINKAGE_SUB"];
    return params;
  }

  protected removeNonWeeklyParams(PlanParam) {
    var params = {};
    Object.keys(PlanParam).map(rowLabel => {
      params[rowLabel] = PlanParam[rowLabel];
    });
    delete params["SHRINKAGE_SUB"];
    return params;
  }

  protected removeNonMonthlyParams(PlanParam) {
    var params = {};
    Object.keys(PlanParam).map(rowLabel => {
      params[rowLabel] = PlanParam[rowLabel];
    });
    delete params["SHRINKAGE_SUB"];
    return params;
  }

  protected removeWorkloadParams(params) {
    delete params["CONTACTS_HANDLED"];
    delete params["ACTUAL_FTE_CAPACITY"];
    delete params["BACKLOG"];
    return params;
  }

  protected updateContactType(contactType){
    this.selectedContactType = contactType;

    this.getPlanDetail(this.gridContext.viewMode);
  }

  protected  getSelectedCtOid(){
    if(this.entityType!="CT" && this.selectedContactType && this.selectedContactType.value!=PlanDetailComponent.ALL_CONTACT_TYPES){
      return this.selectedContactType.value
    }
    return null;
  }

  protected addToSubscriptionList(newSubscription: Subscription) {
    this.subscriptionList.push(newSubscription);
  }

  private clearSubscriptionList() {
    if (this.subscriptionList.length > 0) {
      this.subscriptionList.forEach(subscriptionItem => subscriptionItem.unsubscribe());
      this.subscriptionList = null;
    }
  }

  toggleChartButtonClicked() {
    this.isChartView=!this.isChartView;
  }

  public setCustomValue(rowName,field, value){
    var rowNode = this.gridApi.getRowNode(rowName);
    let columns = this.columnApi.getAllColumns();
    let column = columns.find((col)=>col.getColDef().field==field);

    rowNode.setDataValue(column.getColId(), value);
  }

  private shouldSave():boolean{
    return !this.authService.isTokenExpired() && !this._dataIsSaved && this._userHasModify;
  }

  async canDeactivate() {
    if(!this.shouldSave()){
      return true;
    }

    const dgOption: DialogOptions = new DialogOptions();
    dgOption.titleKey = "plan.exit.unsaved.dialog.title";
    dgOption.messageKey = "plan.exit.unsaved.dialog.msg";
    dgOption.msgType = "warn";
    dgOption.showCancel = true;
    dgOption.confirmLabel = "btn.confirm.label";
    try{
      await modalComponent.showModalMessage(dgOption, this.modalService);
      return true;
    }catch(e){
      return false;
    }
  }
  private canDrillDown() {
    if(this.gridContext.viewMode==DetailViewMode.Daily){
      return false;
    }else{
      return true;
    }
  }
  @HostListener("window:beforeunload", ["$event"])
  canCloseTab($event: any) {
    if(this.shouldSave()){
      $event.returnValue=true;
    }
  }
  @HostListener("window:unload", ["$event"])
  tabClosed($event: any){
    PlanHelper.removePlanDetailSessionId(this.planId);
  }

  private updateCurrentRoute() {
    if(this.route.snapshot.firstChild){
      this._isDistributionRoute=true;
    }else{
      this._isDistributionRoute=false;
    }
  }

}

