import { Component, OnInit } from "@angular/core";
import {DatePipe} from '@angular/common';
import {MessageService} from 'primeng/api';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CTDistributionCalcMethod, PlanContactTypes} from '../../../common/enums';

@Component({
  selector: "wfm-esp-plan-settings-dialog",
  templateUrl: "./plan-settings-dialog.component.html",
  styleUrls: ["./plan-settings-dialog.component.css"],
  providers: [DatePipe, MessageService]
})
export class PlanSettingsDialogComponent implements OnInit {
  public _isLoading = false;

  public _enableCancel = true;
  private planSettingsForm: FormGroup;
  private _initialState: string;
  public _isErlangA = false;
  private FCN_PATIENCE_TXT_BOX: string = "medianPatienceTime";
  private FCN_PATIENCE_CHK_BOX: string = "usePatienceTime";
  public _isSubmitted = false;
  private previousCtDistributionCalcMethod = null;
  public MAX_LEN = 4;

  public get _maxLenMpt() {
    return this.MAX_LEN;
  }

  private get _enableAccept(){
    return this._hasChanges && (!this._isSubmitted || this.planSettingsForm.valid);
  }

  private get _hasChanges(){
    return this._initialState!=JSON.stringify(this.planSettingsForm.value);
  }

  public get displayFTEWarning(){
    return this.ctDistributionCalcMethod==CTDistributionCalcMethod.FTE_REQUIRED && this._hasChangeCtDistributionCalcMethod;
  }

  private get _hasChangeCtDistributionCalcMethod() {
    return this.ctDistributionCalcMethod !== this.previousCtDistributionCalcMethod;
  }

  public get ctDistributionCalcMethod(){
    return this.planSettingsForm.controls["ctDistributionCalcMethod"].value;
  }

  public get _showPatienceTimeValidationError() {
    return this._hasChanges && this._isSubmitted && this._patienceTimeValidationErrors !== null;
  }

  public get _patienceTimeValidationErrors() {
    return this.planSettingsForm.get(this.FCN_PATIENCE_TXT_BOX)?.errors;
  }

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {
    this.planSettingsForm = this.fb.group({
      ctDistributionCalcMethod: new FormControl()
    });
  }

  ngOnInit(): void {

  }

  //isDirty is used in case the dialog is open due to a recoverable error
  reset(settings, isDirty, origSettings) {
    this._isErlangA = settings.erlangAFeature;
    this.createForm(this._isErlangA, settings);
    this.planSettingsForm.reset(settings);

    if (isDirty) {
      this._initialState = "";
      this._isSubmitted = true;
    } else {
      this._initialState = JSON.stringify(this.planSettingsForm.value);
    }

    this.previousCtDistributionCalcMethod = origSettings.ctDistributionCalcMethod;
  }

  createForm(erlangAFeature: boolean, settings: any) {
    if (erlangAFeature) {
      this.planSettingsForm = this.fb.group({
        ctDistributionCalcMethod: new FormControl(),
        usePatienceTime: new FormControl(false),
        medianPatienceTime: new FormControl('', this.minMaxValidator(30, 3600))
      });

      if (settings.planSettingsWorkloadType === PlanContactTypes.WORKLOAD) {
        this.planSettingsForm.controls[this.FCN_PATIENCE_CHK_BOX].disable();
        this.planSettingsForm.controls[this.FCN_PATIENCE_TXT_BOX].disable();
      }
      else if (settings.medianPatienceTime == null) {
        this.planSettingsForm.controls[this.FCN_PATIENCE_TXT_BOX].disable();
      }
    } else {
      this.planSettingsForm = this.fb.group({
        ctDistributionCalcMethod: new FormControl()
      });
    }
  }

  submit() {
    this._isSubmitted = true;
    if (this.planSettingsForm.valid) {
      this.close(this.planSettingsForm.value);
    }
  }

  close(result) {
    this.activeModal.close(result);
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  onUsePatienceTime(evt) {
    let formCtrl = this.planSettingsForm.controls[this.FCN_PATIENCE_TXT_BOX];
    if (evt.target.checked) {
      formCtrl.enable();
    } else {
      formCtrl.setValue(null);
      formCtrl.disable();
    }
  }

  minMaxValidator(min, max): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const mptStr = String(control.value).trim();
      const mptNum = Number(mptStr);
      if (control.value === null || mptStr === "" || !Number.isInteger(mptNum)
          || mptNum < min || mptNum > max) {
        return {invalidPatienceTime: {value: control.value}};
      }

      return null;
    };
  }

  mptOnInput(event) {
    let value = event.value?.toString();
    if (value?.length > this.MAX_LEN) {
      value = value.substring(0, this.MAX_LEN);
    }

    this.planSettingsForm.controls[this.FCN_PATIENCE_TXT_BOX].setValue(value);
  }
}
