import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpEventType,} from "@angular/common/http";

import {Action} from "@ngrx/store";
import {Actions, createEffect, Effect, ofType} from "@ngrx/effects";
import {Observable, of} from "rxjs";
import {map, mergeMap, catchError} from "rxjs/operators";
import {
  AppActionTypes, EditHistoricalData, EditHistoricalDataSuccess, GetHistoricalData,
  GetHistoricalDataCTs,
  GetHistoricalDataCTsSuccess, GetHistoricalDataSuccess, SaveHistoricalDataSuccess
} from "../actions";
import {environment} from "src/environments/environment";
import {appSettings} from "src/app/app-settings";
import {AuthenticationService} from "src/app/authentication.service";
import {PlanHelper} from '../../helpers/plan-helper';

@Injectable()
export class HistoricalDataEffects {
  @Effect()
  getHistoricalDataCTs$: Observable<Action> = this.actions$.pipe(
    ofType(AppActionTypes.GetHistoricalDataCTs),
    map((action: any) => action),
    mergeMap((payload: any) => {
      const historicalDataCTsUrl = environment.espEndpointBaseUrl + appSettings.resourceLocations.historicalDataCTs + "?&optionalSlr=ERLANG&optionalSlr=OUTBOUND&optionalSlr=WORKLOAD"; ;
      return this.http.get(historicalDataCTsUrl)
        .pipe(
          // If successful, dispatch success action with result
          map((data: GetHistoricalDataCTs) => {
              return new GetHistoricalDataCTsSuccess(data);
            }
          ),
          // If request fails, dispatch failed action
          catchError((err) => of({type: AppActionTypes.GetHistoricalDataCTsFailed, payload: err})),
        );
    })
  );
  @Effect()
  getHistoricalData$: Observable<Action> = this.actions$.pipe(
    ofType(AppActionTypes.GetHistoricalData),
    map((action: any) => action),
    mergeMap((action: any) => {
      const historicalDataUrl = environment.espEndpointBaseUrl + appSettings.resourceLocations.historicalData + "/retrieve";
      let headers = new HttpHeaders().append("hist-data-session-key", action.sessionId);

      return this.http.post(historicalDataUrl, action.payload, {headers})
        .pipe(
          // If successful, dispatch success action with result
          map((data: GetHistoricalData) => {
              return new GetHistoricalDataSuccess(data, action.requestId);
            }
          ),
          // If request fails, dispatch failed action
          catchError((err) => of({
            type: AppActionTypes.GetHistoricalDataFailed,
            payload: err,
            requestId: action.requestId
          })),
        );
    })
  );
  @Effect()
  editHistoricalData$: Observable<Action> = this.actions$.pipe(
    ofType(AppActionTypes.EditHistoricalData),
    map((action: any) => action),
    mergeMap((action: any) => {
      const historicalDataUrl = environment.espEndpointBaseUrl + appSettings.resourceLocations.historicalData;
      let headers = new HttpHeaders().append("hist-data-session-key", action.sessionId);

      return this.http.put(historicalDataUrl, {editedData:action.payload}, {headers})
        .pipe(
          // If successful, dispatch success action with result
          map((data: EditHistoricalData) => {
              return new EditHistoricalDataSuccess(data);
            }
          ),
          // If request fails, dispatch failed action
          catchError((err) => of({
            type: AppActionTypes.EditHistoricalDataFailed,
            payload: err,
            requestId: action.requestId
          })),
        );
    })
  );

  saveHistoricalData$ = createEffect(
    () => this.actions$.pipe(
      ofType(AppActionTypes.SaveHistoricalData),
      map((action: any) => action),
      mergeMap((action: any) => {
        const historicalDataUrl = environment.espEndpointBaseUrl + appSettings.resourceLocations.historicalData + "/save";
        let headers = new HttpHeaders().append("hist-data-session-key", action.sessionId);

        return this.http.post(historicalDataUrl, {}, {headers})
          .pipe(
            map((data: any) => {
                return new SaveHistoricalDataSuccess(data);
              }
            ),
            catchError((err) => of({
              type: AppActionTypes.SaveHistoricalDataFailed,
              payload: err
            })),
          );
      })
    )
  );

  constructor(private http: HttpClient, private actions$: Actions, private authService: AuthenticationService) {
  }

}
