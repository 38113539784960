import spanish from "@angular/common/locales/es";
import french from "@angular/common/locales/fr";
import portugese from "@angular/common/locales/pt";
import {registerLocaleData} from "@angular/common";
import {Component, NgZone, OnInit, ViewChild, HostListener} from "@angular/core";
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from "@angular/router";
import {AuthenticationService} from "./authentication.service";
import {TenantService} from "./tenant.service";
import {TranslateService} from "@ngx-translate/core";
import {select, Store} from "@ngrx/store";
import {interval, Observable, zip} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {RefreshPromptComponent} from "./components/refresh-prompt/refresh-prompt.component";

import {AppActionTypes, UpdateMinutesUntilTokenExpiration} from "src/app/store/actions";
import { environment } from "src/environments/environment";
import {Features} from "./models/plan";
import {Actions, ofType} from "@ngrx/effects";
import {MessageService, PrimeNGConfig} from "primeng/api";
import {Tooltip} from "primeng/tooltip"
import {combineLatest} from "rxjs";
import {map, pluck} from "rxjs/operators";
import {ResizeAwareService} from './services/resizeaware.service';
import {ValidateTenantUtils} from "./common/utils/validate.tenant.utils";
import {hash} from "./common/utils/hash"
import { ApiCredentialComponent } from "./components/api-credential/api-credential.component";

@Component({
  selector: "wfm-esp-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [MessageService]
})
export class AppComponent implements OnInit {
  title = "wfm";
  isLoading = true;
  isError = false;
  isValidTenant = false;
  // Hides the top and sidebar by default
  showMainLayout = false;
  showMainLayoutCssObj: any = {"d-none": true};
  urlComesFromRcp = false;

  private refreshPromptThreshold = 16 * 60 * 1000;
  private refreshPromptCheckInterval = 1 * 60 * 1000;
  private isRefreshPrompt = false;

  private readonly supportedLocales = [spanish, french, portugese];


  public username = "-";
  public state$: Observable<any>;
  public isFocused:boolean = true;
  private tokenMinutesUntilExpiration = 0;
  _showImportedForecastsMenu = false;
  isMenuExpanded: any = true;
  public menu: any = [];

  constructor(
    private router: Router,
    private tenantService: TenantService,
    private store: Store<any>,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private ngZone: NgZone,
    private messageService: MessageService,
    private resizeAwareService:ResizeAwareService,
    private action$: Actions,
    private activatedRoute: ActivatedRoute,
    private config: PrimeNGConfig
  ) {
    translate.setDefaultLang("en-US");
    translate.use("en-US");
    this.registerSupportedLocales();
    this.state$ = this.store.select("state");
    this.isMenuExpanded = !(localStorage.getItem("isMenuExpanded")==="false");

  }

  ngOnInit() {
    let self = this;
    this.overrideTooltipDelay();
    this.state$.subscribe((state: any) => {
      this.updateMenuItems(state);
      this.updateShowMainLayout();

      if (state && state.user && state.user.username && state.user.username!=this.username) {
        this.username = state.user.username; //
        ValidateTenantUtils.recordUser(hash(this.username));
      }

      this.tokenMinutesUntilExpiration = state.tokenMinutesUntilExpiration;
    });

    this.action$.pipe(ofType(AppActionTypes.RefreshedTokenFailure))
    .subscribe(()=>{
          this.messageService.add({
            severity: "error",
            detail: this.translate.instant("refresh.prompt.failure",{x:this.tokenMinutesUntilExpiration})
          });
    });

    let samlSsoParameters = this.parseQueryParams();
    if ( samlSsoParameters.locale ) {
      // set translation locale for the pages that are displayed before authentication.
      this.setTranslateLocale(samlSsoParameters.locale);
    }

    if (samlSsoParameters.jtid && samlSsoParameters.locale) {
      this.retrieveAuthenticatedUser(samlSsoParameters.jtid, samlSsoParameters.locale);
    } else if (samlSsoParameters.errorCode === "1") {
      // navigate to unrecoverable-error page.  Do not call validateTenant()
      this.router.navigate(["/unrecoverable-error"]);
    } else if (samlSsoParameters.errorCode === "2") {
      // navigate to sso-login-error page.  Do not call validateTenant()
      this.router.navigate(["/sso-login-error"]);
    } else {
      // validating the tenant's subdomain from parsing the URL
      this.validateTenant(self);
    }

    self.router.events.subscribe((routeEvent) => {
      if (routeEvent instanceof NavigationStart) {
        if (this.router.url === "/") {
          var token = this.getUrlSearchParams("token");
          var locale = this.getLocaleFromWfmLocale(this.getUrlSearchParams("locale"));
          var lineOfBusiness = this.getLobFromWfmLocale(this.getUrlSearchParams("locale"));
          if (token) {
            this.authenticationService.ssoToken = token;
            this.authenticationService.ssoLocale = locale;
            this.authenticationService.ssoLineOfBusiness = lineOfBusiness;
            this.urlComesFromRcp = true;
          }
        }
      }
      if (routeEvent instanceof NavigationEnd) {
        this.showMainLayout = routeEvent.urlAfterRedirects!="/" && routeEvent.urlAfterRedirects.indexOf("login") < 0
          && routeEvent.urlAfterRedirects.indexOf("logout") < 0 && routeEvent.urlAfterRedirects.indexOf("unrecoverable-error") < 0
          && routeEvent.urlAfterRedirects.indexOf("sso-login-error") < 0;
      }
    });

    this.translate.stream("primeng").subscribe(res => {
      this.config.setTranslation(res)
    });

    // start token refresh timer
    this.runOutsideNgZone();
  }

  private setTranslateLocale(locale: string) {
    // set translation locale
    let userLocale = locale.indexOf('_') != -1 ? locale.replace("_", "-") : locale;
    let targetLocale = environment.locales.find(item => item.locale === userLocale);
    let theLocale = (targetLocale) ? targetLocale.locale : environment.defaultLocale;
    this.translate.use(theLocale);
}

  private parseQueryParams() {
    // need to parse both the jtid and locale
    if (this.router.url === "/") {
      let jtidVal = this.getUrlSearchParams("jtid");
      let localeVal = this.getUrlSearchParams("locale");
      let errorCode = this.getUrlSearchParams("errorCode");
      return {jtid: jtidVal, locale: localeVal, errorCode: errorCode};
    } else {
      return {};
    }
  }

  private runOutsideNgZone() {
    this.ngZone.runOutsideAngular(() => {
      //const source = interval(10000);
      const source = interval(this.refreshPromptCheckInterval);
      const subscribe = source.subscribe(val => {
        this.ngZone.run(() => {
          if (this.authenticationService.hasToken) {
            let timeUntilTokenExpiration: number = this.authenticationService.getTimeUntilTokenExpiration();
            this.store.dispatch(new UpdateMinutesUntilTokenExpiration(Math.floor(timeUntilTokenExpiration / 60000)));
            if (timeUntilTokenExpiration < this.refreshPromptCheckInterval) {
              this.isRefreshPrompt = false;
              this.modalService.dismissAll();
              this.logout();
            } else if (timeUntilTokenExpiration < this.refreshPromptThreshold) {
              //} else if(true){
              this.showRefreshPromptDialog();
            }
          }
        });
      });
    });
  }

  private validateTenant(self: this) {
    self.tenantService.validate().then((data: any) => {
      self.isValidTenant = true;
      self.isLoading = false;

      ValidateTenantUtils.recordTenant(data.subdomain);

      if (this.urlComesFromRcp || !data.ssoEnabled) this.espAuthentication(); // ESP authentication.
      else this.redirectToIdpAuthentication(data.wfmSamlEspLoginUrl); // IDP authentication

    }).catch((response) => {
      self.isLoading = false;
      self.isError = true;
      self.isValidTenant = false;
      this.showMainLayout = false;
      if ( response.status === 503 ) {
        // SSO login error.  Show sso-login-error page
        self.isError = false;
        this.router.navigate(["/sso-login-error"]);
      }
    });
  }

  redirectToIdpAuthentication(wfmSamlEspLoginUrl: string) {
    // If user is authenticated we are not going to IDP.
    if (!this.authenticationService.isAuthorized()) {
      window.location.href = wfmSamlEspLoginUrl;
    }
  }

  retrieveAuthenticatedUser(jtid: string, locale: string) {
    this.authenticationService.retrieveAuthenticatedUser(jtid).then(data => {
      return this.authenticationService.entityList();
    }).then(() => {
      this.ifAuthorized();
    }).catch(err => {
      console.log("app component error=" + err);
    });
  }

  espAuthentication() {
    if (this.router.url === "/") {
      if (this.authenticationService.isAuthorized()) {
        this.ifAuthorized();
      } else if (this.authenticationService.ssoToken) {
        this.logUserInSSO();
      } else {
        this.ifNotAuthorized();
      }
    }
  }

  public updateMenuItems(state: any) {
    if (state && state.isLoggedIn) {
      this.showMainLayout = state.isLoggedIn;
      this.loadMenuItems();
    } else {
      this.showMainLayout = false;
    }
  }

  private loadMenuItems() {
    this.menu = [
      { key: "plan.page.title", icon: "icon icon-intraday", route: "plans" },
      { key: "imported.forecasts.page.title", icon: "icon icon-file-directory-create", route: "imported-forecasts" }
    ];
    if (this.authenticationService.hasFeature(Features.HISTORICAL_DATA_MENU)) {
      this.menu.push({ key: "historical-data.page.title", icon: "icon icon-history_data", route: "historical-data" });

    }
  }

  registerSupportedLocales(){
    this.supportedLocales.forEach((l) => registerLocaleData(l));

  }

  /** WFM concatenates the locale and the line of business, like this: en_US_cc
   In ESP, we break these apart and use them separately. **/
  getLocaleFromWfmLocale(wfmLocale) {
    return wfmLocale.slice(0, 5).replace("_", "-");
  }

  getLobFromWfmLocale(wfmLocale) {
    return wfmLocale.slice(-2);
  }


  showRefreshPromptDialog() {
    if (!this.isRefreshPrompt) {
      this.isRefreshPrompt = true;
      const modalRef = this.modalService.open(RefreshPromptComponent, {
        centered: true,
        windowClass: "b-info wfm-modal",
        container: "div.app-wrapper",
        backdropClass: "wfm-modal-backdrop",
        backdrop: "static",
        keyboard: false
      }).result.then((data) => {
        this.isRefreshPrompt = false;
      }, (reason) => {
        this.isRefreshPrompt = false;
        this.logout();
      });
    }
  }

  getUrlSearchParams(name:string) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(window.location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  updateShowMainLayout() {
    this.showMainLayoutCssObj = {
      "d-none": !this.showMainLayout,
      "d-flex": this.showMainLayout,
      "show-mini-sidebar": !this.isMenuExpanded
    };
    // Show Imported Forecasts menu only if the user has permissions
    this._showImportedForecastsMenu = this.authenticationService.hasFeature(Features.IMPORTED_FORECASTS_MENU);
    this.resizeAwareService.NotifyResize();
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  async logout() {
    if (await this.router.navigate(["/logout"])) {
      this.authenticationService.logout()
    }
  }

  public showApiCredentialsDialog() {
      const modalRef = this.modalService.open(ApiCredentialComponent, {
      centered: true,
      windowClass: "b-info wfm-modal",
      container: "body",
      backdrop: "static",
      keyboard: false
    });
  }

  public isPublicApiFeatureEnabled() {
    return this.authenticationService.userData?.publicApiFeature;
  }

  public showApiCredentialsMenuItem() {
    console.debug("userData", this.authenticationService.userData);
    return this.authenticationService.userData?.userRoles?.includes("ESP_ADMIN_ROLE");
  }

  logUserInSSO() {
    this.isLoading = true;
    let self = this;
    this.authenticationService.ssoAuthenticate(self.tenantService.tenantIdentifier)
      .then(
        data => {
          self.authorizeUserSSO();
        })
      .catch(
        err => {
          self.isLoading = false;
          self.ifNotAuthorized();
        });
  }

  authorizeUserSSO() {
    let self = this;
    return this.authenticationService.authorizeUser()
      .then(
        data => {
          self.isLoading = false;
          self.ifAuthorized();
        }
      )
      .catch(function (err) {
        self.isLoading = false;
      });
  }

  ifAuthorized() {
    this.showMainLayout = true;
    this.isRefreshPrompt = false;
    this.router.navigate(["/plans"]);
  }

  ifNotAuthorized() {
    this.router.navigate(["/login"]);
  }

  gotoHelp(){
    let helpUrl = environment.espHelpUrl;
    let language = this.translate.currentLang ? this.translate.currentLang.substring(0,2) : environment.defaultLocale.substring(0, 2);
    let featureHelpLocalizationIsOn = this.authenticationService.hasFeature(Features.HELP_LOCALIZATION);
    if (featureHelpLocalizationIsOn) {
      helpUrl =`${environment.espHelpUrl}/${language}/content/home.htm`;
    }
    window.open(helpUrl);
  }

  @HostListener("window:storage", ["$event"])
  storageListener(e: any) {
    // this.isFocused is used because:
    // IE11 fires this event on the same window/tab as well
    // document.hidden does not work on IE11 when multiple window is opened.
    if (!this.isFocused && e.key === "userData" && (e.newValue === null || e.newValue === "")) {
      this.logout();
    }
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.isFocused = true;
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.isFocused = false;
  }
  @HostListener("window:orientationchange", ["$event"])
  onOrientationChange(event: any): void {
    this.resizeAwareService.NotifyResize();
  }

  handleMenuItemClickEvent($event: any) {

  }

  onSidebarToggle() {
    //let self = this;
    //self.isMenuWidthTransitioning=true;
    //setTimeout(function() {self.isMenuWidthTransitioning=false;},800);
    this.isMenuExpanded=!this.isMenuExpanded;
    this.updateShowMainLayout();
    localStorage.setItem("isMenuExpanded",this.isMenuExpanded.toString())
  }

  private overrideTooltipDelay() {
    let tooltipBase:any = Tooltip;
    let getOptions = tooltipBase.prototype.getOption;
    tooltipBase.prototype.getOption = function getOption(option){
      if(option==="showDelay"){
        return 500;
      }else{
        return getOptions.call(this,option);
      }
    }
  }
}


