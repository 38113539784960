import { PlanRoutingModule } from "./plan-routing.module";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgModule } from "@angular/core";
import { NgSelectModule } from "@ng-select/ng-select";
import { PageModule } from "./pages/page.module";
import { NgbModule } from "../../node_modules/@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "../../node_modules/@angular/common";
import { StoreModule } from "@ngrx/store";
import { reducers } from "./store/reducers/index";
import {BrowserModule} from '@angular/platform-browser';
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { mockBackendProvider, } from "./helpers/mock-backend";
import { AuthenticationGuard } from "src/app/authentication.guard";
import { environment } from "../environments/environment";
import { EffectsModule } from "@ngrx/effects";
import { WfmTokenInterceptor } from "./interceptors/wfm-token.interceptor";
import { PlanEffects } from "./store/effects/plan";
import { AppEffects } from "./store/effects/app";
import { ForecastEffects } from "./store/effects/forecasts";
import {AuthenticationService} from "./authentication.service";
import {DatePipe} from "@angular/common";
import { HighchartsChartModule } from "highcharts-angular";
import {CanDeactivateGuard} from "./helpers/can-deactivate-guard.service";
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {ImportedForecastsRoutingModule} from "./imported-forecasts-routing.module"
import { ToastModule } from "primeng/toast";
import {ComponentModule} from "./components/component.module";
import { TooltipModule } from "primeng/tooltip";
import {HistoricalDataRoutingModule} from './history-routing.module';
import {HistoricalDataEffects} from './store/effects/historical-data';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    PageModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    PlanRoutingModule,
    ImportedForecastsRoutingModule,
    HistoricalDataRoutingModule,
    AppRoutingModule,
    HttpClientModule,
    NgSelectModule,
    HighchartsChartModule,
    NgbModule,
    TooltipModule,
    RouterModule,
    BrowserAnimationsModule,
    ToastModule,
    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      name: "ESP",
      logOnly: environment.production
    }),
    EffectsModule.forRoot([PlanEffects, AppEffects, ForecastEffects,HistoricalDataEffects]),
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ComponentModule
  ],
  providers: [mockBackendProvider, AuthenticationGuard, AuthenticationService, DatePipe,CanDeactivateGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WfmTokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
