import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'wfm-icontextbox',
  templateUrl: './wfm-icontextbox.component.html',
  styleUrls: ['./wfm-icontextbox.component.scss']
})
export class WfmIcontextboxComponent implements OnInit {
  @Input() label = null;
  @Input() inlineStyleObject = {};
  @Input() inputId = null;
  @Input() inputName = null;
  @Input() textValue = "";
  @Input() maxLength: Number = null;
  @Input() iconTooltip: string = null;
  @Input() iconType: string = null;
  @Input() isReadOnly = false;


  @Output() iconEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  iconClicked() {
    this.iconEvent.emit(this.textValue);
  }
}
