export enum EspPermissions {
    MODIFY = "MODIFY",
    VIEW = "VIEW"
}

export enum CTDistributionCalcMethod{
  FTE_REQUIRED = "FTE_REQUIRED",
  MU_CT_DISTRIBUTION = "MU_CT_DISTRIBUTION"
}

export enum PlanContactTypes {
  WORKLOAD = "WORKLOAD",
  NON_WORKLOAD = "NON_WORKLOAD",
  ALL = "ALL",
  NA = "NA"
}
