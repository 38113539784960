import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Component, HostListener, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ColumnApi, GridApi, IDatasource} from "ag-grid-community";
import {format} from "date-fns";
import {PlanDetailHeaderRowComponent} from "../plan-detail-header-row/plan-detail-header-row.component";
import {PlanDetailClickableHeaderComponent} from "../plan-detail-clickable-header/plan-detail-clickable-header.component";
import {BasePage} from "../../base-page";
import {TranslateService} from "@ngx-translate/core";
import {select, Store} from "@ngrx/store";
import { saveAs } from 'file-saver';
import {Actions, ofType} from "@ngrx/effects";
import {Observable, Subscription} from "rxjs";
import {
  AppActionTypes,
  ApplyFTERequired,
  ApplyMuParams,
  EditPlanDetail,
  EditPlanDetailReceived, ExportEveryCt, ExportEveryCtFailed, ExportEveryCtSuccess,
  GetPlanDetail,
  LoadPlanDetail,
  PlanDetailReceived,
  PlanNotExist,
  SavePlanDetail,
  SavePlanSettings
} from "src/app/store/actions";
import {PlanLoadingOverlayComponent} from "../plan-loading-overlay/plan-loading-overlay.component";
import {DatePipe, TitleCasePipe} from "@angular/common";
import {NumericEditorComponent} from "../common/cell-editors/numeric/numeric-editor.component";
import {EditPlanDetailRequestDTO, EntityType, Features, PlanDetailResponse} from "src/app/models/plan";
import {ValidatorFactory} from "../common/validator-factory";
import {PlanCellType, PlanParam} from "../common/plan-param";

import {MessageService} from "primeng/api";
import {NgbDropdownConfig, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {WfmModalComponent as modalComponent} from "src/app/components/wfm-modal/wfm-modal.component";
import {ConfirmationMsgFactory} from "../common/confirm-msg-factory";
import {DialogOptions} from "../../../common/dialog-options";
import {PlanSettingsComponent} from "../plan-settings/plan-settings.component";
import {ConvertPlanDialogComponent} from "../convert-plan-dialog/convert-plan-dialog.component";
import {PlanSaveAsComponent} from "../plan-save-as/plan-save-as.component";
import {AuthenticationService} from "../../../authentication.service";
import {PlanHelper} from "../../../helpers/plan-helper";
import {ColumnId} from "../plan-list-page/plan-list-page.component";
import {filter, take} from "rxjs/operators";
import {BackgroundTask} from "../../../store/application-state";
import {WfmSelectComponent} from "src/app/components/wfm-select/wfm-select.component";
import {NewHireDialogComponent} from "../new-hire-dialog/new-hire-dialog.component";
import {PlanSettingsDialogComponent} from "../plan-settings-dialog/plan-settings-dialog.component";
import {CTDistributionCalcMethod, PlanContactTypes} from "../../../common/enums";
import {NumberUtils} from "../../../common/utils/number.utils";
import {environment} from "../../../../environments/environment";
import {PlanSettingsCtDialogComponent} from "../plan-settings-ct-dialog/plan-settings-ct-dialog.component";
import {ModelUpdatedEvent} from "@ag-grid-enterprise/all-modules";
import {PlanDetailCommonHelper} from "../common/plan-detail-common-helper";
import {DateUtils} from "../../../common/utils/date";
import { MswfRulesDialogComponent } from "../mswf-rules-dialog/mswf-rules-dialog.component";

var dateLocale = DateUtils.getDatefnsLocale("en");

export enum DetailViewMode {
  Daily = "DAY",
  Weekly = "WEEK",
  Monthly = "MONTH"
}
export enum ParamPermission {
    Modify = "MODIFY",
    ReadOnly = "READ"
}

@Component({
  selector: "plan-detail-v2",
  templateUrl: "./plan-detail-v2.component.html",
  styleUrls: ["./plan-detail-v2.component.scss"],
  providers: [MessageService, NgbDropdownConfig, TitleCasePipe]
})
export class PlanDetailComponentV2 extends BasePage implements OnInit, OnDestroy {

  private pinnedRightColumnWidth = 105;
  private pinnedRightTotalColumnWidth = 115;
  private pinnedLeftColumnWidth = 225;
  private dateColumnWidth = 110;
  private dateShortFormat = "M/d";
  public  _isLoading = true;
  public _dataIsSaved = true;
  private _userHasModify = false;

  public planId: String = null;
  public gridApi: GridApi = null;
  public gridParams: any;
  protected subscriptionList: Array<Subscription> = [];
  public datasource: IDatasource;
  public frameworkComponents: any;
  public isChartView=false;
  public chartsCount=0;
  public planName: String;
  public planStartDate: String;
  public planEndDate: String;
  public contactTypeList: any;
  public selectedContactType:any = null;
  public static readonly ALL_CONTACT_TYPES:string = "ALL_CONTACT_TYPES";
  public entityType:any = null;
  public entityId: any;
  public entityName: String;
  public ltForecastName: String;
  public task:BackgroundTask = null;//{progress:55,status:TaskState.InProgress};
  public isPlanLoaded:boolean;
  public _isFTERequiredDirty = false;
  public suppressLoadingOverlay = true; //we don't want to display spinner when plan is loading
  protected translationKeys = ["plan.label.param", "plan.label.total", "plan.label.average", "date.format.short"];
  @ViewChild("grid")
  private grid;
  public isRenderingData = false;
  @ViewChild("muctSelector") muctSelector: WfmSelectComponent;

  colDefs = [];
  colDefsOld = [];

  data = null;
  rowData = null;
  rowDataDaily = [];

  rowDataMonthly = [];

  rowDataWeekly = [];

  private weekStartDate = 0;
  public language = "en";
  public locale = "en-US";
  public userInfo$: Observable<string>;
  public loadingOverlayComponent = "planLoadingOverlay";
  private _isDistributionRoute:boolean;
  public _ctDistMethodFeature:boolean = false;
  public meanConncurrencyFeat:boolean = false;
  public _workloadCtFeature:boolean = false;
  public _erlangAFeature:boolean = false;
  public _historicalDataInPlanFeature:boolean = false;
  public _csvEveryCtFeature:boolean = false;

  private _isMUDirty:boolean = false;
  private columnApi: ColumnApi = null;
  private planSettings: any;
  private localParams : any = PlanParam;
  private planContactTypes: PlanContactTypes = PlanContactTypes.NA;
  private scrollTargetColumn: any;
  private scrollTargetLeft: any;
  public multiStepWorkflow: any;

  constructor(
    private translateSrv: TranslateService,
    private store: Store<any>,
    private messageService: MessageService,
    private action$: Actions,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private ngbDropdownConfig: NgbDropdownConfig,
    private titleCasePipe: TitleCasePipe,
    private authService: AuthenticationService) {

    super(translateSrv);
    this._ctDistMethodFeature = authService.hasFeature(Features.CT_DISTRIBUTION_METHOD);
    this.meanConncurrencyFeat = authService.hasFeature(Features.MEAN_CONCURRENCY);
    this._workloadCtFeature = authService.hasFeature(Features.WORKLOAD_CTS);
    this._erlangAFeature = authService.hasFeature(Features.ERLANG_A);
    this._historicalDataInPlanFeature = authService.hasFeature(Features.HISTORICAL_DATA_IN_PLAN);
    this._csvEveryCtFeature = authService.hasFeature(Features.CSV_EVERY_CT);

    ngbDropdownConfig.placement = "bottom-right";

    this.userInfo$ = this.store.select("state");
    this.userInfo$.subscribe((value: any) => {
      if (value && value.user && value.user.language) {
        if (this.language != value.user.language) {
          dateLocale = DateUtils.getDatefnsLocale(value.user.language);
          this.language = value.user.language;
        }
      }
      if (value && value.locale) {
        this.locale = value.locale;
      }
    });

  }
  get isCTorAllCT(){
    return (this.selectedContactType && this.selectedContactType.type == MUCTKey.CT ||
      this.selectedContactType && this.selectedContactType.value === PlanDetailComponentV2.ALL_CONTACT_TYPES);
  }
  get UnappliedChanges(){
    return this._isLoading || this._isMUDirty || this._isFTERequiredDirty
  }

  isDisableExportAsCsv():boolean {
    return (this.planSettings && this.planSettings.ctDistributionCalcMethod == CTDistributionCalcMethod.FTE_REQUIRED)
      && this._isFTERequiredDirty;
  }

  isDisableExportEveryCt():boolean {
    let isDisable = false;
    if (this.selectedContactType) {
      if (this.selectedContactType.type == MUCTKey.MU) {
        isDisable = !this.isMUApplyDisabled;
      } else {
        isDisable = this.isDisableExportAsCsv();
      }
    }
    return isDisable;
  }

  async showConvertToActiveDialog(){

    if(this._dataIsSaved) {
      try {
        const modalRef = this.modalService.open(ConvertPlanDialogComponent, {
          centered: true,
          windowClass: "b-info wfm-modal",
          container: "div.plan-detail-container",
          backdrop: "static",
          keyboard: false
        });

        modalRef.componentInstance.planStartDate = this.planStartDate;
        modalRef.componentInstance.planEndDate = this.planEndDate;
        modalRef.componentInstance.planName = this.planName;
        modalRef.componentInstance.planId = this.planId;
        await modalRef.result;
      }catch(e){

      }
    }else{
      let dgOption: DialogOptions = new DialogOptions();
      dgOption.titleKey = "plan.convert.unsaved.dialog.title";
      dgOption.messageKey = "plan.convert.unsaved.dialog.msg";
      dgOption.msgType = "warn";
      dgOption.showCancel = true;
      dgOption.confirmLabel = "btn.confirm.label";
      try{
        let result = await modalComponent.showModalMessage(dgOption, this.modalService);
        this.action$.pipe(
          ofType(AppActionTypes.SavePlanDetailComplete),
          take(1))
          .subscribe((action:any)=>{
            if(action.payload !== null){
              this.showConvertToActiveDialog();
            }

          });
        this.savePlan();
      }catch(e){
        //user canceled
      }


    }
  }


  async showMswfRulesDlg() {
    try {
      const modalRef = this.modalService.open(MswfRulesDialogComponent, {
        centered: true,
        windowClass: "b-info wfm-modal mswf-rules",
        container: "div.plan-detail-container",
        backdrop: "static",
        keyboard: false
      });
      modalRef.componentInstance.mswfData = this.multiStepWorkflow;
      modalRef.componentInstance.planName = this.planName;
      modalRef.componentInstance.displayPlanName = false;


      await modalRef.result;
    } catch (e) {
      console.log(e);
    }
  }

  get isMUgrid(){
    return this.selectedContactType && this.selectedContactType.type == MUCTKey.MU;
  }

  get isMUApplyDisabled(){
    return this._isLoading || !this._isMUDirty;
  }

  get isWorkloadCTgrid(){
    return this.selectedContactType
          && this.selectedContactType.type == MUCTKey.CT
          && this.planSettings?.planContactTypes === PlanContactTypes.WORKLOAD;
  }

  get CTDistMode():CTDistributionCalcMethod{
    if(this.planSettings) {
      return this.planSettings.ctDistributionCalcMethod;
    }else{
      return null;
    }
  }
  setMUGridDirty(dirty:boolean){
    this._isMUDirty = dirty;
    const ctl = [...this.contactTypeList];
    if(dirty){
      //disable ct selections
      for (let index = 0; index < ctl.length; index++) {
        const e = ctl[index];
        if(e.type != MUCTKey.MU){
          e.disabled = true;
        }
      }
    }
    else{
      //enable ct selections
      for (let index = 0; index < ctl.length; index++) {
        const e = ctl[index];
        e.disabled = false;
      }
    }
    this.contactTypeList = ctl;

  }

  applyMUEdits(){
    console.debug("applying MU edits");
    this._isLoading = true;
    //this.gridApi.showLoadingOverlay();
    this.store.dispatch(new ApplyMuParams({planId: this.planId}));
  }

  applyFTERequired(){
    console.debug("applying FTE required");
    this.store.dispatch(new ApplyFTERequired({planId: this.planId}));
  }

  gatherCurrentSettings(): any {
    return {
      ctDistributionCalcMethod: this.planSettings.ctDistributionCalcMethod,
      erlangAFeature: this._erlangAFeature,
      usePatienceTime: this.planSettings.medianPatienceTime == null ? false : true,
      medianPatienceTime: this.planSettings.medianPatienceTime == null ? null : String(this.planSettings.medianPatienceTime),
      planSettingsWorkloadType: this.planSettings.planSettingsWorkloadType == null ? PlanContactTypes.NA : this.planSettings.planSettingsWorkloadType
    }
  }

  showPlanSettingsDialog() {
    this._showPlanSettingsDialog(this.gatherCurrentSettings(), false);
  }

  _showPlanSettingsDialog(settings, isDirty) {
    const modalRef = this.modalService.open(PlanSettingsDialogComponent, {
      centered: true,
      windowClass: "b-info wfm-modal",
      container: "div.plan-detail-container",
      backdrop: "static",
      keyboard: false
    });

    modalRef.componentInstance.reset(settings, isDirty, this.gatherCurrentSettings());

    modalRef.result.then((data) => {
      this.store.dispatch(new SavePlanSettings({planId: this.planId,planSettings:data}));
    }, (reason) => {

    });
  }

  showSettings() {
    const modalRef = this.modalService.open(PlanSettingsComponent, {
      centered: true,
      windowClass: "b-info wfm-modal",
      container: "div.plan-detail-container",
      backdrop: "static",
      keyboard: false
    });

    modalRef.componentInstance.currentContact = this.selectedContactType;
    modalRef.componentInstance.planVersion = 2;

    modalRef.result.then((data) => {
      this._dataIsSaved = false;
    }, (reason) => {

    });
  }

  showNewHireSettingsDialog() {
    const modalRef = this.modalService.open(NewHireDialogComponent, {
      centered: true,
      windowClass: "b-info wfm-modal",
      container: "div.plan-detail-container",
      backdrop: "static",
      keyboard: false
    });

    modalRef.componentInstance.currentContact = this.selectedContactType;
    modalRef.componentInstance.planVersion = 2;

    modalRef.result.then((data) => {
      this._dataIsSaved = false;
    }, (reason) => {

    });
  }

  showCtSettingsDialog() {
    const modalRef = this.modalService.open(PlanSettingsCtDialogComponent, {
      centered: true,
      windowClass: "b-info wfm-modal",
      container: "div.plan-detail-container",
      backdrop: "static",
      keyboard: false
    });

    modalRef.componentInstance.selectedOid = this.getSelectedCtOid();

    modalRef.result.then((data) => {
      this._dataIsSaved = false;
    }, (reason) => {

    });
  }

  async showSaveAsDlg() {
    try {
      const modalRef = this.modalService.open(PlanSaveAsComponent, {
        centered: true,
        windowClass: "b-info wfm-modal",
        container: "div.plan-detail-container",
        backdrop: "static",
        keyboard: false
      });
      let component = modalRef.componentInstance;
      component.planId = this.planId;
      component.entityType = this.entityType;
      component.entityId = this.entityId;
      component.entityName = this.entityName;
      component.ltfcstName = this.ltForecastName;
      component.planStartDate = this.planStartDate;
      component.planEndDate = this.planEndDate;

      await modalRef.result;
    } catch(error) {
      if (error) {
        console.log("error during showSaveAsDlg: " + error.message);
      }
    }
  }

  public gridContext: any = {viewMode: DetailViewMode.Monthly};

  //commented due to popover flickering
  // get gridContext() {
  //   return {updateViewMode: this.updateViewMode.bind(this)}
  // }

  public updateViewMode(isCollapsed,column=null) {
    if(column){
      this.setScrollTarget(isCollapsed,column);
    }
    if(this.isLoading()){
      return;
    }

    let newMode: DetailViewMode = null;
    switch (this.gridContext.viewMode) {
      case DetailViewMode.Daily:
        if (isCollapsed) {
          newMode = DetailViewMode.Weekly;
        }
        break;
      case DetailViewMode.Weekly:
        if (isCollapsed) {
          newMode = DetailViewMode.Monthly;
        } else {
          newMode = DetailViewMode.Daily;
        }
        break;
      case DetailViewMode.Monthly:
        if (!isCollapsed) {
          newMode = DetailViewMode.Weekly;
        }
        break;
    }

    this.getPlanDetail(newMode);

  }

  ngOnInit() {
    super.ngOnInit();
    this.frameworkComponents = {
      // agColumnHeaderGroup: PlanDetailHeaderRowComponent,
      // agColumnHeader: PlanDetailClickableHeaderComponent,
      planLoadingOverlay: PlanLoadingOverlayComponent,
      numericEditor: NumericEditorComponent
    };

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(()=>{
      this.updateCurrentRoute();
    });

    this.route.params.subscribe(params => {
      this.planId = params["id"];
    });
    this.translationResults.subscribe((result) => {
      this.updateTranslationObj(result);
      if (this.grid && this.grid.api) {
        this.grid.api.refreshHeader();
      }
    });

    this.gridContext = {
      updateViewMode: this.updateViewMode.bind(this),
      showMessage: this.showMessage.bind(this),
      viewMode: this.gridContext.viewMode,
      isLoading: this.isLoading.bind(this),
      cellEditable: this.cellEditable.bind(this),
      setCustomValue:this.setCustomValue.bind(this),
      canDrillDown:this.canDrillDown.bind(this)
    };

    this.initHandlers();
    this.getPlanDetail(this.gridContext.viewMode);
    this.updateCurrentRoute();
  }

  ngOnDestroy(): void {
    this.clearSubscriptionList();
    PlanHelper.removePlanDetailSessionId(this.planId);
  }

  public isLoading():boolean{
    return this._isLoading;
  }

  async showMessage(titleKey, msgKey, type) {

    const dgOption: DialogOptions = new DialogOptions();
    dgOption.titleKey = titleKey;
    dgOption.messageKey = msgKey;
    dgOption.msgType = type;
    dgOption.showCancel = false;
    dgOption.confirmLabel = "btn.ok.label";
    const dialog = modalComponent.showModalMessage(dgOption, this.modalService);
    return dialog;
  }

  onGridReady(params: any) {
    this.gridParams = params;
    this.gridApi = params.api;
/*    this.gridApi.addEventListener("gridColumnsChanged",()=>{

    })*/
    this.columnApi = params.columnApi;

    this.suppressLoadingOverlay=false;
    this.gridApi.hideOverlay()

    if(this._isLoading && !this.task){
      this.gridApi.showLoadingOverlay();
    }
  }

  getPlanDetail(mode: DetailViewMode) {
    this._isLoading = true;

    const entityType = this.selectedContactType?this.selectedContactType.type:"ALL"
    let payload = {
      planId: this.planId,
      interval: mode,
      oid: this.getSelectedCtOid(),
      entity_type: entityType,
      histdata: false
    };

    //If we don't have the plan details in cache, we have to trigger a background task
    if(PlanHelper.isSessionExist(this.planId?.toString())){
      if(this.gridApi){
        this.gridApi.showLoadingOverlay();
      }
      this.store.dispatch(new GetPlanDetail(payload));
    }else{
      if (this._historicalDataInPlanFeature) {
        payload.histdata = true;
      }
      this.store.dispatch(new LoadPlanDetail(payload));
    }
  }

  initHandlers() {
    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.PlanDetailReceived))
        .subscribe(this.planDetailReceiveHandler)
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.PlanNotExist))
        .subscribe(this.planNotExistHandler)
        );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.EditPlanDetailReceived))
        .subscribe(this.editPlanDetailReceiveHandler)
    );
    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.EditPlanDetailError))
        .subscribe(this.editPlanDetailErrorHandler.bind(this))
    );
    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.SavePlanDetailComplete))
        .subscribe(this.savePlanDetailCompleteHandler)
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ConvertPlanFailed))
        .subscribe(this.convertPlanFailedHandler)
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.SaveAsPlanSuccessReceived))
        .subscribe(this.saveAsSuccessHandler)
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.SaveAsPlanFailed))
        .subscribe(this.saveAsFailedHandler)
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ApplyStaffingDataSuccess))
        .subscribe(this.applyStaffingDataSuccessHandler.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ApplyMuParamsSuccess))
        .subscribe(this.applyMuParamsSuccessHandler.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ApplyMuParamsError))
        .subscribe(this.applyMuParamsErrorHandler.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.SavePlanSettingsSuccess))
        .subscribe(this.savePlanSettingsSuccessHandler.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.SavePlanSettingsError))
        .subscribe(this.savePlanSettingsErrorHandler.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ApplyFTERequiredSuccess))
        .subscribe(this.applyFTERequiredSuccessHandler.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ApplyFTERequiredError))
        .subscribe(this.applyFTERequiredErrorHandler.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ExportEveryCtSuccess))
        .subscribe(this.exportEveryCtSuccess.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ExportEveryCtFailed))
        .subscribe(this.exportEveryCtFailed.bind(this))
    );



    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.TaskCreateAction,
                               AppActionTypes.TaskStartAction,
                               AppActionTypes.TaskStatusAction,
                               AppActionTypes.TaskErrorAction,
                               AppActionTypes.TaskCancelAction,
                               AppActionTypes.TaskCompleteAction)
      ).subscribe(this.taskHandler.bind(this))
    )
  }

  onBtnExport(): void {
    const properCase:any = (p:string) => {return p.charAt(0).toUpperCase() + p.substr(1).toLowerCase()};
    const viewLevel:any = this.translate.instant("plan.viewMode." + properCase(this.gridContext.viewMode.toString()));
    const params: any = {
      columnGroups: true,
      allColumns: true,
      fileName: `${this.planName} - ${this.selectedContactType.label} - ${viewLevel}`,
      columnSeparator: ",",
      customHeader: `"${this.planName}","${this.selectedContactType.label}"`,
      processCellCallback: (p: any) => {
        const params: any = { value: p.value, data: p.node.data, colDef: p.column.colDef };

        // code to skip new parameters of esp-histdatainplan-esp2740 because not data.
        if (!(params.data.metadata && params.data.metadata.cellmetadata)) {
          return "";
        }

        const cellMetadata: any = params.data.metadata.cellmetadata[params.colDef.field];
        if (cellMetadata && cellMetadata.paramStatus === PlanCellType.CLOSED)
          return "";
        else
          return this.cellValueFormatter(params);
      }
    };
    this.gridApi.exportDataAsCsv(params);
  }

  onBtnExportEveryCt(): void {
    let payload = {
      planId: this.planId,
      interval: this.gridContext.viewMode,
      planName: this.planName
    };
    this._isLoading = true;
    this.gridApi.showLoadingOverlay();
    this.store.dispatch(new ExportEveryCt(payload));
  }

  exportEveryCtSuccess(action: ExportEveryCtSuccess) {
    let interval = this.titleCasePipe.transform(action.payload.request.interval);
    const localizedInterval = this.translate.instant("plan.viewMode." + interval);
    const fileName = this.translate.instant("plan.exporteveryctcsv.filename", {0:action.payload.request.planName, 1:localizedInterval});
    saveAs(action.payload.body, fileName);
    this._isLoading = false;
    this.gridApi.hideOverlay();
  }

  exportEveryCtFailed(action: ExportEveryCtFailed) {
    this._isLoading = false;
    this.messageService.add({severity: "error", detail:this.translate.instant("unrecoverable.err.msg")});
    this.gridApi.hideOverlay();
  }

  addMeanConcurrencyDataTemp(action:PlanDetailReceived){
    let response:any = {...action.payload};
    let mc = {...response.params[0]};
    mc.paramName = "MEAN_CONCURRENCY";
    mc.decimal = "1";
    let tempData = {};
    let tempMetaData = {};
    for(const [key, value] of Object.entries(mc.data)){
      tempData[key] = "1";
      tempMetaData[key] = {paramStatus: "NONE"}
    }
    mc.data = tempData;
    mc.metadata = tempMetaData;
    mc.total = "";
    mc.average = "1";

    response.params = [...response.params, mc];
    return response;
  }
  planDetailReceiveHandler = (action: PlanDetailReceived) => {
    this.localParams = PlanParam;
    this.isRenderingData=true;
    setTimeout(()=>{
      if (action.payload) {

        let response:any = action.payload;
        if(!this.meanConncurrencyFeat){
          delete this.localParams["MEAN_CONCURRENCY"];
        }else {
          if(response.params.find(obj => obj.paramName == "MEAN_CONCURRENCY") == null)
            response = this.addMeanConcurrencyDataTemp(action);
        } //action.payload;
        //console.log(response);
        this.isPlanLoaded=true;
        this.gridContext.viewMode=response.interval;
        this.planName = response.planName;
        this.planStartDate = response.settings.startDate;
        this.planEndDate = response.settings.endDate;
        this.planSettings = response.settings;
        this._userHasModify = false;
        if (response.settings && response.settings.userPlanPermission && response.settings.userPlanPermission === "MODIFY") {
          this._userHasModify = true;
        }
        this.planContactTypes = response.settings.planContactTypes;
        let colDefsTemp = this.getColumnsFromResponse(response);
        let rowDataTemp = this.getRowsFromResponse(response);
        this.colDefs = colDefsTemp;
        this.rowData = rowDataTemp;
        this.multiStepWorkflow = response.multiStepWorkFlow;
        this.contactTypeList = this.getCTFromResponse(response);
        this.entityType = response.entityType;
        this.entityId = response.settings.entityId;
        this.entityName = response.settings.entityName;
        this.ltForecastName = response.settings.ltForecastName;

        // if(!this.selectedContactType){
        //   this.selectedContactType = this.contactTypeList[0];
        // }
      } else { //server error
        if(this.planName && this.isPlanLoaded) {//we already have some grid data so stay on the grid
          this.messageService.add({severity: "error", detail: this.translate.instant("plan.details.interval.failure")});
        }else{
          this.planNotReceived();
        }
      }

      if(this.gridApi){
        this.gridApi.hideOverlay();
        this.gridApi.refreshHeader();
      }
      this._isLoading = false;
    },100)
  }

  planNotReceived = ()=>{
    const dgOption: DialogOptions = new DialogOptions();
    dgOption.titleKey = this.translate.instant("plan.detail.open.failure.title");
    dgOption.messageKey = this.translate.instant("plan.detail.open.failure.msg");
    dgOption.msgType = "error";
    dgOption.showCancel = false;
    dgOption.confirmLabel = "btn.ok.label";
    var dialog = modalComponent.showModalMessage(dgOption, this.modalService);
    const navigateBack = ()=>{
      this.router.navigateByUrl("/plans");
    };
    dialog.then(navigateBack, navigateBack);
  }

  planNotExistHandler = (action: PlanNotExist) => {
    const dgOption: DialogOptions = new DialogOptions();
    dgOption.titleKey = this.translate.instant("plan.notexist.title");
    dgOption.messageKey = this.translate.instant("plan.notexist.msg");
    dgOption.msgType = "error";
    dgOption.showCancel = false;
    dgOption.confirmLabel = "btn.ok.label";
    var dialog = modalComponent.showModalMessage(dgOption, this.modalService);
    const navigateBack = ()=>{
      this.router.navigateByUrl("/plans");
    };
    dialog.then(navigateBack, navigateBack);
  }

  editPlanDetailReceiveHandler = (action: EditPlanDetailReceived) => {
    if (action.payload) {
      const updatedRows = action.payload.editValues;
      updatedRows.forEach((updatedRow: any) => {
        const existingRow = this.rowData.find(obj => {
          return obj.metadata && obj.metadata.paramName == updatedRow.paramName;
        });
        if (existingRow) {
          Object.keys(updatedRow.data).forEach((k) => {
            const precision = Math.pow(10, updatedRow.decimal);
            let val = updatedRow.data[k];
            existingRow.metadata.cellmetadata[k] = Object.assign({}, updatedRow.metadata[k]);
            if (!existingRow.metadata.cellmetadata[k]) {
              existingRow.metadata.cellmetadata[k] = {origValue: val};
            } else {
              existingRow.metadata.cellmetadata[k].origValue = val;
            }// raw value to preserve precision
            existingRow.metadata.cellmetadata[k].workload = this.planContactTypes === PlanContactTypes.WORKLOAD;
            existingRow[k] = Number.parseFloat(val) >= 0 ? Math.round(Number(val) * precision) / precision : val;
            // existingRow[k] = Number(val)?Number(val):val;

          });

          // update total and average
          existingRow["plan_label_total"] = updatedRow.total;
          existingRow["plan_label_average"] = updatedRow.average;
        }
      });

      if(this._ctDistMethodFeature===true){
        if(this.isCTorAllCT) {
          if (this.CTDistMode === CTDistributionCalcMethod.FTE_REQUIRED && action.payload.request.planParameterLabel != "HOURLY_RATE") {
            this.setFTERequiredDirty(true);
          }
        }
      }

      // mu grid dirty check
      if(this.selectedContactType && this.selectedContactType.type == MUCTKey.MU){
        this.setMUGridDirty(true);
      }

      // we must redraw rows since the cell-class might have changed. (Edit/Calculated)
      this.rowData=[...this.rowData];
      this.gridApi.redrawRows();
      this._isLoading = false;
      this.gridApi.hideOverlay();
    }
    else{
      this.router.navigateByUrl("/error");
    }
  }

  editPlanDetailErrorHandler(action:any){
    // handle this later
    //this.router.navigateByUrl("/error");
    this._isLoading = false;
    this.messageService.add({severity:"error",detail:this.translate.instant("plan.detail.edit.failure")})
    this.gridApi.hideOverlay();
  }

  savePlanDetailCompleteHandler = (action: any) => {
    this._isLoading = false;
    this.gridApi.hideOverlay();

    let errorStatus = action.payload.errorStatus;
    if (errorStatus === null) {
      this._dataIsSaved = true;
      let successMsg = this.translate.instant("plan.save.success");
      this.messageService.add({severity: "success", detail: successMsg});
    } else if (errorStatus === 409) { // plan modified
      let title = this.translate.instant("plan.save.error.title.dlg");
      let msg = this.translate.instant("plan.save.error.modified.msg");
      this.showMessage(title, msg, "error");
    } else if (errorStatus === 410) { // plan deleted
      let title = this.translate.instant("plan.save.error.title.dlg");
      let msg = this.translate.instant("plan.save.error.deleted.msg");
      this.showMessage(title, msg, "error");
    } else {
      let failureMsg = this.translate.instant("plan.save.failure");
      this.messageService.add({severity: "error", detail: failureMsg});
    }
  };

  convertPlanFailedHandler = (e: any) => {
    if (e.payload.status === 500) {
      const done = () => { };
      const dialogOpt: DialogOptions = new DialogOptions();
      dialogOpt.titleKey = "generic.err.title";
      dialogOpt.messageKey = "unrecoverable.err.msg";
      dialogOpt.msgType = "error";
      dialogOpt.confirmLabel = "btn.close";
      dialogOpt.showCancel = false;
      modalComponent.showModalMessage(dialogOpt, this.modalService).then(done, done);
      // this.messageService.add({severity: "error", detail: this.translate.instant("plan.convert.failure.general")});
    }
    else if (e.payload.status === 401) {
      // logout
      const logout = () => {
        if (this.router.navigate(["/login"]))
          this.authService.logout();
      };
      const dgOption: DialogOptions = new DialogOptions();
      dgOption.titleKey = "generic.err.title";
      dgOption.messageKey = "generic.err.msg";
      dgOption.msgType = "error";
      dgOption.confirmLabel = "btn.signin";
      dgOption.showCancel = false;
      modalComponent.showModalMessage(dgOption, this.modalService).then(logout, logout);
    }
  };

  saveAsSuccessHandler = (action: any) => {
    // set data is saved (as) so user can navigate back to managed plans page
    this._dataIsSaved = true;
    this.router.navigate(['/plans'], {queryParams: {sortBy: ColumnId.modifiedDate, sortOrder: "desc"}});
  };

  saveAsFailedHandler = async (action: any) => {
    // Toast not displayed when Save As results in a duplicate name failure and unrecoverable error
    if (![409, 500, 401].includes(action.payload.status)) {
      this.messageService.add({ severity: "error", detail: this.translate.instant("plan.save.as.failure") });
    }
  };

  protected getColumnsFromResponse(response) {

    let colDefs = [];
    if (this.translateSrv.currentLang) {
      let localeMatch = environment.locales.find(language => language.locale === this.translateSrv.currentLang);
      if (localeMatch) {
        this.dateShortFormat = localeMatch.shortDateFormat;
      }
    }
    if (response.interval === DetailViewMode.Daily) {

      // Parameters column
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.param"],
        headerGroupComponentFramework: null,
        children: [{
          pinned: "left",
          headerComponentFramework: null,
          headerName: "",
          suppressSizeToFit: true,
          width: this.pinnedLeftColumnWidth,
          field: "plan_label_param",
          //colId: "plan_label_param",
          tooltipField: "plan_label_param",
          lockPosition: true,
        }],
        headerClass: "",
        headerTooltip: this.translationStr["plan.label.param"],
        width: null
      });
      // Week/Day columns
      let weeks = response.headers;
      for (var weekKey in weeks) {
        let itemClass = "first-item";
        if (weeks.hasOwnProperty(weekKey)) {
          let children = [];
          let days = weeks[weekKey].children;
          for (let dayKey in days) {
            if (days.hasOwnProperty(dayKey)) {
              children.push({
                field: dayKey,
                //colId:dayKey,
                startDate: days[dayKey].date,
                endDate: days[dayKey].date,
                headerComponentParams: {updateViewMode: this.updateViewMode.bind(this)},
                headerComponentFramework: null,
                headerName: this.datePipe.transform(new Date(days[dayKey].date + "T12:00:00Z"), this.dateShortFormat + "/yy"),
                headerNameFull: this.datePipe.transform(new Date(days[dayKey].date + "T12:00:00Z"), this.dateShortFormat + "/yy"),
                headerTooltip:this.datePipe.transform(new Date(days[dayKey].date + "T12:00:00Z"), this.dateShortFormat + "/yy"),
                suppressSizeToFit: true,
                width: 110,
                headerClass: "no-bold no-icon no-black " + itemClass,
                cellEditorSelector: this.cellEditor,
                editable: this.cellEditable,
                valueFormatter: this.cellValueFormatter,
                cellEditorParams: this.cellEditorParam,
                onCellValueChanged: this.cellValueChanged,
                validator: ValidatorFactory.getValidator,
                valueSetter: this.cellValueSetter.bind(this),
                cellClass: this.cellClass,
                lockPosition:true,
              });
            }
            itemClass = "";
          }
          colDefs.push({
            headerName: this.datePipe.transform(new Date(weeks[weekKey].startDate + "T12:00:00Z"), this.dateShortFormat) +
                  "-" + this.datePipe.transform(new Date(weeks[weekKey].endDate + "T12:00:00Z"), this.dateShortFormat),
            headerNameFull: this.datePipe.transform(new Date(weeks[weekKey].startDate + "T12:00:00Z"), this.dateShortFormat+ "/yy") +
                  "-" + this.datePipe.transform(new Date(weeks[weekKey].endDate + "T12:00:00Z"), this.dateShortFormat+ "/yy"),
            headerGroupComponentFramework: PlanDetailHeaderRowComponent,
            children: children,
            headerClass: "",
            width: null,
            lockPosition:true,
          });
        }
        itemClass = "";
      }

      // Total column
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.total"],
        headerGroupComponentFramework: null,
        children: [{
          pinned: "right", headerComponentFramework: null,
          headerName: "",
          suppressSizeToFit: true,
          width: this.pinnedRightTotalColumnWidth,
          field: "plan_label_total",
          //colId: "plan_label_total",
          tooltip: this.cellValueFormatter,
          valueFormatter: this.cellValueFormatter,
          cellClass: this.cellClass,
          suppressMovable: true
        }],
        headerClass: "black text-right",
        headerTooltip: this.translationStr["plan.label.total"],
        width: null
      });

      // Average column
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.average"],
        headerGroupComponentFramework: null,
        children: [{
          pinned: "right", headerComponentFramework: null, suppressSizeToFit: true,
          width: this.pinnedRightColumnWidth,
          field: "plan_label_average",
          //colId: "plan_label_average",
          tooltip: this.cellValueFormatter,
          valueFormatter: this.cellValueFormatter,
          headerName: "",
          cellClass: this.cellClass,
          suppressMovable: true
        }],
        headerClass: "black text-right",
        headerTooltip: this.translationStr["plan.label.average"],
        width: null
      });
    }

    if (response.interval === DetailViewMode.Weekly) {

      // Parameters column
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.param"],
        headerGroupComponentFramework: null,
        children: [{
          pinned: "left",
          headerComponentFramework: null,
          headerName: "",
          suppressSizeToFit: true,
          width: this.pinnedLeftColumnWidth,
          field: "plan_label_param",
          //colId: "plan_label_param",
          tooltipField: "plan_label_param",
          lockPosition: true
        }],
        headerClass: "",
        headerTooltip: this.translationStr["plan.label.param"],
        width: null,
      });

      // Month/Week columns
      let months = response.headers;
      for (var monthKey in months) {
        let itemClass = "first-item";
        if (months.hasOwnProperty(monthKey)) {
          let children = [];
          let weeks = months[monthKey].children;
          for (var weekKey in weeks) {
            if (weeks.hasOwnProperty(weekKey)) {
              if (weeks[weekKey].partialFlag) {
                itemClass = itemClass + " partial-field";
              }
              children.push({
                field: weekKey,
                //colId: weekKey,
                startDate: weeks[weekKey].startDate,
                endDate: weeks[weekKey].endDate,
                headerComponentParams: {updateViewMode: this.updateViewMode.bind(this)},
                headerComponentFramework: this.selectedContactType.type == MUCTKey.MU?null: PlanDetailClickableHeaderComponent,
                headerName: this.datePipe.transform(new Date(weeks[weekKey].startDate + "T12:00:00Z"), this.dateShortFormat) +
                  "-" + this.datePipe.transform(new Date(weeks[weekKey].endDate + "T12:00:00Z"), this.dateShortFormat),
                headerNameFull: this.datePipe.transform(new Date(weeks[weekKey].startDate + "T12:00:00Z"), this.dateShortFormat+ "/yy") +
                  "-" + this.datePipe.transform(new Date(weeks[weekKey].endDate + "T12:00:00Z"), this.dateShortFormat+ "/yy"),
                suppressSizeToFit: true,
                width: 110,
                headerClass: "no-bold no-icon no-black " + itemClass,
                cellEditorSelector: this.cellEditor,
                editable: this.cellEditable,
                valueFormatter: this.cellValueFormatter,
                cellEditorParams: this.cellEditorParam,
                onCellValueChanged: this.cellValueChanged,
                validator: ValidatorFactory.getValidator,
                valueSetter: this.cellValueSetter.bind(this),
                cellClass: this.cellClass,
                lockPosition:true,
              });
            }
            itemClass = "";
          }
          colDefs.push({
            headerName: format(new Date(months[monthKey].startDate + "T12:00:00Z"), "MMM yyyy", {locale: dateLocale}),
            headerNameFull: format(new Date(months[monthKey].startDate + "T12:00:00Z"), "MMM yyyy", {locale: dateLocale}),
            headerGroupComponentFramework: PlanDetailHeaderRowComponent,
            children: children,
            headerClass: "",
            width: null,
            lockPosition:true,
          });
        }
        itemClass = "";
      }

      // Total column
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.total"],
        headerGroupComponentFramework: null,
        children: [{
          pinned: "right", headerComponentFramework: null,
          headerName: "",
          suppressSizeToFit: true,
          width: this.pinnedRightTotalColumnWidth,
          field: "plan_label_total",
          //colId: "plan_label_total",
          tooltip: this.cellValueFormatter,
          valueFormatter: this.cellValueFormatter,
          cellClass: this.cellClass,
          suppressMovable: true
        }],
        headerClass: "black text-right",
        headerTooltip: this.translationStr["plan.label.total"],
        width: this.pinnedRightTotalColumnWidth
      });

      // Average column
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.average"],
        headerGroupComponentFramework: null,
        children: [{
          pinned: "right", headerComponentFramework: null, suppressSizeToFit: true,
          width: this.pinnedRightColumnWidth,
          field: "plan_label_average",
          //colId: "plan_label_average",
          tooltip: this.cellValueFormatter,
          valueFormatter: this.cellValueFormatter,
          headerName: "",
          cellClass: this.cellClass,
          suppressMovable: true
        }],
        headerClass: "black text-right",
        headerTooltip: this.translationStr["plan.label.average"],
        width: this.pinnedRightColumnWidth
      });
    }

    if (response.interval === DetailViewMode.Monthly) {
      // Parameters column
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.param"],
        pinned: "left",
        field: "plan_label_param",
        //colId: "plan_label_param",
        tooltipField: "plan_label_param",
        width: this.pinnedLeftColumnWidth,
        suppressMovable: true,
        headerTooltip: this.translationStr["plan.label.param"]
      });
      let columns = response.headers;
      for (var columnKey in columns) {
        if (columns.hasOwnProperty(columnKey)) {

          let startDateStr = columns[columnKey].startDate;
          var startDate = new Date(startDateStr + "T12:00:00Z");
          let lastDayOfMon = this.lastday(startDate.getFullYear(), startDate.getMonth());
          let endDateStr = format(lastDayOfMon, "yyyy-MM-dd", {locale: dateLocale});
          let colDef: any = {
            field: columnKey,
            //colId: columnKey,
            startDate: startDateStr,
            endDate: endDateStr,
            headerComponentParams: {updateViewMode: this.updateViewMode.bind(this)},
            headerComponentFramework: PlanDetailClickableHeaderComponent,
            headerName: format(startDate, "MMM yyyy", {locale: dateLocale}),
            headerNameFull: format(startDate, "MMM yyyy", {locale: dateLocale}),
            suppressSizeToFit: true,
            width: 110,
            lockPosition:true
          };
          colDef.cellEditorSelector = this.cellEditor;
          colDef.editable = this.cellEditable.bind(this);
          colDef.valueFormatter = this.cellValueFormatter;
          colDef.cellEditorParams = this.cellEditorParam;
          colDef.onCellValueChanged = this.cellValueChanged;
          colDef.validator = ValidatorFactory.getValidator;
          colDef.valueSetter = this.cellValueSetter.bind(this);
          colDef.cellClass = this.cellClass;
          if (columns[columnKey].partialFlag) {
            colDef.headerClass = "partial-field"
          }
          colDefs.push(colDef);
        }
      }
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.total"],
        pinned: "right",
        field: "plan_label_total",
        //colId: "plan_label_total",
        tooltip: this.cellValueFormatter,
        headerClass: "black text-right",
        width: this.pinnedRightTotalColumnWidth,
        valueFormatter: this.cellValueFormatter,
        cellClass: this.cellClass,
        suppressMovable: true,
        headerTooltip: this.translationStr["plan.label.total"]
      });
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.average"],
        pinned: "right",
        field: "plan_label_average",
        //colId: "plan_label_average",
        tooltip: this.cellValueFormatter,
        valueFormatter: this.cellValueFormatter,
        headerClass: "black text-right",
        width: this.pinnedRightColumnWidth,
        cellClass: this.cellClass,
        suppressMovable: true,
        headerTooltip: this.translationStr["plan.label.average"]
      });
    }

    return colDefs;

  }

  public lastday = (y, m) => {
    return new Date(y, m + 1, 0);
  };

  public floor(num: any, dec: any) {
    if (num && num.toString().includes(".")) {
      var array = num.toString().split(".");
      var decPart = array.pop();
      if (!decPart) {
        decPart = "0";
      }
      array.push(decPart.substring(0, dec));
      var result = array.join(".");
      return result;
    } else {
      return num;
    }
  }

  public cellClass = (params: any) => {
    const baseClass = this.cellEditable(params) ? "cell-editable " : "";
    let paramName: PlanParam = null;
    let cellMetadata = null;
    let cssClass = "", cellValue;
    if (params.data && params.data.metadata) {
      paramName = <PlanParam>PlanParam[params.data.metadata.paramName];

      if(!paramName && params.data.metadata.parent){
        paramName = <PlanParam>PlanParam[params.data.metadata.parent + "_SUB"];
      }
      cellMetadata = params.data.metadata.cellmetadata[params.colDef.field];
      if (cellMetadata && cellMetadata.paramStatus === PlanCellType.CLOSED) {
		cssClass = baseClass + "closed-field";
        return cssClass;
      }
      else if (cellMetadata && cellMetadata.paramStatus === PlanCellType.CALCULATED) {
        cssClass = baseClass + "calculated-field";
      }
    }
    // We are going to use original value to get proper class for cell (cases like -0.000005 class should be negative)
    cellValue = (cellMetadata && cellMetadata.origValue) ? cellMetadata.origValue : params.value;
    let isNegative = NumberUtils.isNegativeNumber(cellValue);

    if(params.colDef.field == "plan_label_average" || params.colDef.field == "plan_label_total" ){
      return isNegative ? "negative-value" : "calculated-field";
    }
    if (this.entityType != EntityType.CT
            && this.selectedContactType.value === PlanDetailComponentV2.ALL_CONTACT_TYPES
            && paramName !== PlanParam.CONTACTS
            && paramName !== PlanParam.AHT) {
      let retCssClass = isNegative ? "negative-value" : "calculated-field";
      return this.isPartial(params) ? retCssClass + " partial-field" : retCssClass;
    }
    switch (paramName) {
      case PlanParam.BASE_FTE:
      case PlanParam.FTE_REQUIRED:
        cssClass = "calculated-field";
        break;
      case PlanParam.FTE_REQ_DIFF:
      case PlanParam.FTE_COST_DIFF:
      case PlanParam.VARIANCE_CONTACTS:
      case PlanParam.VARIANCE_AHT:
        cssClass = isNegative ? "negative-value" : "calculated-field";
        break;
      case PlanParam.ABS_ATTRITION:
      case PlanParam.NEW_HIRE_FTE:
        if (this.gridContext.viewMode === DetailViewMode.Monthly) {
          cssClass = baseClass + "calculated-field";
        }
        break;
      // default:
      //   cssClass = baseClass;
    }
    if (this.isPartial(params)) {
      cssClass = cssClass + " partial-field";
    }
    return cssClass;
  };

  public isPartial(params) {
    var field = params.colDef.field;
    let cellMeta = params.data.metadata !== undefined ? params.data.metadata.cellmetadata[field] : null;
    return cellMeta && cellMeta.partialFlag;
  }

  public hasVariance(params) {
    var field = params.colDef.field;
    let cellMeta = params.data.metadata.cellmetadata[field];
    return cellMeta && cellMeta.varianceFlag;
  }

  public setVarianceOff(params) {
    if (this.hasVariance(params)) {
      var field = params.colDef.field;
      let cellMeta = params.data.metadata.cellmetadata[field];
      cellMeta.varianceFlag = false;
    }
  }

  cellValueSetter(params) {
    var decimal = params.data.metadata.decimal;
    var newVal = params.newValue ? this.floor(params.newValue, decimal) : params.newValue;
    var oldVal = params.oldValue ? this.floor(params.oldValue, decimal) : params.oldValue;

    var data = params.data;
    var result = false;

    if (newVal !== oldVal) {
      if (this.hasVariance(params)) {
        data[params.colDef.field] = newVal;
        this.confirmAndUpdate(params, oldVal);
      } else {
        data[params.colDef.field] = newVal;
        result = true;
      }

    } else {
      result = false;
    }
    return result;

  }

  confirmAndUpdate(params, oldVal) {
    let planParam: PlanParam = <PlanParam>PlanParam[params.data.metadata.paramName];
    //Parent parameter will set the warning message
    if(params.data.metadata.parent){
      planParam = <PlanParam>PlanParam[params.data.metadata.parent];
    }
    let confMsg = ConfirmationMsgFactory.getMsg({
      paramName: planParam,
      viewMode: this.gridContext.viewMode
    });

    const dgOption: DialogOptions = new DialogOptions();
    dgOption.titleKey = confMsg.title;
    dgOption.messageKey = confMsg.msg;
    dgOption.msgType = "warn";
    const dialog = modalComponent.showModalMessage(dgOption, this.modalService);
    dialog.then((yes) => {
      this.setVarianceOff(params);
      var refreshParam = {
        rowNodes: [params.node],
        columns: [params.column]
      };
      this.gridApi.refreshCells(refreshParam);
      this.cellValueChanged(params);

    }, (no) => {
      params.data[params.colDef.field] = oldVal;
      var refreshParam = {
        rowNodes: [params.node],
        columns: [params.column]
      };
      this.gridApi.refreshCells(refreshParam);
      this.rowData=[...this.rowData];//We must refresh the grid because the chart needs to revert as well

    });
  }

  public cellValueFormatter = (params: any) => {
    if (params && params.data && params.data.metadata) {
      let options = {decimal: params.data.metadata.decimal};
      return NumberUtils.formatNumberIfNecessary(params.value, this.locale, options.decimal);
    }
  };

  public cellValueChanged = (params: any) => {
    var field = params.colDef.field;
    var decimal = params.data.metadata.decimal;
    const cellMetadata = params.data.metadata.cellmetadata[field];
    const origValue = cellMetadata?cellMetadata.origValue: null; // raw value to preserve precision
    const newVal = params.newValue !== ""  ? Number(params.newValue).toFixed(decimal) : params.newValue;
    const oldVal = params.oldValue ? Number(params.oldValue).toFixed(decimal) : params.oldValue;
    var validator = params.data.metadata.validator;
    if (oldVal !== newVal && validator.isValid(params.newValue,cellMetadata)) {
      this._isLoading = true;
      this._dataIsSaved = false;
      this.gridApi.showLoadingOverlay();
      let payload: EditPlanDetailRequestDTO = {
        planId: this.planId,
        startDate: params.colDef.startDate,
        endDate: params.colDef.endDate,
        interval: this.gridContext.viewMode,
        planParameterLabel: params.data.metadata.paramName,
        editedValue: newVal,
        previousValue: origValue,
        ctOid: this.selectedContactType.type == MUCTKey.CT? this.getSelectedCtOid():null,
        muOid: this.selectedContactType.type == MUCTKey.MU? this.getSelectedCtOid():null
      };
      this.store.dispatch(new EditPlanDetail(payload));
    }

  };

  public saveButtonClicked = () => {
    this.savePlan();
  };

  private savePlan(){
    this._isLoading = true;
    this.gridApi.showLoadingOverlay();
    this.store.dispatch(new SavePlanDetail(this.planId));
  }


  public cellEditable = (params: any) => {
    let editable = false;
    let paramName: PlanParam = null;

    if(params.colDef) { //charts doesn't have col definition
      var field = params.colDef.field;
      let cellMetadata = null;
      if (params.data && params.data.metadata && params.data.metadata.cellmetadata) {
        cellMetadata = params.data.metadata.cellmetadata[field];
      }
      if (!params.data || !params.data.metadata || !params.data.metadata.paramName
        || (cellMetadata && cellMetadata.paramStatus === PlanCellType.CLOSED)) {
        return false;
      }
    }
    return params.data.metadata.permission===ParamPermission.Modify;
  };

  public cellEditor = (params: any) => {
    let paramName: PlanParam = <PlanParam>PlanParam[params.data.metadata.paramName];

    if(!paramName && params.data.metadata.parent){
      paramName = <PlanParam>PlanParam[params.data.metadata.parent + "_SUB"];
    }

    var editor = {component: null, param: params};
    switch (paramName) {
      case PlanParam.ACTUAL_FTE:
      case PlanParam.SHRINKAGE:
      case PlanParam.SHRINKAGE_SUB:
      case PlanParam.ASA:
      case PlanParam.MS_EFFICIENCY:
      case PlanParam.CONTACTS:
      case PlanParam.HOURLY_RATE:
      case PlanParam.OCCUPANCY:
      case PlanParam.NEW_HIRE_FTE:
      case PlanParam.AHT:
      case PlanParam.BACKLOG:
      case PlanParam.SERVICE_LEVEL_PERCENT:
      case PlanParam.SERVICE_LEVEL_TIME:
      case PlanParam.ABS_ATTRITION:
      case PlanParam.MEAN_CONCURRENCY:
        editor.component = "numericEditor";
        break;

      default:
        break;
    }
    return editor;
  };

  public cellEditorParam = (params: any) => {
    let paramName: PlanParam = <PlanParam>PlanParam[params.data.metadata.paramName];
    const param = {
      origValue: params.data[params.colDef.field],
      metadata: params.data.metadata,
      incremental: true,
      locale: this.locale
    };
    //gets added to the editor parameter
    return param;
  };

  private getCTFromResponse(response: PlanDetailResponse) {
    const getSelectItems = (obj, type) => {
      let item = {
        label: "", type, styleClass: "", disabled: false,
        value: obj.oid,
        object: obj
      };
      if (type == MUCTKey.CT) {
        item.label = this.translate.instant("label.ct.short") + " - " + obj.id + " " + obj.name;
        item.styleClass = "wfm-bg-white";
        item.disabled = this._isMUDirty;
      } else if (type == MUCTKey.MU) {
        item.label = this.translate.instant("label.mu.short") + " - " + obj.id + " " + obj.name;
        item.styleClass = "wfm-bg-gray";
        item.disabled = this._isFTERequiredDirty;
      }
      return item;
    }
    let result = [];
    const muItems: Array<any> = response.muInfo.map((mu) => getSelectItems(mu, MUCTKey.MU));
    muItems[0].styleClass += "-separator";
    const ctItems: Array<any> = response.ctInfo.map((ct) => getSelectItems(ct, MUCTKey.CT));
    ctItems[0].styleClass += "-separator";

    result = result.concat(muItems, ctItems);
    let firstItem = null;
    if(this.multiStepWorkflow){
      let initialOid = this.getInitialCtOid();
      let initialCt = result.find((item)=>item.object.oid==initialOid);
      let initialCtIndex = result.indexOf(initialCt);
      result.splice(initialCtIndex,1);
      firstItem = initialCt;
    }else{
      firstItem = {
        label: this.translate.instant("plan.details.cts.all"),
        value: PlanDetailComponentV2.ALL_CONTACT_TYPES,
        object: null,
        disabled: this._isMUDirty
      };
    }
    if (response.entityType != EntityType.CT || this.multiStepWorkflow) {
      result.unshift(firstItem);
    } else {
      firstItem = ctItems[0];
    }
    if (!this.selectedContactType)
      this.selectedContactType = firstItem;
    return result;
  }

  protected getRowsFromResponse(response) {

    let rowGroup = 1;
    let rows = [];
    if (this.selectedContactType && this.selectedContactType.type == MUCTKey.MU) {
      this.localParams = this.removeNonMUParams(PlanParam);
    }
    else {
      if (this.gridContext.viewMode === DetailViewMode.Daily) {
        this.localParams = this.removeNonDailyParams(PlanParam);
      } else if (this.gridContext.viewMode === DetailViewMode.Weekly) {
        this.localParams = this.removeNonWeeklyParams(PlanParam)
      } else if (this.gridContext.viewMode === DetailViewMode.Monthly) {
        this.localParams = this.removeNonMonthlyParams(PlanParam)
      }
    }
    switch(this.planContactTypes){
      case PlanContactTypes.WORKLOAD:
        this.localParams = this.removeNonWorkloadParams(this.localParams);
        break;
      case PlanContactTypes.NON_WORKLOAD:
        this.localParams = this.removeWorkloadParams(this.localParams);
        break;
      default:
        break;
    }

    // historical data parameters only available if feature toggle is enable.
    if (!this._historicalDataInPlanFeature) {
      this.localParams = PlanDetailCommonHelper.removeHistoricalDataParams(this.localParams);
    }

    Object.keys(this.localParams).map(paramKey => {
      let remoteParam = response.params.find(obj => obj.paramName == paramKey);
      let row = this.getRow(remoteParam,paramKey,rowGroup);
      rows.push(row);

      rowGroup = Math.abs(rowGroup - 1);

      let remoteParamChildren = response.params.filter(obj => obj.parent == paramKey);
      if (remoteParamChildren.length > 0) {
        remoteParamChildren.forEach((remoteChild)=>{
          let childRow = this.getRow(remoteChild,remoteChild.paramName,rowGroup);
          rows.push(childRow);
        });
        rowGroup = Math.abs(rowGroup - 1);
      }

    });

    return rows;
  }
  protected getRow(remoteParam,paramKey,rowGroup){
    let rowTemp = {};

    //if it's a static parameter, we have a translation for it
    if(PlanParam[paramKey]){
      // historical data parameters if feature toggle esp-histdatainplan-esp2740 is enable.
      // plan.detail.aht.forecast it will be plan.detail.aht.forecast
      // and plan.detail.contacts.recv will be plan.detail.contacts.recv.forecast
      if (this._historicalDataInPlanFeature &&
        (PlanParam[paramKey] === PlanParam.CONTACTS || PlanParam[paramKey] === PlanParam.AHT)) {
        rowTemp["plan_label_param"] = this.translate.instant(`${PlanParam[paramKey]}.forecast`);
      } else {
        rowTemp["plan_label_param"]=this.translate.instant(PlanParam[paramKey]);
      }
    }

    if (remoteParam && remoteParam.data) {

      let paramName: PlanParam = <PlanParam>PlanParam[paramKey];

      if(!paramName && remoteParam.parent){
        paramName = <PlanParam>PlanParam[remoteParam.parent + "_SUB"];
      }

      let validator = ValidatorFactory.getValidator(paramName, remoteParam, this.translate, this.locale);
      const rowMetaData: any = {decimal: remoteParam.decimal, paramName: paramKey, permission: remoteParam.permission, validator: validator, cellmetadata: new Map<String, any>(),};

      const precision = Math.pow(10, remoteParam.decimal);


      if (remoteParam.metadata) {
        for (let [key, value] of Object.entries(remoteParam.metadata)) {
          rowMetaData.cellmetadata[key]=Object.assign({},value);
        }
      }
      const options = this.getOptionsFromRow(remoteParam);
      for (const cellKey in remoteParam.data) {
        if (remoteParam.data.hasOwnProperty(cellKey)) {
          const val = remoteParam.data[cellKey];
          if (rowMetaData.cellmetadata[cellKey]) {
            rowMetaData.cellmetadata[cellKey].origValue = val;
          } else {
            rowMetaData.cellmetadata[cellKey]= {origValue:val};
          }// raw value to preserve precision
          rowMetaData.cellmetadata[cellKey].workload = this.planContactTypes === PlanContactTypes.WORKLOAD;
          //rowTemp[cellKey] = Number(val)!==NaN ? Math.round(Number(val) * precision) / precision : val;//this.formatNumberIfNecessary(remoteParam.data[cellKey],paramKey,options);
          rowTemp[cellKey] = Math.abs(Number.parseFloat(val)) >= 0 ? Math.round(Number(val) * precision) / precision : val;

        }
      }
      rowTemp["metadata"] = rowMetaData;
    }

    rowTemp["plan_label_total"] = remoteParam && remoteParam.total ? remoteParam.total : "";
    rowTemp["plan_label_average"] = remoteParam && remoteParam.average ? remoteParam.average : "";
    rowTemp["rowGroup"] = rowGroup;

    //If we're a child row
    if(remoteParam && remoteParam.parent){
      rowTemp["plan_label_param"] = remoteParam.label;
      rowTemp["rowChild"] = true;
      rowTemp["metadata"].parent = remoteParam.parent;
    }

    return rowTemp;

  }
  protected getOptionsFromRow(row) {
    var options = {
      decimal: null
    };
    if ("decimal" in row) {
      options.decimal = row.decimal;
    }
    return options;
  }

  protected removeNonMUParams(PlanParam){
    let params = {};
    Object.keys(PlanParam).map(rowLabel => {
      params[rowLabel] = PlanParam[rowLabel];
    });
    const muParams: Array<any> = [PlanParam.ACTUAL_FTE, PlanParam.ATTRITION, PlanParam.ABS_ATTRITION, PlanParam.NEW_HIRE_FTE];
    delete params["CONTACTS"];
    delete params["AHT"];
    // If feature toggle is enable. Historical data parameters are removed.
    if (this._historicalDataInPlanFeature) {
      params = PlanDetailCommonHelper.removeHistoricalDataParams(params);
    }

    delete params["MEAN_CONCURRENCY"];
    delete params["SERVICE_LEVEL_PERCENT"];
    delete params["SERVICE_LEVEL_TIME"];
    delete params["ASA"];
    delete params["OCCUPANCY"];
    delete params["BASE_FTE"];
    delete params["MS_EFFICIENCY"];
    delete params["SHRINKAGE"];
    delete params["SHRINKAGE_SUB"];
    delete params["FTE_REQUIRED"];

    delete params["PROJECTED_SERVICE_LEVEL_PERCENT"];
    delete params["PROJECTED_ASA"];
    delete params["PROJECTED_OCCUPANCY"];

    delete params["FTE_REQ_DIFF"];
    delete params["HOURLY_RATE"];
    delete params["FTE_REQ_COST"];
    delete params["FTE_ACT_COST"];
    delete params["FTE_COST_DIFF"];

    delete params["CONTACTS_HANDLED"];
    delete params["ACTUAL_FTE_CAPACITY"];
    delete params["BACKLOG"];
    return params;
  }

  protected removeNonDailyParams(PlanParam) {
    var params = {};
    Object.keys(PlanParam).map(rowLabel => {
      params[rowLabel] = PlanParam[rowLabel];
    });
    delete params["ACTUAL_FTE"];
    delete params["ATTRITION"];
    delete params["ABS_ATTRITION"];
    delete params["NEW_HIRE_FTE"];
    delete params["FTE_REQ_DIFF"];
    delete params["FTE_ACT_COST"];
    delete params["FTE_COST_DIFF"];
    delete params["PROJECTED_SERVICE_LEVEL_PERCENT"];
    delete params["PROJECTED_ASA"];
    delete params["PROJECTED_OCCUPANCY"];
    delete params["SHRINKAGE_SUB"];
    delete params["ACTUAL_FTE_CAPACITY"];
    delete params["BACKLOG"];
    return params;
  }

  protected removeNonWeeklyParams(PlanParam) {
    var params = {};
    Object.keys(PlanParam).map(rowLabel => {
      params[rowLabel] = PlanParam[rowLabel];
    });
    delete params["SHRINKAGE_SUB"];
    return params;
  }

  protected removeNonMonthlyParams(PlanParam) {
    var params = {};
    Object.keys(PlanParam).map(rowLabel => {
      params[rowLabel] = PlanParam[rowLabel];
    });
    delete params["SHRINKAGE_SUB"];
    return params;
  }
  protected removeWorkloadParams(params){
    delete params["CONTACTS_HANDLED"];
    delete params["ACTUAL_FTE_CAPACITY"];
    delete params["BACKLOG"];
    return params;
  }
  protected removeNonWorkloadParams(params){
    delete params["ASA"];
    delete params["PROJECTED_ASA"];
    return params;
  }

  public updateContactType(contactType){
    const currentType = this.selectedContactType.type;
    const selectedType = contactType.type;
    // go to weekly MU view when switching from Daily CT to MU
    const isCt2Mu = (currentType == MUCTKey.CT || !currentType) && selectedType == MUCTKey.MU;
    if(isCt2Mu && this.gridContext.viewMode == DetailViewMode.Daily){
      this.gridContext.viewMode = DetailViewMode.Weekly;
    }
    this.selectedContactType = contactType;
    this.getPlanDetail(this.gridContext.viewMode);
  }

  protected  getSelectedCtOid(){
    if(this.selectedContactType && this.selectedContactType.value!=PlanDetailComponentV2.ALL_CONTACT_TYPES){
      return this.selectedContactType.value
    }
    return null;
  }

  protected addToSubscriptionList(newSubscription: Subscription) {
    this.subscriptionList.push(newSubscription);
  }

  private clearSubscriptionList() {
    if (this.subscriptionList.length > 0) {
      this.subscriptionList.forEach(subscriptionItem => subscriptionItem.unsubscribe());
      this.subscriptionList = null;
    }
  }

  toggleChartButtonClicked(numOfCharts) {
    this.isChartView=numOfCharts>0;
    if(this.isChartView===true){
      this.chartsCount=numOfCharts
    }

  }

  public setCustomValue(rowName,field, value){
    var rowNode = this.gridApi.getRowNode(rowName);
    let columns = this.columnApi.getAllColumns();
    //chart is sending the field name, but ag grid generates a unique column id.
    //so we must find the right column id
    let column = columns.find((col)=>col.getColDef().field==field);
    rowNode.setDataValue(column.getColId(), value);
  }
  private shouldSave():boolean{
    return !this.authService.isTokenExpired() && !this._dataIsSaved && this._userHasModify;
  }

  async canDeactivate() {
    if(!this.shouldSave()){
      return true;
    }

    const dgOption: DialogOptions = new DialogOptions();
    dgOption.titleKey = "plan.exit.unsaved.dialog.title";
    dgOption.messageKey = "plan.exit.unsaved.dialog.msg";
    dgOption.msgType = "warn";
    dgOption.showCancel = true;
    dgOption.confirmLabel = "btn.confirm.label";
    try{
      await modalComponent.showModalMessage(dgOption, this.modalService);
      return true;
    }catch(e){
      return false;
    }
  }
  @HostListener("window:beforeunload", ["$event"])
  canCloseTab($event: any) {
    if(this.shouldSave()){
      $event.returnValue=true;
    }
  }
  @HostListener("window:unload", ["$event"])
  tabClosed($event: any){
    PlanHelper.removePlanDetailSessionId(this.planId);
  }

  private updateCurrentRoute() {

    if(this.route.snapshot.firstChild){
      this._isDistributionRoute=true;
    }else{
      if(this._isDistributionRoute==true){ //if we came from distribution route, we should refresh data
        this.getPlanDetail(this.gridContext.viewMode);
      }
      this._isDistributionRoute=false;
    }
  }

  private navigateToDistribution(){
    this.router.navigate(["distribution"],{relativeTo: this.route, state: {saved: this._dataIsSaved}});
  }

  private canDrillDown() {
    if(this.gridContext.viewMode==DetailViewMode.Daily){
      return false;
    }else if(this.selectedContactType && this.selectedContactType.type == MUCTKey.MU && this.gridContext.viewMode==DetailViewMode.Weekly){
      return false;
    }else{
      return true;
    }
  }
  private taskHandler(taskAction:any) {
    if (taskAction.type==AppActionTypes.TaskCompleteAction) {
      this.task = null;
    } else if(taskAction.type==AppActionTypes.TaskCancelAction) {
      this.task = null;
    }else if(taskAction.type==AppActionTypes.TaskErrorAction){
      this.task = null;
    } else { //progress
      this.task = taskAction.payload;
      if(this.task.metadata && this.task.metadata.planName){
        this.planName=this.task.metadata.planName
      }
    }
  }

  private applyStaffingDataSuccessHandler() {
    this._dataIsSaved=false;
  }

  private applyMuParamsSuccessHandler() {
    this._isLoading = false;
    this.gridApi.hideOverlay();
    this.setMUGridDirty(false);
    let successMsg = this.translate.instant("plan.detail.apply.mu.edit.success");
    this.messageService.add({severity: "success", detail: successMsg});
  }

  private applyMuParamsErrorHandler() {
    this._isLoading = false;
    this.gridApi.hideOverlay();
    let errorMsg = this.translate.instant("plan.detail.apply.mu.edit.failure");
    this.messageService.add({severity: "error", detail: errorMsg});
  }

  private savePlanSettingsSuccessHandler(){
    this._dataIsSaved = false;
    let successMsg = this.translate.instant("plan.settings.applied.successfully");
    this.messageService.add({severity: "success", detail: successMsg});
    this.getPlanDetail(this.gridContext.viewMode);
    this.setFTERequiredDirty(false);
  }
  private async savePlanSettingsErrorHandler(e){
    let payload = e.payload;
    let error = payload.error;
    let planSettings = {...payload.planSettings, erlangAFeature: this._erlangAFeature};

    if(error.EXCEPTION && error.EXCEPTION.Recoverable===false){
      let title = this.translate.instant("plan.detail.apply.settings.critical.title");
      let msg = this.translate.instant("plan.detail.apply.settings.critical.failure");
      try{
        await this.showMessage(title, msg, "error");
      }finally{
        this.router.navigateByUrl("/plans");
      }
    }else{
      let errorMsg = this.translate.instant("plan.detail.apply.settings.failure");
      this.messageService.add({severity: "error", detail: errorMsg});
      planSettings.erlangAFeature = this._erlangAFeature;
      this._showPlanSettingsDialog(planSettings, true);
    }
    console.log(e);
  }
  private applyFTERequiredSuccessHandler(){
    this.setFTERequiredDirty(false);
    this._dataIsSaved=false;
    let successMsg = this.translate.instant("plan.detail.apply.fte.required.success");
    this.messageService.add({severity: "success", detail: successMsg});
    this.getPlanDetail(this.gridContext.viewMode);
  }
  private applyFTERequiredErrorHandler(e){
    let errorMsg = this.translate.instant("plan.detail.edit.failure");
    this.messageService.add({severity: "error", detail: errorMsg});
  }
  private setFTERequiredDirty(dirty:boolean){
    this._isFTERequiredDirty=dirty;

    this.contactTypeList = this.contactTypeList.map((ctItem)=>{
      if(dirty && ctItem.type===MUCTKey.MU){
        ctItem.disabled=true;
      }else{
        ctItem.disabled=false;
      }
      return ctItem;
    });
  }

  onGridColumnsChanged($event: ModelUpdatedEvent) {
    this.scrollToTargetColumn();
  }

  private setScrollTarget(isCollapsed, column) {
    let field = column.colDef.field;
    let parts = field.split("_");

    this.scrollTargetColumn = parts[0];

    if (isCollapsed && this.gridContext.viewMode === DetailViewMode.Weekly) {
      this.scrollTargetColumn = this.scrollTargetColumn.substring(0, 6);
    }
    this.scrollTargetLeft = column.left - this.columnApi["columnModel"].scrollPosition;
  }

  private scrollToTargetColumn() {
    let targetColumn = null;
    let columns = [];

    //if we only switched CT/MU we won't have a target column
    if (!this.scrollTargetColumn) {
      this.isRenderingData = false;
      return;
    }

    //flat all columns
    this.colDefs.forEach((column) => {
      if (column.children) {
        columns.push(...column.children);
      } else {
        columns.push(column);
      }
    });


    targetColumn = columns.find((column) => column.field.startsWith(this.scrollTargetColumn));
    this.scrollTargetColumn = null;

    if (!targetColumn) {
      this.isRenderingData = false;
      return;
    }

    let gridApi: any = this.gridApi; //we're using some internal APIs so we need the :any type
    let column = this.columnApi.getColumn(targetColumn.field);
    let scrollLeft = column.getLeft() - this.scrollTargetLeft;
    if (scrollLeft < 0) {
      scrollLeft = 0;
    }

    gridApi.gridBodyCtrl.getScrollFeature().setHorizontalScrollPosition(scrollLeft); //will scroll the headers
    gridApi.ctrlsService.fakeHScrollComp.eViewport.scrollLeft = scrollLeft;  //will scroll the HTML scrollbar

    //adding some timeout to allow the scrollbar to relocate
    setTimeout(() => {
      this.isRenderingData = false;
    }, 100);
  }

  private getInitialCtOid() {
    let initialCT = this.multiStepWorkflow.cts.find((ct)=>ct.initial);
    if(initialCT){
      return initialCT.ctOid;
    }else{
      return null;
    }
  }
}

enum MUCTKey {
  MU = "MU", CT = "CT"
}

