import { SvgIconsModule } from "./svg-icons/svg-icons.module";
import { FullWidthTableDirective } from "./directives/full-width-table/full-width-table.directive";
import { CreatePlanComponent } from "./create-plan/create-plan.component";
import { RefreshPromptComponent } from "./refresh-prompt/refresh-prompt.component";
import { NgModule } from "@angular/core";
import { WfmTextboxComponent } from "./wfm-textbox/wfm-textbox.component";
import { WfmSelectComponent } from "./wfm-select/wfm-select.component";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { InputTextComponent } from "./input-text/input-text.component";
import { BreezeButtonComponent } from "./breeze-button/breeze-button.component";
import { IconNameDirective } from "./svg-icons/icon-name.directive";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";

import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { PageTitleComponent } from "./page-title/page-title.component";
import { WfmModalComponent } from "./wfm-modal/wfm-modal.component";
import { CheckboxListComponent } from "./checkbox-list/checkbox-list.component";
import { CreatePlanComponentV2 } from "./create-plan-v2/create-plan-v2.component";
import { MultiSelectModule } from 'primeng/multiselect'
import { WeekPickerComponent } from './week-picker/week-picker.component';
import { NgbDatepickerModule, NgbPopoverModule } from "@ng-bootstrap/ng-bootstrap";
import { TaskProgressComponent } from './task-progress/task-progress.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { EspTableHeaderComponent } from "./esp-table-header/esp-table-header.component";
import { EspNoRowsOverlayComponent } from "./no-rows-overlay/no-rows-overlay.component";
import { GridItemActionComponent } from "./grid-item-action/grid-item-action.component";
import { InputFocusDirective } from './directives/auto-focus/auto-focus.directive';
import { SideNavComponent } from "./side-nav/side-nav.component"
import { TooltipModule } from "primeng/tooltip";
import { DateTimePickersModule } from "cxone-components/date-time-pickers";

import { LicenseManager } from "@ag-grid-enterprise/core";
import { MultiSelectFilterComponent } from './multi-select-filter/multi-select-filter.component';
import { MultiSelectFooterComponent } from './multi-select-footer/multi-select-footer.component';
import { LoadingRowsOverlayComponent } from './loading-rows-overlay/loading-rows-overlay.component';
import { ValidationNavigatorComponent } from './validation-navigator/validation-navigator.component';
import { MuGroupHeaderComponent } from './mu-group-header/mu-group-header.component';
import { ApiCredentialComponent } from './api-credential/api-credential.component';
import { WfmIcontextboxComponent } from './wfm-icontextbox/wfm-icontextbox.component';

LicenseManager.setLicenseKey('CompanyName=NICE Ltd.,LicensedGroup=NICE Enterprise,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=20,LicensedProductionInstancesCount=10,AssetReference=AG-030636,SupportServicesEnd=15_September_2023_[v2]_MTY5NDczMjQwMDAwMA==67274b0466dad158508a4ae0d4ef5204');

@NgModule({
  declarations: [
    WfmTextboxComponent,
    WfmSelectComponent,
    WfmModalComponent,
    CreatePlanComponent,
    CreatePlanComponentV2,
    RefreshPromptComponent,
    FullWidthTableDirective,
    InputTextComponent,
    BreezeButtonComponent,
    PageTitleComponent,
    CheckboxListComponent,
    WeekPickerComponent,
    TaskProgressComponent,
    EspTableHeaderComponent,
    EspNoRowsOverlayComponent,
    GridItemActionComponent,
    SideNavComponent,
    InputFocusDirective,
    MultiSelectFilterComponent,
    MultiSelectFooterComponent,
    LoadingRowsOverlayComponent,
    ValidationNavigatorComponent,
    MuGroupHeaderComponent,
    ApiCredentialComponent,
    WfmIcontextboxComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MultiSelectModule,
    SvgIconsModule,
    HttpClientModule,
    NgbDatepickerModule,
    TooltipModule,
    NgbPopoverModule,
    InputSwitchModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    DateTimePickersModule
  ],

  exports: [
    WfmTextboxComponent,
    WfmSelectComponent,
    WfmModalComponent,
    CreatePlanComponent,
    CreatePlanComponentV2,
    RefreshPromptComponent,
    FullWidthTableDirective,
    InputTextComponent,
    BreezeButtonComponent,
    IconNameDirective,
    PageTitleComponent,
    CheckboxListComponent,
    WeekPickerComponent,
    TaskProgressComponent,
    EspTableHeaderComponent,
    EspNoRowsOverlayComponent,
    GridItemActionComponent,
    SideNavComponent,
    InputFocusDirective,
    MultiSelectFilterComponent,
    MultiSelectFooterComponent,
    LoadingRowsOverlayComponent,
    ValidationNavigatorComponent,
    ApiCredentialComponent,
    WfmIcontextboxComponent
  ]
})
export class ComponentModule {

}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
