import { Component, OnInit, Renderer2 } from "@angular/core";
import { IHeaderAngularComp } from "ag-grid-angular";
import { Store } from "@ngrx/store";

@Component({
  selector: "esp-column-header",
  templateUrl: "./esp-column-header.component.html",
  styleUrls: ["./esp-column-header.component.scss"]
})
export class EspColumnHeaderComponent implements OnInit, IHeaderAngularComp {
  params: any;
  colId: string;

  public static headers:Map<string,EspColumnHeaderComponent> = new Map<string,EspColumnHeaderComponent>();

  constructor(private renderer: Renderer2, private store: Store<any> ) { }

  ngOnInit() { }

  agInit(params) {
    EspColumnHeaderComponent.headers.set(params.column.colDef.colId, this);
    this.params = params;
    this.colId =  params.column.colDef.colId ? params.column.colDef.colId : "";
    console.log("colId=" + this.colId);
  }

  refresh(params: import("ag-grid-community").IHeaderParams): boolean {
    // debugger;
    return false;
  }
  afterGuiAttached?(params?: import("ag-grid-community").IAfterGuiAttachedParams): void { }
}
