
export class ViewLogHelper {

    public static replaceParamsFromMessage(message: string, args: any): string {
        if (message && args) {
            let logMessage: string = message;
            let checkForMoreArgsToReplace: boolean = false;
            let indexParam = 0;
            do {
                const param = "{" + indexParam + "}";
                if (logMessage.indexOf(param) === -1) checkForMoreArgsToReplace = false;
                else {
                    if (args[indexParam] && args[indexParam].value) {
                        logMessage = logMessage.replace(param, args[indexParam].value);
                        checkForMoreArgsToReplace = true;
                        indexParam++;
                    } else {
                        // If total arguments are not available, undefined.
                        return undefined;
                    }
                }
            } while (checkForMoreArgsToReplace);
            return logMessage;
        } else {
            return undefined;
        }

    }

}