import {ActionReducerMap} from "@ngrx/store";
import {AppActionTypes} from "../actions";
import {PlanSettingsComponent} from "src/app/pages/plan/plan-settings/plan-settings.component";
import {BackgroundTask, TaskState} from "../application-state";


export const reducers: ActionReducerMap<any> = {
  state(state: any = { user: null, locale: null, isLoggedIn: false, tokenMinutesUntilExpiration: null,tasks:{count:0} }, action: any) {
    const target = JSON.parse(JSON.stringify(state));
    switch (action.type) {
      case AppActionTypes.GetPlanList:
        target.plans = JSON.parse(JSON.stringify(action.payload));
        break;
      case AppActionTypes.PlanListReceived:
        target.plans = JSON.parse(JSON.stringify(action.payload));
        break;
      case AppActionTypes.PlanDetailReceived:
        target.currentPlanDetail = JSON.parse(JSON.stringify(action.payload));
        break;
      case AppActionTypes.UpdateLoginState:
        target.isLoggedIn = action.payload;
        break;
      case AppActionTypes.UpdateUserInfo:
        target.user = action.payload;
        break;
      case AppActionTypes.UpdateMinutesUntilTokenExpiration:
        target.tokenMinutesUntilExpiration = action.payload;
        break;
      case AppActionTypes.UpdateLocale:
        target.locale = action.payload;
        break;
      case AppActionTypes.EditPlanDetailReceived:
        if (action.payload && action.payload.request && action.payload.request.planParameterLabel &&
            action.payload.request.planParameterLabel===PlanSettingsComponent.NEW_HIRE_TRAINING_PERIOD) {
            target.currentPlanDetail.settings.newHireTrainingWeeks = action.payload.request.editedValue;
        }
        break;
      case AppActionTypes.TaskStartAction:
        target.tasks.count++;
        target.tasks[action.payload.taskId] = action.payload;
        console.log("TASK STATUS: ",target);
        break;
      case AppActionTypes.TaskStatusAction:
        target.tasks[action.payload.taskId] = action.payload;
        console.log("TASK STATUS: ",target);
        break;
      case AppActionTypes.TaskCompleteAction:
        target.tasks[action.payload.taskId] = action.payload;
        target.tasks.count--;
        console.log("TASK COMPLETE: ",target);
        break;
      case AppActionTypes.EditEntitySettingsSuccess:
        target.currentPlanDetail.settings.startingBacklog = action.payload.startingBacklog;
        break;
      default:
        return state;
    }

    return target;
  }
};
