export class Plan {
    planName: string;
    entityType:  string;
    entityId: number;
    entityOid: string;
    entityName:  string;
    ltfcstName: string;
    ltfcstOid: string;
    startDate:  string;
    endDate:  string;
    status: planStatus;
    entityPermission:  string;
    createdBy:  string;
    modifiedBy: string;
    modifiedTime:  string;
    permission: string;
}

export class CreatePlanRequest {
    planName: string;
    entityType: string;
    entity: any;
    forecast: any;
}

export class CreatePlanRequestV2 {
  planName: string;
  entityType: string;
  entity: any;
  forecastSource: string;
  forecast: any;
  mus?:any[];
  muset?:any;
  week1:any;
  week2:any;
  week3:any;
  week4:any;
  workflowOid?:string;
}

export class EditPlanDetailRequestDTO {
    planId: String;
    startDate: string;
    endDate: string;
    interval: string;
    planParameterLabel: string;
    editedValue: string;
    previousValue: string;
    ctOid: string;
    muOid?: string;
    muRollup?:boolean;
  }

export interface MultiEditPlanDetailRequestDTO {
  planId: String;
  editInterval: string;
  ctOid: string;
  muOid?: string;
  muRollup: boolean;
  editedData:{
    [date: string]:{
      startDate: string;
      endDate: string;
      parameters:{
        [paramName:string]:{
          editedValue: string;
          previousValue: string;
        }
      }
    }
  }
}

export class PlanListResponse {

    plans: Array<Plan>;
    offset: number;
    limit: number;
    sortBy: planSortBy;
    sortOrder: sortOrder;
    totalCount: number;

}

export class CTInfo{
  id:string;
  name:string;
  oid:string;
}

export class PlanDetailResponse {
    interval: string;
    planId: string;
    request: any;
    ctInfo:Array<CTInfo>;
    muInfo: Array<CTInfo>;
    entityType:string;
    selectedType:string;
    selectedOid:string;
    muRollupParams:any;
}

export class MuInfo {
  oid:	string
  id:	number
  name:	string
}
export class CtStaffingData {
  ct:CTInfo;
  baseDistribution:number;
  dateDistributions:Array<any>
}
export class MuStaffingResponse {
  mus:	Array<MuInfo>;
  weekDates: Array<any>;
  muStaffingData : {
    mu:MuInfo,
    cts:Array<CtStaffingData>
  }
}

export class DiscardMuStffingResponse extends MuStaffingResponse {
}

export class EditMuDistRequestV2{
  planId:string;
  muOid:string;
  base:any;
  applyBase:boolean;
  dateDistributions:any;
  applyBaseToDates: Array<string>;
  editAction:any
}


export class DiscardMuDistRequest {
  planId: string;
  muOid: string;
  revertAction: discardMuDist;
  revertAll: boolean;
}

export class PlanConvertResponse {
  id:string;
}

export class PlanNameExists {
    planName: string;
    exists: boolean;
}

export class PlanSaveAsResponse {
    planId: string;
    planName: string;
    planStatus: planStatus;
}

export class EditEntitySettingsDto {
  planId: string;
  entityType: string;
  entityOid: string;
  interval: string;
  startingBacklog: number;
}

enum planSortBy {
    PLAN_NAME, ENTITY_TYPE, ENTITY, FORECAST_NAME, DATE_RANGE, MODIFIED_DATE
}
enum sortOrder {
    ASC, DESC
}

enum planStatus {
    PENDING, ACTIVE, FAILED
}

export enum discardMuDist {
  REVERT_DISTRIBUTIONS, RETURN_TO_PLAN
}

export enum Features{
    IMPORTED_FORECASTS_MENU = "esp-importforecast-wfm89135",
    CREATE_SITE_PLAN = "esp-siteplans-wfm87352",
    DELETE_IMPORTED_FORECAST = "esp-deleteimportforecast-wfm91339",
    CT_DISTRIBUTION_METHOD = "esp-ctreqdistributions-wfm96888",
    MEAN_CONCURRENCY = "esp-dcmiexphasetwo-wfm99931",
    WORKLOAD_CTS = "esp-workloadct-esp16",
    HELP_LOCALIZATION = "esp-helplocalization-esp8",
    ERLANG_A = "esp-erlangaimpl-esp3759",
    HISTORICAL_DATA_MENU="esp-histdata-esp4772",
    HISTORICAL_DATA_IN_PLAN = "esp-histdatainplan-esp2740",
    MULTI_STEP_WORKFLOW = "esp-multistepworkflow-esp5908",
    UPDATE_FORECAST_TEMPLATE = "esp-updateforecasttemplate-esp6027",
    COPY_PASTE = "esp-copypasteplandetail-esp6459",
    CSV_EVERY_CT = "esp-csveveryct-esp6461",
    MU_ROLLUP = "esp-murollup-esp7471",
}
export enum EntityType{
    CT = "CT", CTSET = "CTSET",BU="BU", BUSET="BUSET",EG="EG",EGSET="EGSET",MU="MU", MUSET="MUSET"
}
